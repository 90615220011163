import Styled from 'vue3-styled-components';

const Content = Styled.div`
    background: #ffffff;
    box-shadow: 0px 0px 2px #888;
    a i, a svg, a img {
        ${({theme}) => (theme.rtl ? 'margin-left' : 'margin-right')}: 8px;
    }
    a {
        display: block;
        color: #888;
        padding: 6px 12px;
    }
    a:hover {
        background: ${({theme}) => theme['primary-color']}10;
        color: ${({theme}) => theme['primary-color']}
    }
    .dropdown-theme-2{
        a:hover{
            background: ${({theme}) => theme.pink}10;
            color: ${({theme}) => theme.pink}
        }
    }
    .button-wrapper {
      width: 100% !important;
    }
`;

const DropdownIconStyleWrapper = Styled.div`
  button{
    padding: 0 !important;
    >span{
      padding: ${({theme}) => (theme.rtl ? '0 16px 0 10px' : '0 10px 0 16px')}
    }
  }
  .ant-dropdown-trigger{
    height: 100%;
    display: inline-flex;
    align-items: center;
    padding: 0 8px;
    ${({theme}) => (!theme.rtl ? 'border-left' : 'border-right')}: 1px solid ${({theme}) =>
  theme['border-color-normal']};
  }
`;

export {
  Content,
  DropdownIconStyleWrapper
};
