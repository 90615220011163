<template>
  <div>
    <a-form-item :label="$t(label)" :name="fieldKey" :rules="rules" >
      <a-select size="default" :value="fieldValue" mode="multiple" :placeholder="placeholder ? $t(placeholder) : ''"
                :allowClear="true" :disabled="disabled" :options="validOptions"
                :show-search="enableSearch" :filterOption="filterOption"
                :show-arrow="showArrow" :maxTagCount="5"
                @select="onSelect" @deselect="onDeselect" @change="onChange" />
    </a-form-item>
  </div>
</template>

<script>
import {
  computed, defineComponent, toRefs, watch
} from 'vue';
import VueTypes from 'vue-types';
import _ from 'lodash';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  name: 'SelectField',
  emits: ['update:fieldValue'],
  props: {
    /**
   * Input Type
   * @values array<{value, label}>
   */
    options: VueTypes.Array,
    label: VueTypes.string,
    disabled: VueTypes.bool.def(false),
    fieldValue: VueTypes.array.def([]),
    fieldKey: VueTypes.oneOfType([VueTypes.array, VueTypes.string]),
    placeholder: VueTypes.string.def(''),
    allowClear: VueTypes.bool.def(false),
    enableSearch: VueTypes.bool.def(false),
    showArrow: VueTypes.bool.def(true),
    selectAllOption: VueTypes.bool.def(false),
    rules: VueTypes.array.def(undefined)
  },
  setup(props, context) {
    const {t} = useI18n();

    const options = computed(() => {
      return props.selectAllOption ? [{
        label: t('actions.selectAll'), value: 'systemSelection:selectAll', class: 'multi-select-select-all'
      }, ...props.options] : props.options;
    });

    const onSelect = (value) => {
      if (props.selectAllOption && value === 'systemSelection:selectAll') {
        context.emit('update:fieldValue', _.map(options.value, 'value'));
      } else {
        context.emit('update:fieldValue', [...props.fieldValue, value]);
      }
    };

    const onDeselect = (value) => {
      if (props.selectAllOption && _.includes(props.fieldValue, 'systemSelection:selectAll') && value !== 'systemSelection:selectAll') {
        context.emit('update:fieldValue', _.without(props.fieldValue, value, 'systemSelection:selectAll'));
      } else {
        context.emit('update:fieldValue', _.without(props.fieldValue, value));
      }
    };

    const onChange = (value) => {
      if (_.isEmpty(value)) context.emit('update:fieldValue', []);
    };

    const filterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    // Fix antd's resetFields() method for Select Field -> resets to [undefined]
    const {fieldValue} = toRefs(props);
    watch(fieldValue, (value) => {
      if (value instanceof Array && _.includes(value, undefined)) {
        context.emit('update:fieldValue', _.without(value, undefined));
      }
    });

    return {
      filterOption,
      onSelect,
      onDeselect,
      onChange,
      validOptions: options
    };
  }
});
</script>
