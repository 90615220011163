<template>
  <div>
    <a-form-item :ref="fieldRef" :label="$t(label)" :name="fieldKey" :rules="fieldRules">
      <a-input type="password" :size="size" :value="fieldValue" :placeholder="placeholder ? $t(placeholder) : ''"
                        :disabled="disabled" @change="fieldChanged">
        <template #prefix v-if="icon"><sdFeatherIcons :type="name" size="14" /></template>
      </a-input>
    </a-form-item>
  </div>
</template>

<script>
import {
  defineComponent, computed, ref, toRefs, watch
} from 'vue';
import VueTypes from 'vue-types';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  name: 'PasswordField',
  emits: ['update:fieldValue', 'validateField'],
  props: {
    label: VueTypes.string,
    disabled: VueTypes.bool.def(false),
    fieldKey: VueTypes.oneOfType([VueTypes.array, VueTypes.string]),
    fieldValue: VueTypes.string.def(''),
    placeholder: VueTypes.string.def(''),
    customValidation: VueTypes.bool.def(false),
    validateAgainst: VueTypes.string.def(''),
    validateDependent: VueTypes.string.def(''),
    rules: VueTypes.array,
    icon: VueTypes.string.def(undefined),
    size: VueTypes.string.def('default')
  },
  setup(props, context) {
    const {t} = useI18n();
    const fieldRef = ref();
    const {validateAgainst} = toRefs(props);
    const localFieldValue = ref();

    const fieldChanged = (e) => {
      localFieldValue.value = e.target.value;
      context.emit('update:fieldValue', e.target.value);
    };

    const validateMatchingPasswords = async () => {
      if (localFieldValue.value && localFieldValue.value !== validateAgainst.value) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(t('messages.error.notAMatch'));
      } else {
        return Promise.resolve();
      }
    };

    const fieldRules = computed(() => {
      const rules = [];
      if (props.rules) {
        rules.push(...props.rules);
      }
      if (props.customValidation) {
        rules.push({
          validator: validateMatchingPasswords,
          trigger: ['blur', 'change']
        });
      }
      return rules;
    });

    watch(validateAgainst, (value) => {
      if (value !== '' && props.customValidation) {
        context.emit('validateField', props.fieldKey);
      }
    });

    return {
      fieldChanged,
      fieldRef,
      fieldRules
    };
  }
});
</script>
