import app from '@/config/configApp';
import router from '@/routes';
import store from '@/store';
import axiosInstance from '@/core/plugins/axios';
import VueAxios from 'vue-axios';
import VueNativeSock from 'vue-native-websocket-vue3';
import {jsonapiModule} from 'jsonapi-vuex';
import lodash from 'lodash';
import i18n from '@/core/plugins/i18n';
import {abilitiesPlugin} from '@casl/vue';
import ability from '@/core/plugins/ability';
import './static/css/style.css';
import './static/css/custom.css';
import '@/core/plugins/ant-design';
import '@/core/plugins/fonts';
import '@/core/components/custom';
import '@/core/components/style';
import * as Sentry from '@sentry/vue';
import {BrowserTracing} from '@sentry/tracing';
import {VueEternalLoading} from '@ts-pro/vue-eternal-loading';
import AppForm from './components/shared/form/Form';
import AppHeader from '@/components/shared/Header.vue';
import AppChat from '@/components/shared/chat';
import AppTicket from '@/components/shared/ticket';

app.config.globalProperties.$_ = lodash;

app.config.productionTip = false;

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    autoSessionTracking: true,
    tracesSampleRate: 0.2,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    environment: process.env.NODE_ENV
  });
}

app.use(abilitiesPlugin, ability(), {
  useGlobalProperties: true
});
app.use(i18n);
app.use(store);
app.use(router);
app.use(VueAxios, axiosInstance);
app.use(VueNativeSock, process.env.VUE_APP_GCE_SOCKET_URL, {
  store: store,
  connectManually: true
});
app.use(AppChat);
app.use(AppTicket);

app.component('AppForm', AppForm);
app.component('AppHeader', AppHeader);
app.component('VueEternalLoading', VueEternalLoading);
app.config.devtools = true;

app.mount('#app');

store.$_ = app.$_;
store.$router = router;
store.$http = app.$http;
store.axios = app.axios;
store.$ability = app.config.globalProperties.$ability;
store.$t = app.config.globalProperties.$t;
store.$connect = app.config.globalProperties.$connect;
store.$disconnect = app.config.globalProperties.$disconnect;
store.registerModule('jv', jsonapiModule(axiosInstance));
