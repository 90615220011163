import {displayServerErrorsInToaster, displaySuccessToaster} from '@/helpers/toasters';
import {utils} from 'jsonapi-vuex';
import _ from 'lodash';
import {Meta} from '@/models/meta';

const state = {
  agents: [],
  companies: [],
  company: {},
  companyPriceLists: {},
  versions: []
};

const getters = {
  agents: (state) => state.agents,
  companies: (state) => state.companies,
  company: (state) => state.company,
  companyPriceLists: (state) => state.companyPriceLists,
  versions: (state) => state.versions
};

const actions = {
  async getCompanies({commit}, params) {
    const response = await this.$http.get('/companies', {params});
    if (response.status === 200) {
      commit('setCompanies', response.data);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async getClientAgents({commit}, params) {
    const response = await this.$http.get('/preferred_agents', {params});
    if (response.status === 200) {
      commit('setAgents', response.data);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async preferredAgent({getters, commit}, id) {
    const response = await this.$http.put(`/preferred_agents/${id}/prefer`);
    if (response.status === 200) {
      const index = _.findIndex(getters.agents.data, (agent) => agent._jv.id === id);
      commit('updateAgentPreferred', {index});
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async unpreferredAgent({getters, commit}, id) {
    const response = await this.$http.put(`/preferred_agents/${id}/disprefer`);
    if (response.status === 200) {
      const index = _.findIndex(getters.agents.data, (agent) => agent._jv.id === id);
      commit('updateAgentUnpreferred', {index});
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async getCompany({commit}, id) {
    const response = await this.$http.get(`/companies/${id}`);
    if (response.status === 200) {
      commit('setCompany', response.data);
    } else {
      displayServerErrorsInToaster(response.errors);
      this.$router.push({name: 'BackOfficeAgentCompanies'});
    }
  },
  async updateCompany({state, commit}, {
    id, form, hideToaster
  }) {
    const response = await this.$http.put(`/companies/${id}`, form);
    if (response.status === 200) {
      commit('updateCompanyInList', response.data);
      if (!_.isEmpty(state.company) && state.company._jv.id === id) commit('setCompany', response.data);
      if (!hideToaster) {
        displaySuccessToaster(this.$t('messages.success.updated', {entity: this.$t('models.company.entity', 1)}));
      }
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async getCompanyUsers({commit}, {companyId, params}) {
    const response = await this.$http.get(`/companies/${companyId}/users`, {params});
    if (response.status === 200) {
      commit('setUsers', response.data);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async getHistory({commit}, {
    resourceType, resourceId, params
  }) {
    params['resource_id'] = resourceId;
    params['resource_type'] = resourceType;
    const response = await this.$http.get('/resource_history', {params});
    if (response.status === 200) {
      commit('setVersions', response.data);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async updateCompanyStatus({state, commit}, {
    id, index, status, message
  }) {
    const response = await this.$http.put(`/companies/${id}`, {
      status: status,
      rejection_reason: 'other',
      blacklist_comment: message
    });
    if (response.status === 200) {
      commit('setCompanyStatus', {index, status: response.data.data.attributes.status});
      commit('setCompanyBlacklistComment', {
        index,
        blacklistComment: response.data.data.attributes.blacklistComment,
        rejectionReason: response.data.data.attributes.rejectionReason
      });
      displaySuccessToaster(this.$t('messages.success.updated', {entity: this.$t('models.company.entity', 1)}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async blacklistCompany({dispatch}, {
    id, index, message
  }) {
    return dispatch('updateCompanyStatus', {
      id, index, status: 'blacklisted', message
    });
  },
  async rejectCompany({dispatch}, {id, index}) {
    return dispatch('updateCompanyStatus', {
      id, index, status: 'rejected', message: ''
    });
  },
  async getCompanyProductItems({commit}, companyId) {
    const response = await this.$http.get(`/companies/${companyId}/product_items`);
    if (response.status === 200) {
      commit('setProductItems', response.data);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async getCompanyPriceLists({commit}, {companyId, params}) {
    const response = await this.$http.get(`/companies/${companyId}/price_lists`, {params});
    if (response.status === 200) {
      commit('setCompanyPriceLists', response.data);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  }
};

const mutations = {
  setCompanies: (state, companies) => {
    const normalizedCompanies = _.map(companies.data, (company) => utils.jsonapiToNorm(company));
    state.companies = {
      data: normalizedCompanies,
      ...new Meta(companies.meta)
    };
  },
  setAgents: (state, agents) => {
    const normalizedAgents = _.map(agents.data, (agent) => utils.jsonapiToNorm(agent));
    state.agents = {
      data: normalizedAgents,
      ...new Meta(agents.meta)
    };
  },
  updateAgentPreferred: (state, {index}) => {
    state.agents.data[index].preferred = true;
  },
  updateAgentUnpreferred: (state, {index}) => {
    state.agents.data[index].preferred = false;
  },
  setCompany: (state, company) => state.company = utils.jsonapiToNorm(company.data),
  updateCompanyInList: (state, {data}) => {
    const index = _.findIndex(state.companies.data, (company) => company._jv.id === data.id);
    if (index === -1) return;
    state.companies.data[index] = utils.jsonapiToNorm(data);
  },
  setCompanyStatus: (state, {index, status}) => Object.assign(state.companies.data[index], {status}),
  setVersions: (state, items) => {
    const normalisedVersions = _.map(items.data, (item) => {
      return utils.jsonapiToNorm(item);
    });
    state.versions = {data: normalisedVersions};
  },
  setCompanyBlacklistComment: (state, {
    index, blacklistComment, rejectionReason
  }) => {
    Object.assign(state.companies.data[index], {blacklistComment});
    Object.assign(state.companies.data[index], {rejectionReason});
  },
  setCompanyPriceLists: (state, data) => {
    let formattedPriceLists = utils.jsonapiToNorm(data.data);
    const included = data.included;
    _.forEach(formattedPriceLists, (price) => {
      price._jv.relationships.priceCategoryItem = utils.jsonapiToNorm(_.find(included, {
        id: price._jv.relationships.priceCategoryItem.data.id,
        type: 'priceCategoryItem'
      }));
      price._jv.relationships.suggestedPrice = utils.jsonapiToNorm(_.find(included, {
        id: price._jv.relationships.suggestedPrice.data?.id,
        type: 'suggestedPrice'
      }));
    });

    formattedPriceLists = _.groupBy(formattedPriceLists, 'countryCode');
    _.forEach(formattedPriceLists, (value, key) => {
      formattedPriceLists[key] = _.groupBy(value, '_jv.relationships.priceCategoryItem.categoryType');
    });

    state.companyPriceLists = formattedPriceLists;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
