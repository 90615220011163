import {displayServerErrorsInToaster, displaySuccessToaster} from '@/helpers/toasters';
import _ from 'lodash';
import buildAbility from '@/core/plugins/ability';

const actions = {
  async registerCompany({commit, rootState}, {
    data, saveDraft, skipToaster
  }) {
    const response = await this.axios.post('/auth', data);
    if (response.status === 200) {
      if (saveDraft) {
        commit('setCurrentUser', response);
        if (response.headers['access-token']) {
          commit('setSession', response);
          this.$ability.update(buildAbility(rootState.session.currentUser.permissions).rules);
        }
        if (saveDraft && !skipToaster) {
          displaySuccessToaster(this.$t('views.registration.draftSaved'));
          return;
        }
      }
      if (rootState.session.userType === 'ClientUser') this.$router.push('/client/registration/success');
    } else {
      displayServerErrorsInToaster(response.errors.full_messages);
    }
    return response.status;
  },
  async updateCompanyRegistration({
    commit, rootState, dispatch
  }, {
    data, saveDraft, skipToaster, edit, skipLogin
  }) {
    const response = await this.axios.put('/auth', data);
    if (response.status === 200) {
      commit('setCurrentUser', response);
      this.$ability.update(buildAbility(rootState.session.currentUser.permissions).rules);
      if (saveDraft) {
        if (response.headers['access-token'] && !skipLogin) commit('setSession', response);
        if (!skipToaster) displaySuccessToaster(this.$t('views.registration.draftSaved'));
        return response.status;
      } else {
        if (skipLogin) await dispatch('resetSession');
        this.$router.push(`/${rootState.session.userType === 'AgentUser' ? 'agent' : 'client'}/registration/success?edit=${Boolean(edit)}`);
      }
    } else {
      displayServerErrorsInToaster(response.errors.full_messages || response.errors);
    }
    return response;
  },
  async validateCompany(_, data) {
    const response = await this.axios.post('auth/validate', data);
    if (response.status !== 204) {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async validateCompanyUpdate(_, data) {
    const response = await this.axios.put('auth/validate_update', data);
    if (response.status !== 204) {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async continueRegistration({commit, rootState}, data) {
    const response = await this.axios.post('/auth/sign_in', data);
    if (response.status === 200) {
      const company = _.find(response.data.included, {type: 'company'}).attributes;
      if (company?.status !== 'approved' && company?.status !== 'draft') {
        return {errors: [this.$t('messages.error.companyAlreadyRegistered')]};
      }
      commit('setCurrentUser', response);
      commit('setSession', response);
      if (company?.status === 'approved') {
        this.$router.push(rootState.session.userType === 'AgentUser' ? '/agent' : '/client');
        return;
      }
      this.$router.push({path: '/agent/registration', query: {continue: true}});
    }
    return response;
  },
  async reApply({commit, rootState}, data) {
    const response = await this.axios.post('/auth/sign_in', data);
    if (response.status === 200) {
      const company = _.find(response.data.included, {type: 'company'}).attributes;
      if (company?.status !== 'rejected') {
        return {errors: [this.$t('messages.error.cannotReApply')]};
      }
      commit('setCurrentUser', response);
      this.$ability.update(buildAbility(rootState.session.currentUser.permissions).rules);
      commit('setSession', response);
      this.$router.push('/');
    }
    return response;
  }
};

export default {
  namespaced: false,
  actions
};
