<template>
  <div>
  <a-form-item  :name="fieldKey" >
    <a-checkbox :checked="fieldValue" :disabled="disabled"
                @change="$emit('update:fieldValue', $event.target.checked)">
      {{label ? $t(label) : ''}}
    </a-checkbox>
  </a-form-item>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'CheckBox',
  emits: ['update:fieldValue'],
  props: {
    label: VueTypes.string.def(''),
    fieldValue: VueTypes.bool.def(false),
    disabled: VueTypes.bool.def(false),
    fieldKey: VueTypes.oneOfType([VueTypes.array, VueTypes.string])
  }
});
</script>
