export default [
  {
    name: 'ClientAuth',
    path: '/client/auth',
    component: () => import('../layout/authLayout.vue'),
    children: [
      {
        name: 'ClientLogin',
        path: '',
        component: () => import('../views/session/login.vue')
      },
      {
        name: 'ClientChangePassword',
        path: 'set-password',
        component: () => import('../views/session/login.vue')
      },
      {
        name: 'ClientForgotPassword',
        path: 'forgot-password',
        component: () => import('../views/session/forgotPassword')
      },
      {
        name: 'ClientResetPassword',
        path: 'reset-password',
        component: () => import('../views/session/setPassword')
      },
      {
        name: 'ClientAcceptInvitation',
        path: 'accept-invitation',
        component: () => import('../views/session/setPassword')
      },
      {
        name: 'ClientReApply',
        path: 'reapply',
        component: () => import('../views/session/reapply')
      }
    ],
    meta: {
      auth: false, key: 'Client', userType: 'ClientUser'
    }
  },
  {
    name: 'AgentAuth',
    path: '/agent/auth',
    component: () => import('../layout/authLayout.vue'),
    children: [
      {
        name: 'AgentLogin',
        path: '',
        component: () => import('../views/session/login.vue')
      },
      {
        name: 'AgentChangePassword',
        path: 'set-password',
        component: () => import('../views/session/login.vue')
      },
      {
        name: 'AgentForgotPassword',
        path: 'forgot-password',
        component: () => import('../views/session/forgotPassword')
      },
      {
        name: 'AgentResetPassword',
        path: 'reset-password',
        component: () => import('../views/session/setPassword')
      },
      {
        name: 'AgentAcceptInvitation',
        path: 'accept-invitation',
        component: () => import('../views/session/setPassword')
      },
      {
        name: 'AgentContinueRegistration',
        path: 'continue-registration',
        component: () => import('../views/session/continueRegistration.vue')
      },
      {
        name: 'AgentReApply',
        path: 'reapply',
        component: () => import('../views/session/reapply')
      }
    ],
    meta: {
      auth: false, key: 'Agent', userType: 'AgentUser'
    }
  },
  {
    name: 'BackOfficeAuth',
    path: '/back-office/auth',
    component: () => import('../layout/authLayout.vue'),
    children: [
      {
        name: 'BackOfficeLogin',
        path: '',
        component: () => import('../views/session/login.vue')
      },
      {
        name: 'BackOfficeForgotPassword',
        path: 'forgot-password',
        component: () => import('../views/session/forgotPassword')
      },
      {
        name: 'BackOfficeResetPassword',
        path: 'reset-password',
        component: () => import('../views/session/setPassword')
      },
      {
        name: 'BackOfficeAcceptInvitation',
        path: 'accept-invitation',
        component: () => import('../views/session/setPassword')
      }
    ],
    meta: {
      auth: false, key: 'BackOffice', userType: 'BackOfficeUser'
    }
  }
];
