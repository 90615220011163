<template>
  <ThemeProvider :theme="{...theme, rtl, topMenu, darkMode}">
    <router-view />
  </ThemeProvider>
</template>
<script>
import {ThemeProvider} from 'vue3-styled-components';
import {theme} from './config/theme/themeVariables';
import {computed} from 'vue';
import {useStore} from 'vuex';
export default {
  name: 'App',
  components: {
    ThemeProvider
  },
  setup() {
    const {state} = useStore();
    const rtl = computed(() => state.layout.rtl);
    const isLoading = computed(() => state.layout.loading);
    const darkMode = computed(() => state.layout.data);
    const topMenu = computed(() => state.layout.topMenu);

    return {
      theme,
      rtl,
      isLoading,
      darkMode,
      topMenu
    };
  }
};
</script>
