import {AbilityBuilder, Ability} from '@casl/ability';
import store from '@/store';
import _ from 'lodash';

let userPermissions;
/**
 * @param {array} permissions current user permissions
 * @return {function}
 */
export default function defineAbility(permissions) {
  userPermissions = permissions || store.getters.userPermissions;

  const {
    can, build, cannot
  } = new AbilityBuilder(Ability);

  const agentShipmentPermission = () => {
    // Shipment Request
    can('read', ['shipmentRequest', 'shipment', 'shipmentItem']);
    // Shipment Quote
    can('read', 'shipmentQuote', {'_jv.relationships.createdBy.data': {$ne: null}});
    if (hasPermission('manage_shipment_request') || hasPermission('review_agent_quote')) {
      let condition = {
        status: {
          $in: ['waiting_agent_response', 'waiting_primary_user_approval', 'modification_requested',
            'documents_requested', 'documents_submitted', 'submitted', 'revalidation_requested', 'approved', 'awaiting_reconfirmation', 'documents_submitted']
        }
      };
      if (store.state.session.currentUser?._jv.relationships.company.status === 'cs_verification_required') condition = {...condition, agentApprovedAt: {$ne: null}};
      can('update', 'shipmentQuote', condition);
      cannot('update', 'shipmentQuote', {status: {$ne: 'revalidation_requested'}, paidToAgent: true});
    }
  };

  switch (store.state.session.userType) {
  case 'ClientUser':
    // Company
    can('read', 'company');
    if (hasPermission('edit_client_profile')) can('edit', 'company', {status: {$ne: 'in_review'}});

    if (store.state.session.currentUser?._jv.relationships.company.status !== 'rejected') {
      // Shipment Requests
      can('read', ['shipmentRequest', 'shipment', 'shipmentItem']);
      if (hasPermission('view_change_history')) {
        can('read', 'shipmentRequestHistory');
        can('read', 'allHistory');
      }
      if (hasPermission('manage_shipment_request')) {
        can('manage', ['shipmentRequest', 'shipment', 'shipmentItem']);
        cannot('cancel', 'shipment', {status: {$in: ['awaiting_cancellation_approval', 'cancelled', 'completed']}});
        cannot('cancel', 'shipment', {'trackingStatus.end': true});
        cannot(['cancel', 'edit', 'clone'], 'shipmentRequest', {cancelledAt: {$ne: null}});
        can('clone', ['shipmentRequest', 'shipment', 'shipmentItem']);
        cannot('publish', 'shipmentRequest', {published: true});
        cannot(['cancel', 'edit'], 'shipmentRequest', {hasCompletedShipment: true});
      }
      // Shipment Quotes
      if (hasPermission('view_quotes')) can('read', 'shipmentQuote');
      if (hasPermission('quote_approval_rejection')) {
        can('read', 'shipmentQuote');
        can('reject', 'shipmentQuote', {
          status: {$in: ['modification_requested', 'documents_requested', 'documents_submitted', 'submitted', 'expired', 'approved', 'revalidation_requested', 'awaiting_reconfirmation']},
          quoteType: 'agent'
        });

        can('requestRevalidation', 'shipmentQuote', {status: 'expired', quoteType: 'agent'});

        can('approve', 'shipmentQuote', {
          status: {$in: ['documents_requested', 'rejected', 'submitted', 'awaiting_reconfirmation', 'documents_submitted']},
          quoteType: 'agent'
        });
      }

      // Ticket
      can(['create', 'read'], 'ticket');
      can('updateStatus', 'ticket', {
        '_jv.relationships.createdBy._jv.id': store.state.session.currentUser?._jv?.id,
        'status': 'resolved'
      });
      can('read', 'comment');
      can('createComment', 'ticket', {
        '_jv.relationships.createdBy._jv.id': store.state.session.currentUser?._jv?.id
      });

      // Chat
      if (hasPermission('messages')) can('manage', 'chat');

      // Company
      can('read', 'productItem');

      // User
      can('edit', 'user', {'_jv.id': store.state.session.currentUser?._jv?.id});
      if (hasPermission('view_client_user_list')) can('read', 'user');
      if (hasPermission('manage_client_users')) {
        can(['read', 'invite'], 'user');
        can('edit', 'user', {deletedAt: null});
        can('delete', 'user', {
          '_jv.id': {$ne: store.state.session.currentUser?._jv?.id},
          'deletedAt': null
        });
        can('updatePermissions', 'user', {
          '_jv.id': {$ne: store.state.session.currentUser?._jv?.id},
          'deletedAt': null
        });
      }

      can('read', 'bankAccount');
    }
    break;
  case 'AgentUser':
    // Company
    can('read', 'company');
    if (_.includes(['cs_verification_required', 'in_review', 'approved'], store.state.session.currentUser?._jv.relationships.company.status)) {
      agentShipmentPermission();
    }
    if (hasPermission('edit_agent_profile')) can('edit', 'company', {status: {$ne: 'in_review'}});

    if (store.state.session.currentUser?._jv.relationships.company.status === 'approved') {
      // Shipment Request
      if (hasPermission('view_change_history')) {
        can('read', 'shipmentRequestHistory');
        can('read', 'allHistory');
      }
      can('read', ['shipmentRequest', 'shipment', 'shipmentItem']);
      can('viewDocuments', 'shipment', {
        'agentMainQuote.status': {$nin: ['rejected', 'archived']},
        'agentShipmentStatus': {$nin: ['waiting_for_approval_on_rejection', 'rejected']}
      });
      can('updateTrackingStatus', 'shipment', {
        'agentId': parseInt(store.state.session.currentUser?._jv.relationships.company._jv.id),
        'trackingStatus.end': {$ne: true},
        'agentMainQuote.status': {$nin: ['rejected', 'archived']},
        'agentShipmentStatus': {$nin: ['waiting_for_approval_on_rejection', 'rejected']}
      });

      if (hasPermission('manage_shipment_request') || hasPermission('review_agent_quote')) {
        can('read', 'shipmentQuoteTemplate');
        can('reject', 'shipment', {
          status: {$nin: ['cancelled', 'rejected', 'archived']},
          agentShipmentStatus: {$ne: 'rejected'}
        });
        can('updateTrackingStatus', 'shipment', {agentId: store.state.session.currentUser?._jv?.relationships.company._jv.id});
        can('revalidate', 'shipmentQuote', {status: 'revalidation_requested'});
      }

      if (hasPermission('review_agent_quote')) {
        can('cancelRejection', 'shipment', {agentShipmentStatus: 'waiting_for_approval_on_rejection'});
        can('approveRejection', 'shipment', {
          status: {$nin: ['cancelled', 'rejected', 'archived']},
          agentShipmentStatus: 'waiting_for_approval_on_rejection'
        });
        can('approve', 'shipmentQuote', {status: 'waiting_primary_user_approval'});
      }

      // Ticket
      can(['create', 'read'], 'ticket');
      can('updateStatus', 'ticket', {
        '_jv.relationships.createdBy._jv.id': store.state.session.currentUser?._jv?.id,
        'status': 'resolved'
      });
      can('read', 'comment');
      can('createComment', 'ticket', {
        '_jv.relationships.createdBy._jv.id': store.state.session.currentUser?._jv?.id
      });

      // Chat
      if (hasPermission('messages')) can('manage', 'chat');

      // User
      can('edit', 'user', {'_jv.id': store.state.session.currentUser?._jv?.id});
      if (hasPermission('view_agent_user_list')) can('read', 'user');
      if (hasPermission('manage_agent_users')) {
        can(['read', 'invite'], 'user');
        can('edit', 'user', {deletedAt: null});
        can('delete', 'user', {
          '_jv.id': {$ne: store.state.session.currentUser?._jv?.id},
          'deletedAt': null
        });
        can('updatePermissions', 'user', {
          '_jv.id': {$ne: store.state.session.currentUser?._jv?.id},
          'deletedAt': null
        });
      }
    }
    break;
  case 'BackOfficeUser':
    // History
    can('read', 'allHistory');
    can('read', 'shipmentRequestHistory');
    can('manage', 'report');

    // Prices
    if (hasPermission('add_price_list_suggestions')) can('manage', ['priceCategoryItem', 'suggestedPrice']);

    // Company
    can('read', 'company');
    can('readUsers', 'company');
    if (hasPermission('manage_profile_status')) can(['blacklist', 'reject'], 'company');

    // User
    can('edit', 'user', {'_jv.id': store.state.session.currentUser?._jv?.id});
    if (hasPermission('view_back_office_user')) can('read', 'user');
    if (hasPermission('manage_back_office_users')) {
      can(['read', 'invite'], 'user');
      can('edit', 'user', {deletedAt: null, type: 'BackOfficeUser'});
      can('delete', 'user', {
        '_jv.id': {$ne: store.state.session.currentUser?._jv?.id},
        'deletedAt': null
      });
      can('updatePermissions', 'user', {
        '_jv.id': {$ne: store.state.session.currentUser?._jv?.id},
        'deletedAt': null
      });
    }

    // System Configurations
    can('read', ['systemConfiguration', 'sanctionedCountry', 'dynamicList']);
    if (hasPermission('system_configuration_management')) can('manage', ['systemConfiguration', 'sanctionedCountry', 'dynamicList']);

    // Companies
    if (hasPermission('verify_client_profile')) {
      can('verify', 'company', {
        type: 'Client',
        status: {$in: ['cs_verification_required', 'in_review']}
      });
    }

    // Shipments
    can('read', 'shipment');
    can('complete', 'shipment', {status: {$in: ['awaiting_completion_approval']}});
    can('reopen', 'shipment', {status: {$in: ['awaiting_cancellation_approval', 'awaiting_completion_approval']}});
    can('read', 'shipmentRequestHistory');
    if (hasPermission('cancel_client_shipment')) can('cancel', 'shipment');
    // Shipment Quotes
    can('read', 'shipmentQuote');
    if (hasPermission('verify_agent_profile')) {
      can('verify', 'company', {
        type: 'Agent',
        status: {$in: ['cs_verification_required', 'in_review']}
      });
    }

    // Messages
    can('manage', 'read');

    // Ticket
    can('read', ['ticket', 'comment']);
    can('updateStatus', 'ticket', {
      '_jv.relationships.assignee._jv.id': store.state.session.currentUser?._jv?.id,
      'status': {$in: ['open', 'reopened', 'in_progress']}
    });
    can('createComment', 'ticket', {
      '_jv.relationships.assignee._jv.id': store.state.session.currentUser?._jv?.id
    });
    break;
  default:
    break;
  }
  return build({
    detectSubjectType: (object) => object._jv?.type
  });
}

const hasPermission = (permission) => {
  return _.includes(userPermissions, permission);
};
