export default {
  user: {
    entity: 'User | Users',
    status: {
      active: 'Active',
      archived: 'Archived',
      all: 'All'
    },
    attributes: {
      account: 'Account',
      email: 'Email',
      currentPassword: 'Current Password',
      newPassword: 'New Password',
      newPasswordConfirmation: 'New Password Confirmation',
      password: 'Password',
      passwordConfirmation: 'Password Confirmation',
      firstName: 'First Name',
      lastName: 'Last Name',
      fullName: 'Full Name',
      userType: 'User Type',
      userName: 'User Name',
      userTypes: {
        primary_user: 'Primary User',
        secondary_user: 'Secondary User'
      },
      permissions: 'Permissions',
      status: 'Status'
    }
  },
  version: {
    attributes: {
      date: 'Date',
      field: 'Field',
      old: 'Old',
      new: 'New'
    }
  },
  company: {
    entity: 'Company | Companies',
    type: {
      client: 'Client',
      agent: 'Agent'
    },
    serviceTypes: {
      IOR: 'IOR',
      EOR: 'EOR'
    },
    rejectionReasons: {
      insufficient_information: 'Insufficient Information',
      profile_already_exists: 'Profile Already Exists',
      sanctioned_country: 'Sanctioned Country',
      other: 'Other',
      nan: ''
    },
    blackListReasons: {
      insufficient_information: 'Insufficient Information',
      profile_already_exists: 'Profile Already Exists',
      sanctioned_country: 'Sanctioned Country',
      other: 'Other',
      nan: ''
    },
    status: {
      cs_verification_required: 'Verification Required',
      modification_requested: 'Modification Requested',
      blacklisted: 'Blacklisted',
      approved: 'Approved',
      rejected: 'Rejected',
      price_list_requested: 'Price List Requested',
      in_review: 'In Review',
      draft: 'Draft'
    },
    attributes: {
      type: 'Type',
      serviceTypes: 'Service Types',
      status: 'Status',
      name: 'Company Name',
      registrationNumber: 'Registration Number',
      countriesCoverage: 'Countries Coverage',
      phoneCode: 'Phone Code',
      phone: 'Phone',
      code: 'Code',
      businessType: 'Business Type',
      address: 'Address',
      city: 'City',
      street: 'Street',
      country: 'Country',
      modificationFields: 'Modification Fields',
      contactPersonName: 'Contact Person Name',
      contactPersonPosition: 'Contact Person Position',
      tradeLicense: 'Trade License',
      businessRegistrationCertificate: 'Business Registration Certificate',
      productCategories: 'Product Categories',
      numberOfServiceCountries: 'Number Of Service Countries',
      registrationDate: 'Registration Date',
      lastUpdateDate: 'Last Update Date',
      blackListReason: 'Black List Reason',
      deliveryCity: 'Delivery City',
      rejectionReason: 'Rejection Reason',
      blacklistComment: 'Blacklist Comment',
      blacklistedSince: 'Blacklist Since',
      termsAndConditions: 'Terms and Conditions',
      statuses: {
        cs_verification_required: 'Verification Required',
        modification_requested: 'Modification Requested',
        blacklisted: 'Blacklisted',
        approved: 'Approved',
        rejected: 'Rejected',
        price_list_requested: 'Price List Requested',
        in_review: 'In Review',
        draft: 'Draft'
      }
    }
  },
  shipmentRequest: {
    entity: 'Shipment Request | Shipment Requests',
    attributes: {
      shipmentType: 'Shipment Type',
      shipmentTypes: {
        IOR: 'IOR',
        EOR: 'EOR'
      },
      shippingMethod: 'Shipping Method',
      shippingMethods: {
        air: 'Air',
        land: 'Land',
        sea: 'Sea'
      },
      shipperName: 'Shipper Name',
      shipmentsNumber: 'Number of Shipments',
      operatingShipmentsNumber: 'Number of Operating Shipments',
      totalShipments: 'Number of Destinations',
      consigneeName: 'Consignee Name',
      published: 'Published',
      unpublished: 'Unpublished',
      clientName: 'Client Name',
      requestDate: 'Request Date',
      identifier: 'Request Number',
      createdById: 'Created By',
      clientId: 'Client',
      shippingFrom: 'Shipping From',
      shippingTo: 'Shipping To',
      shippingFromName: 'Shipping From',
      destinations: 'Destinations',
      destinationsWithHelp: 'Destinations (multiple)',
      cancelledAt: 'Cancelled At',
      client: 'Client Company',
      reference: 'Reference',
      scope: 'Scope',
      scopeId: 'Scope Id',
      scopes: {
        door_to_door: 'Door to Door',
        airport_of_arrival_to_door: 'Airport of Arrival to Door',
        airport_of_departure_to_door: 'Airport of Departure to Door',
        airport_of_departure_to_airport_of_arrival: 'Airport of Departure to Airport of Arrival',
        door_to_airport_of_arrival: 'Door to Airport of Arrival',
        door_to_airport_of_departure: 'Door to Airport of Departure'
      }
    }
  },
  shipment: {
    entity: 'Shipment | Shipments',
    attributes: {
      number: 'Number',
      landingPort: 'Landing Port',
      arrivalPort: 'Arrival Port',
      preferredAgent: 'Preferred Agent',
      shippingFrom: 'Shipping From',
      destinationCountry: 'Destination Country',
      destinationCountryName: 'Destination Country',
      deliveryCity: 'Delivery City',
      deliveryAddressLineOne: 'Delivery Address',
      dimensions: 'Dimensions',
      packagingType: 'Packaging Type',
      packagingLength: 'Packaging Length',
      length: 'Length',
      packagingWidth: 'Packaging Width',
      width: 'Width',
      packagingHeight: 'Packaging Height',
      height: 'Height',
      dimensionsUnit: 'Dimensions Unit',
      weight: 'Weight',
      weightUnit: 'Weight Unit',
      totalWeight: 'Total Weight',
      unit: 'unit',
      quoteDeadline: 'Quote Deadline',
      currency: 'Currency',
      totalValue: 'Total Value',
      status: 'Status',
      shipmentRequestId: 'Shipment Request',
      createdAt: 'Created At',
      updatedAt: 'Updated At',
      shipmentDate: 'Shipment Date',
      requestDate: 'Request Date',
      scope: 'Scope',
      shipmentType: 'Shipment Type',
      identifier: 'Shipment Number',
      clientName: 'Client Name',
      agentName: 'Agent Name',
      offersSubmitted: 'Offers Submitted',
      quoteApproved: 'Quote Approved',
      quoteRecieved: 'Quote Recieved',
      clientWasInvoiced: 'Client was invoiced',
      clientPaymentDone: 'Client Payment Done',
      agentPaymentDone: 'Agent Payment Done',
      lastTransactionDate: 'Last Transaction Date',
      lastCommunicationDate: 'Last Communication Date',
      assistMe: 'Assist Me',
      filterBy: 'Filter By',
      visibleColumns: 'Visible Columns',
      agentId: 'Agent',
      cifValue: 'Cif Value',
      calculateCif: 'Calculate Cif',
      insuranceCost: 'Insurance Cost',
      freightCost: 'Freight Cost',
      temperatureControl: 'Temperature Control',
      temperature: 'Temperature',
      temperatureControlUnit: 'Temperature Control Unit',
      temperatureControlValue: 'Temperature Control Value',
      packagingTypeId: 'Packaging Type',
      preferredAgentId: 'Preferred Agent',
      packagingQuantity: 'Packaging Quantity',
      dimensionsAndWeights: 'Dimensions And Weights',
      endUserName: 'End User Name',
      packagingUnits: {
        cm: 'cm',
        inch: 'inch'
      },
      weightUnits: {
        lbs: 'lbs',
        kg: 'kg'
      },
      temperatureUnits: {
        fahrenheit: '°F',
        celsius: '°C'
      },
      shipmentStatus: 'Shipment Status',
      trackingStatus: 'Tracking Status',
      quoteStatus: 'Quote Status',
      statuses: {
        submitted: 'Submitted',
        agent_quotes_submitted: 'Agent Quotes Submitted',
        quote_approved: 'Quote Approved',
        cancelled: 'Cancelled',
        awaiting_cancellation_approval: 'Awaiting Cancellation Approval',
        final_documents_requested: 'Final Documents Requested',
        invoice_generated: 'Invoice Generated',
        completed: 'Completed',
        assigned_agent_rejected_shipment: 'Assigned Agent rejected Shipment',
        all_quotes_rejected: 'All Quotes Rejected',
        publish_to_other_agents_action_needed: 'Publish To Other Agents Action Needed',
        awaiting_completion_approval: 'Awaiting completion approval'
      },
      agentShipmentStatus: {
        rejected: 'rejected',
        rejectionWaitingApproval: 'waiting_for_approval_on_rejection',
        archived: 'Archived'
      },
      requestIdentifier: 'Request Number',
      documents: 'Documents',
      document: 'Document',
      route: 'Route',
      grandTotal: 'Grand Total'
    }
  },
  shipmentQuote: {
    entity: 'Shipment Quote | Shipment Quotes',
    attributes: {
      shipmentId: 'Shipment',
      lastUpdatedById: 'Last updated by Id',
      agentId: 'Agent',
      currency: 'Currency',
      iorFees: 'IOR Fee',
      totalIorFees: 'IOR Fee',
      totalEorFees: 'EOR Fee',
      totalLocalFees: 'Total Local Fees',
      totalDutiesTaxes: 'Total Duties Taxes',
      systemCommissions: 'System Commissions',
      identifier: 'Quote Number',
      adminFees: 'Admin Fees',
      status: 'Status',
      clientPaid: 'Client paid',
      paidToAgent: 'Paid to agent',
      plannedPayment: 'Planned payment',
      statuses: {
        waiting_agent_response: 'Awaiting Agent\'s Response',
        waiting_primary_user_approval: 'Awaiting Primary User\'s Approval',
        awaiting_reconfirmation: 'Awaiting Reconfirmation',
        modification_requested: 'Modification Requested',
        documents_requested: 'Documents Requested',
        documents_submitted: 'Documents Submitted',
        submitted: 'Submitted',
        revalidation_requested: 'Revalidation Requested',
        expired: 'Expired',
        rejected: 'Rejected',
        approved: 'Approved',
        archived: 'Archived'
      },
      agentCompany: 'Agent',
      expirationDate: 'Expiration Date',
      modificationsRequested: 'Modifications Requested',
      documentsRequested: 'Documents Requested',
      createdAt: 'Created At',
      updatedAt: 'Updated At',
      createdById: 'Created By',
      agentApprovedAt: 'Agent Approved At',
      rejectionReason: 'Rejection Reason',
      grandTotal: 'Grand Total',
      reference: 'Reference',
      route: 'route',
      ci: 'CI',
      cif: 'CIF',
      totalValue: 'Total Value',
      value: 'Value',
      percentage: 'Percentage',
      note: 'Note',
      notes: 'Notes',
      appliedOn: 'Applied On',
      vat: 'VAT',
      other_tax: 'Other Tax',
      createdAtByAgent: 'Submitted at',
      quantity: 'Quantity',
      valuePerItem: 'Value Per Item',
      submittedToClient: 'Submitted To Client'
    }
  },
  shipmentItem: {
    entity: 'Shipment Item | Shipment Items'
  },
  ticket: {
    entity: 'Ticket | Tickets',
    attributes: {
      ticketId: 'Ticket',
      category: 'Category',
      shipmentId: 'Shipment ID',
      shipmentIdPlaceholder: 'Type Shipment ID',
      categoryPlaceholder: 'Select Ticket Category',
      assigneePlaceholder: 'Select Assignee',
      commentPlaceholder: 'Comment',
      titlePlaceholder: 'Title',
      descriptionPlaceholder: 'Description Placeholder',
      title: 'Title',
      description: 'Description',
      file: 'Upload files',
      attachments: 'Attachments',
      identifier: 'Ticket Number',
      status: 'Status',
      reporter: 'Reporter',
      company: 'Company',
      assignee: 'Assignee',
      updatedDate: 'Updated Date',
      statuses: {
        open: 'Open',
        reopened: 'Reopened',
        in_progress: 'In Progress',
        resolved: 'Resolved'
      },
      comments: 'Comments',
      comment: 'Comment'
    }
  },
  comment: {
    entity: 'Comment | Comments'
  },
  productItem: {
    entity: 'Product Item | Product Items',
    attributes: {
      productCategory: 'Product Category',
      quantity: 'Quantity',
      valuePerItem: 'Value Per Item',
      descriptionOfGoods: 'Description Of Goods',
      hsCode: 'Hs Code',
      countryOfOrigin: 'Country Of Origin',
      totalValue: 'Total Value',
      comment: 'Comment',
      productNumber: 'Product Number',
      clientId: 'Client',
      createdAt: 'Created At',
      updatedAt: 'Updated At',
      itemType: 'Item Type',
      dataSheet: 'Data Sheet'
    }
  },
  systemConfiguration: {
    entity: 'System Configuration | System Configurations',
    attributes: {
      key: 'Key',
      unit: 'Unit',
      value: 'Value',
      file: 'File',
      keys: {
        archive_rejected_quote_period: 'Archive rejected quote period',
        shipment_percentage_to_calculate_instant_quote: 'Shipment percentage to calculate instant quote',
        agent_quote_validity: 'Agent quote validity',
        instant_quote_max_ior: 'Instant quote max IOR',
        instant_quote_min_ior: 'Instant quote min IOR',
        price_list_default_currency: 'Price list default currency',
        allowed_shipment_requests_for_non_verified_client: 'Allowed shipment requests for non verified client',
        before_shipment_deadline_reminder: 'Before shipment deadline reminder',
        display_agent_rating: 'Display agent rating',
        reapply_after_rejection: 'Reapply after rejection',
        session_timeout: 'Session timeout',
        preferred_agent_response_time: 'Preferred agent response time',
        quote_template: 'Quote template',
        bank_account_information: 'Bank account information',
        lifespan: 'Lifespan',
        display_client_rating: 'Display Client Rating',
        shipment_deadline_reminder: 'Shipment deadline reminder',
        shipment_deadline_reminder_with_minimum_bid_submissions: 'Shipment deadline reminder with less than minimum bids submissions',
        minimum_shipment_bids_limit: 'Minimum shipment bids limit',
        total_local_fees_percentage: 'Total local fees percentage',
        shipment_quote_commission_percent: 'Shipment quote commission percentage',
        shipment_quote_admin_fees: 'Shipment quote admin fees'
      },
      bankInfo: {
        bic: 'BIC',
        iban: 'IBAN',
        bankName: 'Bank Name',
        beneficiaryName: 'Beneficiary Name',
        currency: 'Currency'
      }
    }
  },
  dynamicList: {
    entity: 'Dynamic List | Dynamic Lists',
    attributes: {
      value: 'Value',
      listName: 'List Name',
      endStatus: 'End Status',
      listNames: {
        business_type: 'Business Types',
        product_category: 'Product Categories',
        packaging_types: 'Packaging Types',
        tracking_status: 'Tracking Statuses',
        ior_scopes: 'IOR Scopes',
        eor_scopes: 'EOR Scopes',
        ticket_categories: 'Ticket Categories'
      }
    }
  },
  bankAccountInformation: {
    entity: 'Bank Account Information',
    attributes: {
      bankName: 'Bank Name',
      bic: 'BIC',
      iban: 'IBAN',
      beneficiaryName: 'Beneficiary Name',
      currency: 'Currency'
    }
  },
  priceCategory: {
    entity: 'Price Category | Price Categories',
    attributes: {
      itemName: 'Item Name',
      mandatory: 'Mandatory',
      hsCode: 'HS Code',
      hs_code: 'HS Code',
      duties: 'Duties %',
      vat: 'VAT %',
      categoryTypes: {
        freight: 'Freight',
        thc: 'Terminal Handling Charge',
        clearance: 'Clearance',
        service: 'Service',
        handling: 'Handling',
        approval: 'Approval',
        documentation: 'Documentation',
        other_duties: 'Other Duties',
        delivery: 'Delivery',
        hs_code_list: 'HS Code List',
        vat: 'VAT',
        duties: 'Duties'
      },
      status: {
        active: 'Active',
        archived: 'Archived'
      },
      approval1: 'Approval 1',
      approval2: 'Approval 2',
      approval3: 'Approval 3',
      approval4: 'Approval 4',
      approval5: 'Approval 5',
      approval6: 'Approval 6'
    }
  },
  suggestedPrice: {
    entity: 'Suggested Price | Suggested Prices',
    attributes: {
      countryCode: 'Country Code',
      suggestedMin: 'Suggested Minimum',
      suggestedPercent: 'Suggested Percentage',
      priceCategoryItemId: 'Price Category Item'
    }
  },
  priceList: {
    entity: 'Price List | Price Lists',
    attributes: {
      countryCode: 'Country Code',
      min: 'Min',
      percent: 'Percent',
      comment: 'Comment',
      duties: 'Duties %',
      vat: 'VAT %',
      priceCategoryItemId: 'Price Category Item',
      suggestedPriceId: 'Suggested Price',
      agentId: 'Agent'
    }
  },
  companyProfileStep: {
    entity: 'Company Profile Step | Company Profile Steps',
    attributes: {
      name: 'Company Name',
      status: 'Status',
      rejectionReason: 'Rejection Reason',
      companyId: 'Company'
    }
  }
};
