import {ref} from 'vue';
import i18n from '@/core/plugins/i18n';
import _ from 'lodash';

export const uploader = (multiple, context, required = false) => {
  const files = ref([]);

  const handleRemove = (file, check) => {
    const index = files.value.indexOf(file);
    const newFileList = files.value.slice();
    newFileList.splice(index, 1);
    files.value = newFileList;
    context.emit('update:filesValue', files.value);
  };

  const beforeUpload = (file) => {
    files.value = multiple ? [...files.value, file] : [file];
    context.emit('update:filesValue', files.value);
    return false;
  };

  const validateFileSize = async () => {
    let valid = true;
    _.forEach(files.value, (file) => {
      if (file.size && file.size > 2.5e+7) {
        file.status = 'error';
        file.response = i18n.global.t('messages.error.fileSizeTooltip');
        valid = false;
      }
    });
    if (!valid) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(i18n.global.t('messages.error.fileSize'));
    } else {
      return Promise.resolve();
    }
  };

  const rules = [
    {
      validator: validateFileSize,
      type: 'array',
      trigger: 'change'
    }
  ];

  if (required) {
    rules.unshift({
      required: true,
      message: i18n.global.t('messages.error.required'),
      type: 'array',
      trigger: 'blur'
    });
  }

  return {
    files,
    rules,
    handleRemove,
    beforeUpload
  };
};
