<template>
  <div class="sc-chat-window" :class="{opened: isOpen, closed: !isOpen}">
    <single-chat-wrapper>
      <a-card>
        <message-list :messages="messages"
                      :me="me"
                      :on-load="onLoad"
                      :scroll-bottom="scrollBottom"
                      @scrollToTop="$emit('scrollToTop')"
                      @remove="$emit('remove', $event)"/>
        <template v-if="sendAttachment">
          <a-progress :percent="100" class="chat-upload" size="small"
                      status="active" :show-info="false" />
        </template>
        <user-input :show-file="showFile"
                    :disabled="disabled"
                    :placeholder="placeholder"
                    @onSubmit="handleSubmit"
                    @onType="$emit('onType', $event)"/>
      </a-card>
    </single-chat-wrapper>
  </div>
</template>

<script>
import MessageList from './MessageList';
import UserInput from './UserInput';
import {SingleChatWrapper} from './style';
import VueTypes from 'vue-types';
import {
  watch, ref, toRefs, computed
} from 'vue';
import {useStore} from 'vuex';

export default {
  emits: ['close', 'onSubmit', 'onType'],
  components: {
    MessageList,
    UserInput,
    SingleChatWrapper
  },
  props: {
    isOpen: VueTypes.bool.def(false),
    sendAttachment: VueTypes.bool.def(false),
    showFile: VueTypes.bool.def(false),
    showCloseButton: VueTypes.bool.def(true),
    disabled: VueTypes.bool.def(false),
    messages: VueTypes.array.required,
    me: VueTypes.object.required,
    placeholder: VueTypes.string.required,
    onLoad: VueTypes.func.required,
    onSubmit: VueTypes.func.required,
    quoteId: VueTypes.string.required
  },
  setup(props, context) {
    const {dispatch} = useStore();

    const scrollBottom = ref(false);
    const handleSubmit = (event) => {
      context.emit('onSubmit', event);
      scrollBottom.value = !scrollBottom.value;
    };

    const {isOpen, messages} = toRefs(props);

    watch(isOpen, (value) => {
      dispatch('markMessagesAsRead', props.quoteId);
      scrollBottom.value = !scrollBottom.value;
    });

    const count = computed(() => messages.value.length);
    watch(count, (value) => {
      if (isOpen.value) {
        dispatch('markMessagesAsRead', props.quoteId);
      }
    });

    return {
      handleSubmit,
      scrollBottom
    };
  }
};
</script>

<style scoped>
.chat-upload.ant-progress-line {
    position: relative;
    width: 74%;
    margin-left: 6%;
}
.sc-chat-window {
  width: 450px;
  z-index: 1000;
  position: fixed;
  right: 25px;
  bottom: 120px;
  box-sizing: border-box;
  box-shadow: 0 7px 40px 2px rgba(148, 149, 150, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  animation: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}

.sc-chat-window.closed {
  opacity: 0;
  display: none;
  bottom: 90px;
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  100% {
    display: flex;
    opacity: 1;
  }
}

@media (max-width: 450px) {
  .sc-chat-window {
    width: 100%;
    right: 0;
    bottom: 0;
    border-radius: 0;
  }

  .sc-chat-window {
    transition: 0.1s ease-in-out;
  }

  .sc-chat-window.closed {
    bottom: 0;
  }
}
</style>
