import {createStore} from 'vuex';
import companies from '@/store/modules/companies';
import companiesVerification from '@/store/modules/companiesVerification';
import shipmentRequests from '@/store/modules/shipmentRequests';
import shipments from './modules/shipments';
import shipmentQuotes from '@/store/modules/shipmentQuotes';
import session from '@/store/modules/session';
import layout from '@/store/modules/layout';
import messages from '@/store/modules/messages';
import notifications from '@/store/modules/notifications';
import dynamicLists from '@/store/modules/dynamicLists';
import headerSearchData from '@/store/modules/headerSearch';
import registrations from '@/store/modules/registrations';
import systemConfigurations from '@/store/modules/systemConfigurations';
import users from '@/store/modules/users';
import priceCategories from '@/store/modules/priceCategories';
import suggestedPrices from '@/store/modules/suggestedPrices';
import priceLists from '@/store/modules/priceLists';
import socket from '@/store/plugins/socket';
import tickets from '@/store/modules/tickets';
import reports from '@/store/modules/reports';

export default createStore({
  modules: {
    companies,
    companiesVerification,
    shipmentRequests,
    shipments,
    shipmentQuotes,
    session,
    layout,
    messages,
    notifications,
    dynamicLists,
    headerSearchData,
    registrations,
    systemConfigurations,
    users,
    priceCategories,
    suggestedPrices,
    priceLists,
    socket,
    tickets,
    reports
  }
});
