<template>
  <div :class="label ? classes : `${classes} field-without-label`">
    <a-form-item :label="label ? $t(label) : ' '" :name="fieldKey"  :rules="rules" :colon="colon">
      <a-select :size="size" :value="fieldValue"
                :placeholder="placeholder ? $t(placeholder) : ''"
                :allowClear="allowClear" :disabled="disabled"
                :show-search="enableSearch" :filterOption="filterOption"
                @search="onChange"
                @change="onChange"
                :notFoundContent="noDataMessage ? $t(noDataMessage) : undefined"
                option-label-prop="label" :show-arrow="showArrow"
                :dropdownAlign="dropdownAlign ? {overflow: { adjustY: 0 }} : undefined"
                @inputKeyDown="onKeyDown"
      >
        <a-select-option v-for="option in options"
          :key="option.code" :value="option.value" :label="option.label"
        >
          <country-flag v-if="showCountryFlags" :country="option.code" size="normal" :rounded="true" />
          <span>{{option.label}}</span>
          <span v-if="showCountryFlags">({{option.code}})</span>
        </a-select-option>
        <template v-if="loading" #notFoundContent>
          <a-spin size="small" />
        </template>
      </a-select>
    </a-form-item>
  </div>
</template>

<script>
import {defineComponent, ref} from 'vue';
import _ from 'lodash';
import VueTypes from 'vue-types';
import CountryFlag from 'vue-country-flag-next';

export default defineComponent({
  name: 'SelectField',
  emits: ['update:fieldValue'],
  components: {CountryFlag},
  props: {
    /**
   * Input Type
   * @values array<{value, label}>
   */
    options: VueTypes.Array,
    label: VueTypes.string.def(''),
    disabled: VueTypes.bool.def(false),
    loading: VueTypes.bool.def(false),
    selectType: VueTypes.string.def(''),
    fieldValue: VueTypes.oneOfType([VueTypes.number, VueTypes.string]),
    fieldKey: VueTypes.oneOfType([VueTypes.array, VueTypes.string]),
    placeholder: VueTypes.string.def(''),
    allowClear: VueTypes.bool.def(false),
    enableSearch: VueTypes.bool.def(false),
    rules: VueTypes.array.def(undefined),
    noDataMessage: VueTypes.string.def(undefined),
    size: VueTypes.string.def('default'),
    showCountryFlags: VueTypes.bool.def(false),
    showArrow: VueTypes.bool.def(true),
    dropdownAlign: VueTypes.bool.def(false),
    colon: VueTypes.bool.def(false),
    classes: VueTypes.string.def('')
  },
  setup(props, context) {
    const onChange = (value) => {
      context.emit('update:fieldValue', value);
    };
    const filterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        option.key?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const forbiddenKeys = ref([107, 109, 187, 189, 69, 190, 110, 188]);
    const onKeyDown = (e) => {
      const keyCode = e.keyCode;
      // prevent + - e .
      if (props.selectType === 'number' && (_.includes(forbiddenKeys.value, keyCode) || (keyCode >= 65 && keyCode <= 85))) {
        e.preventDefault();
        return false;
      }
    };

    return {
      onChange,
      filterOption,
      onKeyDown
    };
  }
});
</script>
