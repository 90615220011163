export default [
  {
    name: 'Client',
    path: '/client',
    redirect: {name: 'ClientShipmentRequests'},
    component: () => import('@/layout/appLayout.vue'),
    children: [
      {
        name: 'ClientShipmentRequests',
        path: 'shipment-requests',
        component: () => import('@/views/common/shipmentRequests/list.vue')
      },
      {
        name: 'ClientEdit',
        path: 'edit',
        component: () => import('@/views/client/EditProfile.vue'),
        meta: {
          abilitySubject: 'company',
          abilityAction: 'edit',
          skipCompanyGuard: true
        }
      },
      {
        name: 'ClientNewShipmentRequest',
        path: 'shipment-requests/new',
        component: () => import('@/views/client/shipmentRequests/form.vue')
      },
      {
        name: 'ClientEditShipmentRequest',
        path: 'shipment-requests/:shipmentRequestId',
        component: () => import('@/views/client/shipmentRequests/form.vue'),
        meta: {
          abilityAction: 'manage',
          abilitySubject: 'shipmentRequest'
        }
      },
      {
        name: 'ClientCloneShipmentRequest',
        path: 'shipment-requests/clone/:shipmentRequestId',
        component: () => import('@/views/client/shipmentRequests/form.vue'),
        meta: {
          abilityAction: 'manage',
          abilitySubject: 'shipmentRequest'
        }
      },
      {
        name: 'ClientShipmentRequestHistory',
        path: 'shipment-requests/:shipmentRequestId/history',
        component: () => import('@/views/client/shipmentRequests/history.vue'),
        meta: {
          abilityAction: 'read',
          abilitySubject: 'shipmentRequestHistory'
        }
      },
      {
        name: 'ClientShipmentRequestDetails',
        path: 'shipment-requests/:shipmentRequestId/details',
        component: () => import('@/views/common/shipmentRequests/details.vue')
      },
      {
        name: 'ClientShipmentsForm',
        path: 'shipment-requests/:shipmentRequestId/shipments',
        component: () => import('@/views/client/shipmentRequests/shipmentsFormSteps.vue'),
        meta: {
          abilityAction: 'manage',
          abilitySubject: 'shipmentRequest'
        }
      },
      {
        name: 'ClientShipmentsFormCloneShipmentRequest',
        path: 'shipment-requests/:shipmentRequestId/shipments/clone',
        component: () => import('@/views/client/shipmentRequests/shipmentsFormSteps.vue'),
        meta: {
          abilityAction: 'manage',
          abilitySubject: 'shipmentRequest'
        }
      },
      {
        name: 'ClientEditShipment',
        path: 'shipment-requests/:shipmentRequestId/shipments/:shipmentId',
        component: () => import('@/views/client/shipmentRequests/shipmentForm.vue'),
        meta: {
          abilityAction: 'manage',
          abilitySubject: 'shipmentRequest'
        }
      },
      {
        name: 'ClientCloneShipment',
        path: 'shipment-requests/clone/:shipmentRequestId/shipments/:shipmentId',
        component: () => import('@/views/client/shipmentRequests/shipmentForm.vue'),
        meta: {
          abilityAction: 'manage',
          abilitySubject: 'shipmentRequest'
        }
      },
      {
        name: 'ClientShipmentDetails',
        path: 'shipment-requests/:shipmentRequestId/shipments/:shipmentId/details',
        component: () => import('@/views/common/shipmentRequests/shipmentDetails.vue')
      },
      {
        name: 'ClientShipmentQuotes',
        path: 'shipment-requests/:shipmentRequestId/shipments/:shipmentId/quotes',
        component: () => import('@/views/common/shipmentQuotes/list.vue'),
        meta: {
          abilityAction: 'read',
          abilitySubject: 'shipmentQuote'
        }
      },
      {
        name: 'ClientShipmentQuote',
        path: 'shipment-requests/:shipmentRequestId/shipments/:shipmentId/quote/:quoteId',
        component: () => import('@/views/common/shipmentQuotes/quote.vue'),
        meta: {
          abilityAction: 'read',
          abilitySubject: 'shipmentQuote'
        }
      },
      {
        name: 'BankAccountInformation',
        path: 'bank-account-information',
        component: () => import('@/views/common/bankAccountInformation')
      },
      {
        name: 'ClientUsers',
        path: 'users',
        component: () => import('@/views/common/users/index'),
        meta: {
          abilitySubject: 'user',
          abilityAction: 'read'
        }
      },
      {
        name: 'ClientInviteUser',
        path: 'users/invite',
        component: () => import('@/views/common/users/invite'),
        meta: {
          abilitySubject: 'user',
          abilityAction: 'invite'
        }
      },
      {
        name: 'ClientUserProfile',
        path: 'users/:userId',
        component: () => import('@/views/common/users/user/index.vue'),
        redirect: {name: 'ClientUserBasicInfo'},
        children: [
          {
            path: 'profile',
            name: 'ClientUserBasicInfo',
            component: () => import('@/views/common/users/user/actions/profile.vue'),
            meta: {
              abilitySubject: 'user',
              abilityAction: 'read',
              requireCurrentUser: true
            }
          },
          {
            path: 'password',
            name: 'ClientChangePassword',
            component: () => import('@/views/common/users/user/actions/password.vue'),
            meta: {
              requireCurrentUser: true
            }
          },
          {
            path: 'permissions',
            name: 'ClientAccountPermissions',
            component: () => import('@/views/common/users/user/actions/permissions.vue'),
            meta: {
              abilitySubject: 'user',
              abilityAction: 'read',
              requireCurrentUser: true
            }
          },
          {
            path: 'history',
            name: 'ClientAccountHistory',
            component: () => import('@/views/common/users/user/actions/history.vue'),
            meta: {
              abilitySubject: 'allHistory',
              abilityAction: 'read',
              requireCurrentUser: true,
              companyGuard: true
            }
          }
        ]
      },
      {
        name: 'ClientCompanyProfile',
        path: 'company',
        component: () => import('@/views/common/companies/company'),
        meta: {
          abilitySubject: 'company',
          abilityAction: 'read',
          skipCompanyGuard: true
        }
      },
      {
        name: 'ClientTickets',
        path: 'tickets',
        component: () => import('@/views/common/tickets/list.vue')
      },
      {
        name: 'ClientTicketDetails',
        path: 'tickets/:ticketId/details',
        component: () => import('@/views/common/tickets/details.vue')
      }
    ],
    meta: {
      auth: true, client: true, companyNotRejected: true
    }
  }
];
