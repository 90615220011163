
<template>
  <div>
    <a-form-item :name="fieldKey" >
      <a-radio :checked="fieldValue" :disabled="disabled" class="black-span"
               @change="$emit('update:fieldValue', $event.target.checked)">
        {{ $t(label) }}
      </a-radio>
    </a-form-item>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'RadioButton',
  emits: ['update:fieldValue'],
  props: {
    label: VueTypes.string,
    fieldValue: VueTypes.bool.def(false),
    disabled: VueTypes.bool.def(false),
    visible: VueTypes.bool.def(true),
    fieldKey: VueTypes.oneOfType([VueTypes.array, VueTypes.string]),
    span: VueTypes.number.def(24)
  }
});
</script>

<style lang="scss" scoped>
.ant-checkbox-wrapper span + span {
    color: #000000 !important;
}
</style>
