import axiosInstance from '@/core/plugins/axios';
import i18n from '@/core/plugins/i18n';
import {displayServerErrorsInToaster} from '@/helpers/toasters';

export const downloadFile = async (filename, fileUrl) => {
  const response = await axiosInstance.get(`${process.env.VUE_APP_GCE_BASE_URL}/${fileUrl}`, {responseType: 'arraybuffer'});
  if (response.status === 200) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  } else {
    displayServerErrorsInToaster([i18n.global.t('messages.error.fileDownload')]);
  }
};

export const requestXlsx = async (dowloadLink, params, filename) => {
  const response = await axiosInstance.get(dowloadLink, {responseType: 'blob', params: params});
  if (response && response.status === 200) {
    const blob = new Blob([response.data], {type: 'application/vnd.ms-excel;charset=utf-8'});
    const data = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = data;
    link.download = filename;
    link.click();
    document.body.appendChild(link);
    window.URL.revokeObjectURL(blob);
  } else {
    displayServerErrorsInToaster([i18n.global.t('messages.error.fileDownload')]);
  }
};
