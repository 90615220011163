import {Meta} from '@/models/meta';
import {displayServerErrorsInToaster} from '@/helpers/toasters';

const state = {
  reports: []
};

const getters = {
  reports: (state) => state.reports
};

const actions = {
  async getReport({commit, getters}, data) {
    data.per_page = data.perPage;
    const response = await this.$http.get('/reports/predefined', {params: data});
    if (response.status === 200) {
      commit('setReports', response.data);
    } else {
      displayServerErrorsInToaster([this.$t('messages.error.failedToLoad')]);
      commit('setReports', []);
    }
  }
};

const mutations = {
  setReports: (state, reports) => {
    const meta = new Meta(reports.meta);
    state.reports = {data: reports.data, ...meta};
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
