import {displayServerErrorsInToaster, displaySuccessToaster} from '@/helpers/toasters';
import {utils} from 'jsonapi-vuex';
import _ from 'lodash';
import {Meta} from '@/models/meta';
import router from '@/routes';

const state = {
  tickets: [],
  ticket: {},
  comments: {
    data: [],
    page: 0,
    totalEntries: 0,
    totalPages: 0
  },
  pageable: {
    page: 1,
    perPage: 5
  },
  refreshTicketsTable: false
};

const getters = {
  ticket: (state) => state.ticket,
  tickets: (state) => state.tickets,
  comments: (state) => state.comments,
  refreshTicketsTable: (state) => state.refreshTicketsTable
};

const actions = {
  async createTicket({commit, dispatch}, {formData}) {
    const response = await this.$http.post('/tickets', formData);
    if (response.status === 200) {
      displaySuccessToaster(this.$t('messages.success.created', {entity: this.$t('models.ticket.entity', 1)}));
      const ticketRoutes = ['AgentTickets', 'ClientTickets', 'BackOfficeTickets'];
      if (_.includes(ticketRoutes, router.currentRoute._value.name)) {
        commit('setRefreshTicketsTable', true);
      }
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async updateTicket({commit}, data) {
    const response = await this.$http.put(
      `/tickets/${data.ticketId}`,
      data.formData
    );
    if (response.status === 200) {
      commit('setTicket', {ticket: response.data});
      displaySuccessToaster(this.$t('messages.success.updated', {entity: this.$t('models.ticket.entity', 1)}));
      if (data.formData.get('comments_attributes[][content]')) {
        const lastComment = response.data.data.attributes.lastComment;
        commit('pushComment', lastComment.data.attributes);
      }
    } else if (!`${response.status}`.startsWith(2)) {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async getTickets({commit, getters}, data) {
    const response = await this.$http.get('/tickets', {params: data});
    if (response.status === 200) {
      commit('setTickets', response.data);
    } else {
      commit('setTickets', []);
    }
  },
  async getTicket({commit}, ticketId) {
    const response = await this.$http.get(`/tickets/${ticketId}`);
    if (response.status === 200) {
      commit('setTicket', {ticket: response.data});
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  resetRefreshTicketsTable({commit}, data) {
    commit('setRefreshTicketsTable', data);
  },
  async createComment({commit}, {ticketId, formData}) {
    const response = await this.$http.post(`/tickets/${ticketId}/comments`, formData);
    if (response.status === 200) {
      displaySuccessToaster(this.$t('messages.success.created', {entity: this.$t('models.comment.entity', 1)}));
      commit('pushComment', response.data.data.attributes);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async getComments({commit}, {ticketId, data}) {
    if (state.comments.totalPages > 0 && state.comments.totalPages + 1 === state.pageable.page) return;
    const response = await this.$http.get(`/tickets/${ticketId}/comments`, {
      params: {
        page: state.pageable.page,
        per_page: state.pageable.perPage
      }
    });
    if (response.status === 200) {
      const data = _.values(utils.jsonapiToNorm(response.data.data))
        .reverse();
      const meta = new Meta(response.data.meta);
      commit('pushComments', {data, ...meta});
      commit('incrementPage');
    } else {
      commit('setComments', []);
    }
  },
  reloadComments({commit}) {
    commit('clearComments');
  }
};

const mutations = {
  setTicket: (state, {ticket}) => {
    const normalisedTicket = utils.jsonapiToNorm(ticket.data);
    if (normalisedTicket._jv.relationships.shipment) {
      const shipment = normalisedTicket._jv.relationships.shipment;
      if (shipment && shipment.data) {
        normalisedTicket._jv.relationships.shipment = utils.jsonapiToNorm(
          _.find(ticket.included, {id: shipment.data.id, type: 'shipment'})
        );
      }
    }
    if (normalisedTicket._jv.relationships.createdBy) {
      const createdBy = normalisedTicket._jv.relationships.createdBy;
      if (createdBy && createdBy.data) {
        normalisedTicket._jv.relationships.createdBy = utils.jsonapiToNorm(
          _.find(ticket.included, {id: createdBy.data.id, type: 'user'})
        );
      }
    }
    if (normalisedTicket._jv.relationships.company) {
      const company = normalisedTicket._jv.relationships.company;
      if (company && company.data) {
        normalisedTicket._jv.relationships.company = utils.jsonapiToNorm(
          _.find(ticket.included, {id: company.data.id, type: 'company'})
        );
      }
    }
    if (normalisedTicket._jv.relationships.assignee) {
      const assignee = normalisedTicket._jv.relationships.assignee;
      if (assignee && assignee.data) {
        normalisedTicket._jv.relationships.assignee = utils.jsonapiToNorm(
          _.find(ticket.included, {id: assignee.data.id, type: 'user'})
        );
      }
    }
    state.ticket = normalisedTicket;
  },
  setTickets: (state, tickets) => {
    const normalisedTickets = _.map(tickets.data, (ticket) => {
      const normalisedTicket = utils.jsonapiToNorm(ticket);
      const createdBy = normalisedTicket._jv.relationships.createdBy;
      const assignee = normalisedTicket._jv.relationships.assignee;
      if (createdBy && createdBy.data) {
        normalisedTicket._jv.relationships.createdBy = utils.jsonapiToNorm(
          _.find(tickets.included, {
            id: createdBy.data.id,
            type: 'user'
          })
        );
      }
      if (assignee && assignee.data) {
        normalisedTicket._jv.relationships.assignee = utils.jsonapiToNorm(
          _.find(tickets.included, {
            id: assignee.data.id,
            type: 'user'
          })
        );
      } else {
        normalisedTicket._jv.relationships.assignee = null;
      }
      return normalisedTicket;
    });
    const meta = new Meta(tickets.meta);
    state.tickets = {data: normalisedTickets, ...meta};
  },
  setRefreshTicketsTable: (state, value) => {
    state.refreshTicketsTable = value;
  },
  setComments: (state, comments) => {
    const normalisedComments = _.map(comments.data, (comment) => {
      const normalisedComment = utils.jsonapiToNorm(comment);
      return normalisedComment;
    });
    const meta = new Meta(comments.meta);
    state.comments = {data: normalisedComments, ...meta};
  },
  pushComments: (state, comments) => {
    state.comments.data.push(...comments.data);
    state.comments.page = comments.page;
    state.comments.totalEntries = comments.totalEntries;
    state.comments.totalPages = comments.totalPages;
  },
  pushComment: (state, comment) => {
    state.comments.data.unshift(comment);
  },
  incrementPage: (state) => {
    if (state.pageable.page < state.comments.totalPages ) {
      state.pageable.page++;
    }
  },
  clearComments(state) {
    state.comments = {
      data: [],
      page: 0,
      totalEntries: 0,
      totalPages: 0
    };
    state.pageable.page = 1;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
