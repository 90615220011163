import axios from 'axios';
import store from '@/store';
import _ from 'lodash';

const axiosInstance = axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use((config) => {
  config.headers['access-token'] = store.state.session.accessToken;
  config.headers['client'] = store.state.session.client;
  config.headers['uid'] = store.state.session.uid;
  config.baseURL = store.getters.baseURL;
  return config;
}, (error) => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => {
  if (!_.isNil(response)) {
    return response;
  }
}, (error) => {
  if (_.isNil(error) || _.isNil(error.response) || error.response.status === 500) {
    // TODO: Sentry
    // eslint-disable-next-line no-console
    console.error(error);
  } else {
    switch (error.response.status) {
    case 401:
      if (!window.location.pathname.toLowerCase().includes('/auth') && !window.location.pathname.toLowerCase().includes('/registration')) {
        store.dispatch('resetSession');
        window.location = '/';
      }
      return {
        errors: error.response.data.errors,
        status: error.response.status
      };
    case 403:
      window.location = '/forbidden';
      return {
        errors: [],
        status: error.response.status
      };
    default:
      return {
        errors: error.response.data.errors,
        errorFields: error.response.data.error_fields,
        countries: error.response.data.countries,
        status: error.response.status
      };
    }
  }
});

export default axiosInstance;
