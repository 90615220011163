export default {
  login: {
    forgotPassword: 'Forgot password?',
    keepMeLoggedIn: 'Keep me logged in',
    wantToSignInAs: 'Want to sign in as',
    signInAs: 'Sign in as',
    backOffice: 'Back Office',
    client: 'Client',
    agent: 'Agent',
    signIn: 'sign in',
    registerHere: 'Register Here',
    noAccount: "Don't have an account?",
    or: ' or',
    reApplyHere: 'Reapply here',
    reapply: 'Reapply'
  },
  forgotPassword: {
    title: 'Forgot Password?',
    message: 'Please enter the email address you used when you joined',
    sendResetInstructions: 'Send Reset Instructions',
    returnToLogin: 'Return to Login Page'
  },
  setPassword: {
    title: 'Set Password',
    message: 'Please enter your new password',
    sendResetInstructions: 'Set Password'
  },
  breadcrumbs: {
    identifier: '#{identifier}'
  },
  shipmentRequest: {
    title: 'Shipment Request',
    edit: 'Modify Shipment Request',
    clone: 'Clone Shipment Request',
    old: 'Old :',
    new: 'New :',
    history: 'History',
    shipments: 'Shipments',
    shipment: 'Shipment',
    newShipment: 'New Shipment',
    editShipment: 'Edit Shipment',
    totalShipments: 'Total Shipments',
    showAffectedShipments: 'Show affected shipments',
    list: 'List',
    details: 'Request Details',
    viewQuotes: 'View Quotes',
    viewQuote: 'View Quote',
    submitQuote: 'Submit Quote',
    submitQuoteWithTemplate: 'Submit Quote using default template',
    addQuoteTemplate: 'Your quote template is currently empty. Create a new quote and mark it as template.',
    shipmentDetails: 'Shipment Details',
    shipmentHistory: 'Shipment History',
    shipmentDocuments: 'Shipment Documents',
    selectShipmentTypeToViewScopes: 'Please select a shipment type to view the scope\'s options',
    one: '1. ',
    two: '2. ',
    form: {
      stepOne: 'Shipment Request Data',
      stepTwo: 'Shipment Request Items'
    },
    shipmentForm: {
      stepOne: 'Shipment Data',
      stepTwo: 'Shipment Items'
    },
    setTrackingStatus: 'Set Tracking Status',
    trackingStatus: 'Tracking Status',
    identifier: 'SR{id}'
  },
  shipment: {
    selectItems: 'Select Items',
    shippingInfo: 'Shipping Information',
    itemsValue: 'Items Value',
    previewInvoice: 'Preview Invoice',
    downloadInvoice: 'Download Invoice'
  },
  shipmentQuote: {
    quotes: 'Quotes',
    quote: 'Quote',
    breakdown: 'Quote Breakdown',
    index: 'Shipment Quotes',
    details: 'Shipment Quote Details',
    history: 'Shipment Quote History',
    title: 'Shipment Quote',
    downloadBreakdown: 'Download Quote Breakdown',
    confirmRevalidate: 'Confirm Quote Revalidate',
    noModifications: 'No Modifications Have Been Requested',
    noDocuments: 'No Documents Have Been Requested',
    instant: 'Instant Quote',
    quoteWithIdentifier: 'Quote #{identifier}',
    agent: 'Agent',
    date: 'Date',
    submitQuote: 'Submit Quote',
    editQuote: 'Edit Quote',
    addAdditionalQuote: 'Add Additional Quote',
    agentInfo: 'Agent Information',
    appliedOn: 'Applied On',
    note: 'Note',
    shipmentDetails: 'Shipment Details',
    addCategory: 'Add Category',
    additionalQuote: 'Additional Quote',
    plannedPayment: 'Planned payment',
    clientPaid: 'Mark client paid',
    clientPaidDone: 'Client paid',
    paidToAgent: 'Mark paid to agent',
    paidToAgentDone: 'Paid to agent',
    quotePaid: 'Paid'
  },
  ticket: {
    title: 'Ticket',
    details: 'Ticket Details',
    startProgress: 'Start Progress Ticket',
    resolve: 'Resolve Ticket',
    close: 'Close Ticket',
    reopen: 'Reopen Ticket',
    assign: 'Assign Ticket'
  },
  fileUploadCard: {
    helper: 'Drop files here to upload'
  },
  notifications: {
    title: 'Notifications',
    markAllAsRead: 'Mark all as read'
  },
  chat: {
    placeholder: 'Message...',
    chatHistory: 'Chat History'
  },
  confirm: {
    navigation: {
      title: 'Confirm Navigation',
      message: 'Are you sure you want to leave this step? If you have any unsaved changes they will be lost.'
    },
    blacklistCompany: {
      title: 'Confirm Company Blacklisting',
      message: 'Are you sure you want to blacklist this company?'
    },
    rejectCompany: {
      title: 'Confirm Company Rejection',
      message: 'Are you sure you want to reject this company?'
    },
    rejectQuote: {
      title: 'Confirm Quote Rejection',
      message: 'Are you sure you want to reject this quote?'
    },
    approveQuote: {
      title: 'Confirm Quote Approval',
      message: 'Are you sure you want to approve this quote?'
    },
    deleteShipment: {
      title: 'Confirm Delete Shipment',
      message: 'Are you sure you want to delete this shipment?'
    },
    cancelShipment: {
      title: 'Confirm Cancel Shipment',
      message: 'Are you sure you want to cancel this shipment?'
    },
    completeShipment: {
      title: 'Approve Shipment Completion',
      message: 'Are you sure you want to complete this shipment?'
    },
    reopenShipment: {
      title: 'Reopen Shipment',
      message: 'Are you sure you want to reopen this shipment?'
    },
    cancelShipmentRequest: {
      title: 'Confirm Cancel Shipment Request',
      message: 'Are you sure you want to cancel this shipment request?'
    },
    publishShipmentRequest: {
      title: 'Confirm Publish Shipment Request',
      message: 'Are you sure you want to publish this shipment request?'
    },
    publishShipment: {
      title: 'Confirm Publish Shipment',
      message: 'Are you sure you want to publish this shipment to other agents?'
    },
    leavePage: {
      title: 'Confirm Leave Page',
      message: 'Are you sure you want to leave this page? You have unsaved changes.'
    },
    removeDestination: {
      title: 'Confirm Submit',
      message: 'Are you sure you want to save? Removing a destination automatically cancels the associated shipment (if present).'
    },
    removeItem: {
      title: 'Confirm Remove Item',
      message: 'Are you sure you want to remove this item?'
    },
    sanctionCountry: {
      title: 'Confirm Add Country to Sanctioned',
      message: 'Are you sure you want to sanction this country? This action will affect existing shipment requests.'
    },
    deleteUser: {
      title: 'Confirm Delete User',
      message: 'Are you sure you want to delete this user?'
    },
    removeCountryCoverage: {
      title: 'Confirm Remove Country',
      message: 'Are you sure you want to remove this country? This action will remove related price lists (if present).'
    },
    approveCancelShipment: {
      title: 'Approve Shipment Cancellation',
      message: 'Are you sure you want to approve this shipment cancellation?'
    },
    rejectCancelShipment: {
      title: 'Reject Shipment Cancellation',
      message: 'Are you sure you want to reject this shipment cancellation?'
    },
    waitForCompletionApproval: {
      title: 'Wait For Completion Approval',
      message: 'Are you sure you want to update this shipment status to be waiting for completion approval?'
    },
    startProgressTicket: {
      title: 'Start Progress Ticket',
      message: 'Are you sure you want to start progress for this ticket?'
    },
    resolveTicket: {
      title: 'Resolve Ticket',
      message: 'Are you sure you want to resolve this ticket?'
    },
    closeTicket: {
      title: 'Close Ticket',
      message: 'Are you sure you want to close this ticket?'
    },
    reopenTicket: {
      title: 'Reopen Ticket',
      message: 'Are you sure you want to reopen this ticket?'
    },
    clientPayment: {
      title: 'Confirm client payment',
      message: 'Are you sure you want to confirm client payment for this shipment quote?'
    },
    paymentToAgent: {
      title: 'Confirm payment to agent',
      message: 'Are you sure you want to confirm payment to agent for this shipment quote?'
    }
  },
  registration: {
    title: 'Register Your Company',
    companyDetails: 'Company Details',
    address: 'Address',
    uploadDocuments: 'Upload Documents',
    products: 'Products',
    priceList: 'Price List',
    countriesCoverage: 'Countries Coverage',
    countries: 'Countries',
    country: 'Country',
    category: 'Category',
    selectedCountries: 'Selected Countries',
    termsAndConditions: 'Agreements',
    termsAndConditionsAgree: 'I have read and agree with the Terms and Conditions.',
    done: 'Done!',
    thankYou: 'Thank you!',
    verification: 'Your account will now enter a verification process by our team.',
    allowedShipments: 'You can make {number} shipment requests until the verification process is finished.',
    loginHere: 'click here to log in',
    continueRegistration: 'Continue Registration',
    draftSaved: 'Draft Saved Successfully!',
    noMandatory: 'This category has no mandatory items to price. You can go forward or select an optional category item to price.',
    homePage: 'Go to home page',
    priceListSummary: 'Price List Summary'
  },
  systemConfigurations: {
    title: 'System Configurations',
    edit: 'Edit Configuration',
    params: 'Configuration Parameters'
  },
  dynamicLists: {
    title: 'Dynamic Lists',
    addItem: 'Add Item',
    enterValue: 'Enter a value',
    endState: 'Final State',
    markAsEndState: 'Mark As Final State'
  },
  sanctionedCountries: {
    allowed: 'Allowed Countries',
    sanctioned: 'Sanctioned Countries',
    moveToSanctioned: 'Move To Sanctioned',
    removeFromSanctioned: 'Remove From Sanctioned'
  },
  users: {
    edit: 'Edit User',
    title: 'Users',
    permissionsSearch: 'Filter By Permissions',
    details: {
      title: '1. Please Enter the User\'s Details',
      name: 'Name: ',
      email: 'Email: ',
      firstName: 'First Name',
      lastName: 'Last Name',
      emailAddress: 'Email Address'
    },
    permissions: {
      index: 'Permission',
      title: '2. Please Select the User\'s Permissions'
    },
    review: {
      title: '3. Review and confirm user invitation',
      success: {
        title: 'Invitation Successful',
        body: 'An invitation has been successfully sent to '
      }
    }
  },
  company: {
    title: 'Company',
    details: 'Company Details',
    history: 'Company History'
  },
  agent: {
    title: 'Agents'
  },
  userProfile: {
    title: 'Profile',
    changePassword: 'Change Password',
    password: 'Password',
    editProfile: 'Edit Profile',
    permissions: 'Permissions',
    permissionsCaption: 'View current permissions',
    history: 'History'
  },
  priceCategories: {
    title: 'Price Categories and Sub Categories',
    bulkUpload: 'Upload HS Codes',
    hsCodesUploaded: 'HS Codes uploaded successfully!',
    searchHSCodes: 'Search Codes'
  },
  suggestedPrices: {
    title: 'Suggested Prices',
    country: 'Country',
    category: 'Category'
  },
  profileVerification: {
    verificationProcess: 'Verification Process',
    title: 'Company Verification',
    rejectionReason: 'Rejection Reason',
    rejectionReasonPlaceholder: 'Please provide the reasons for rejection',
    companyDetails: 'Company Details',
    address: 'Address',
    uploadedDocuments: 'Uploaded Documents',
    termsAndConditions: 'Terms and Conditions Accepted',
    overallProfileStatus: 'Overall Profile Status',
    submitDecision: 'Submit Decision ({decision})',
    blackList: 'Black List'
  },
  backOfficeShipments: {
    tabTitle: {
      all: 'All',
      sanctioned: 'Sanctioned Destination',
      cancelled: 'Awaiting Cancellation Approval',
      completed: 'Awaiting Completion Approval'
    }
  },
  reports: {
    fileName: 'File Name',
    filterBy: 'Filter by',
    from: 'From',
    to: 'To'
  }
};
