import app from '../../config/configApp';
import {UserCard} from '@/views/shared/style.js';

[
  {
    name: 'UserCard',
    ...UserCard
  }
].map((c) => {
  app.component(`sd${c.name}`, c);
});
