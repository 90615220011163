<template>
  <Footer>
    <div class="chatbox-reply-form d-flex">
      <div class="chatbox-reply-input">
        <a-textarea v-model:value="message"
                 id="chat"
                 name="message"
                 autocomplete="off"
                 :disabled="disabled || isSubmitting"
                 :placeholder="placeholder"
                 :style="{width: '100%'}"
                 @keydown="handleKey"
                 @input="handleInput"
                 class="chat-textarea">
        </a-textarea>
      </div>
      <div class="chatbox-reply-action d-flex">
        <a to="#">
          <a-upload
            :showUploadList="true"
            :before-upload="beforeUpload"
            :disabled="disabled || isSubmitting"
            class="chat-upload-file"
            v-model:file-list="files"
            >
            <sd-button v-if="!files.length">
              <sd-feather-icons type="paperclip" size="18"/>
            </sd-button>
            <sd-button v-else @click="clear">
              <sd-feather-icons type="trash-2" size="18"/>
            </sd-button>
          </a-upload>
        </a>
        <sd-button type="primary" class="btn-send" @click="handleSubmit">
          <sd-feather-icons type="send" size="5"/>
        </sd-button>
      </div>
    </div>
  </Footer>
</template>

<script>
import {ref} from 'vue';
import VueTypes from 'vue-types';
import _ from 'lodash';
import {useI18n} from 'vue-i18n';
import {Footer} from './style';
import {displayServerErrorsInToaster} from '@/helpers/toasters';

export default {
  name: 'UserInput',
  emits: ['onSubmit', 'onType'],
  components: {Footer},
  props: {
    showFile: VueTypes.bool.def(false),
    disabled: VueTypes.bool.def(false),
    onSubmit: VueTypes.func.required,
    placeholder: VueTypes.string.required
  },
  setup(props, context) {
    const {t} = useI18n();
    const message = ref('');
    const files = ref([]);

    const clear = ($event) => {
      $event?.stopPropagation();
      if (!_.isNull(files.value[0])) {
        files.value = [];
      }
      message.value = '';
    };
    const beforeUpload = (data) => {
      if (data.size > 2.5e+7) {
        displayServerErrorsInToaster([t('messages.error.fileSize')]);
      }
      return false;
    };
    const handleKey = (event) => {
      if (event.keyCode === 13 && !event.shiftKey && !isSubmitting.value) {
        handleSubmit();
        event.preventDefault();
      }
    };
    const handleInput = () => {
      context.emit('onType', message.value);
    };

    const isSubmitting = ref(false);

    const handleSubmit = () => {
      if (!_.isEmpty(message.value) || files.value[0]) {
        isSubmitting.value = true;

        const data = {
          content: message.value,
          file: files.value[0]?.originFileObj
        };
        context.emit('onSubmit', data);
        clear();
        isSubmitting.value = false;
      }
    };

    return {
      message,
      files,
      clear,
      isSubmitting,
      beforeUpload,
      handleKey,
      handleInput,
      handleSubmit
    };
  }
};
</script>

<style scoped>
.ant-tooltip {
  z-index: 100000;
}
.chatbox-reply-form {
  padding-top: 20px
}
.chat-textarea {
  padding: 12px 11px !important;
  resize: none;
}
</style>
<style lang="scss">
.chat-upload-file {
  .anticon-delete {
    display: none!important;
  }
  .ant-upload-list {
    bottom: 60px!important;
  }
  .button-wrapper {
    width: unset !important;
  }
  button {
    background: #F4F5F7!important;
  }
}
</style>
