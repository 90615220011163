<template>
  <div>
    <a-page-header>
      <template #extra>
        <a-breadcrumb :routes="routes">
          <template #itemRender="{ route, routes }">
            <span v-if="routes.indexOf(route) === routes.length - 1" class="current-route">
              {{breadcrumbName(route)}}
            </span>
            <router-link v-else :to="route">
              {{breadcrumbName(route)}}
            </router-link>
          </template>
        </a-breadcrumb>
        <div class="page-header-actions">
          <template v-for="(button, index) in buttons" :key="index">
            <sdButton v-if="button.visible!==false" size="small" :type="button.type || 'primary'"
                      @click="$emit('headerAction', button.action)" :disabled="button.disabled"
                      :tooltip="button.tooltip" :tooltipPlacement="button.tooltipPlacement"
            >
              <sdFeatherIcons v-if="button.icon" :type="button.icon" size="14" />
              {{$t(button.label)}}
            </sdButton>
          </template>
        </div>
      </template>
    </a-page-header>
  </div>
</template>
<script>
import {defineComponent} from 'vue';
import {useI18n} from 'vue-i18n';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'AppBreadcrumbs',
  emits: ['headerAction'],
  props: {
    routes: VueTypes.array.def([]),
    buttons: VueTypes.array.def([])
  },
  setup() {
    const {t} = useI18n();
    const breadcrumbName = (route) => {
      if (route.paramName && route.paramValue) {
        return t(route.breadcrumbName, {[route.paramName]: route.paramValue});
      } else {
        return t(route.breadcrumbName);
      }
    };

    return {
      breadcrumbName
    };
  }
});
</script>

<style lang="scss" scoped>

.ant-page-header {
  margin-bottom: 0;
  padding-top: 5px;
}
.ant-breadcrumb {
  padding-top: 30px;
  padding-left: 30px;
}

.page-header-actions button.ant-btn-white svg {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  color: #F79742;
}

i +span, svg +span, img +span {
  margin-left: 6px;
}

.current-route {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 900;
  font-size: 16px;
  line-height: 32px;
}
</style>
