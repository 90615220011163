import Styled from 'vue3-styled-components';
import {PageHeader} from 'ant-design-vue';

// eslint-disable-next-line new-cap
const PageHeaderStyle = Styled(PageHeader)`
  margin-bottom: 0;

  .page-header-actions button.ant-btn-white svg {
    width: 12px;
    height: 12px;
    ${({theme}) => (theme.rtl ? 'margin-left' : 'margin-right')}: 2px;
    color: #F79742;
  }
  i +span, svg +span, img +span {
      ${({theme}) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 6px;
  }
`;

export {PageHeaderStyle};
