export default {
  success: {
    created: '{entity} was created succesfully!',
    updated: '{entity} was updated succesfully!',
    cloned: '{entity} was cloned successfully!',
    approved: '{entity} was approved succesfully!',
    rejected: '{entity} was rejected succesfully!',
    published: '{entity} was published succesfully!',
    cancelled: '{entity} was cancelled succesfully!',
    completed: '{entity} was completed succesfully!',
    reopened: '{entity} was reopened successfully!',
    deleted: '{entity} was deleted succesfully!',
    revalidated: '{entity} was revalidated succesfully!',
    uploaded: '{entity} uploaded succesfully!',
    requestRevalidation: 'Revalidation requested succesfully!',
    statusUpdated: '{entity} status was updated successfully!',
    cancellationRejected: '{entity} cancellation has been rejected successfully!',
    cancellationRequest: '{entity} cancellation request has been sent successfully!',
    passwordSet: 'Your password has been set successfully!',
    userInvited: 'User invited successfully!',
    markedAsTemplate: 'Quote is marked as a template successfully!'
  },
  error: {
    required: 'required',
    nonZero: 'must be greater than 0',
    minRange: 'should be at least {min}',
    maxRange: 'should be at most {max}',
    nonNegative: 'must be 0 or more',
    rangeNumerical: 'should be between {min} and {max} numerical digits',
    phoneNumberFormat: 'Only numbers are allowed',
    phoneNumberRange: 'should be between {min} and {max} numerical digits',
    rangeText: 'Max limit of characters is {max}',
    notAMatch: 'not a match',
    minLength: 'must be at least {min} characters',
    noDecimals: 'can\'t have decimals',
    email: 'invalid email',
    fileSize: 'File size exceeds limit of 25MB',
    fileSizeTooltip: 'Exceeds size limit',
    emailFormat: 'invalid email format',
    login: 'The email and password you entered did not match our records. Please double-check and try again',
    fileDownload: 'Couldn\'t download file',
    filePreview: 'Couldn\'t preview file',
    fileFormatExcel: 'Invalid file format. Please upload an excel file',
    noItemsSelected: 'At least one item must be selected to continue',
    notAuthorisedToResetPassword: 'A valid token is required to set your password',
    invalidCredentials: 'Invalid email or password',
    invalidLoginCredentials: 'Invalid login credentials. Please try again',
    countriesCoverageRequired: 'Please select the countries your company covers to continue',
    companyApprovalRequired: 'Your company must be approved by our team before you can log in',
    selectHsCode: 'Please add price for at least one HS Code to continue',
    emailTaken: 'Email is already taken',
    companyBlacklisted: 'Your company is blacklisted until {date}',
    companyRejected: 'Your company has been rejected',
    companyAlreadyRegistered: 'Your company has already been registered. Please log in.',
    cannotReApply: 'You can only reapply when your company is rejected',
    alreadyVerified: 'Company already verified!',
    failedToLoad: 'Failed to load this page',
    from_after_to: 'To date can not be after from',
    range_must_exists: 'Filter Date Range must exists'
  },
  help: {
    noQuoteSubmitted: 'No quote submitted yet',
    increaseQuantity: 'Increase quantity to add more measurements',
    usd: 'Currency: USD',
    archivedPriceCategory: 'Archived Category Item',
    companyInReview: 'Company is currently being reviewed',
    shipmentPendingCancellation: 'Shipment might get cancelled',
    completedShipments: 'One or more shipments have been delivered',
    chatEmpty: 'No messages yet'
  }
};
