<template>
  <FormValidationWrap>
    <VerticalFormStyleWrap>
      <a-form name="sDash_validation-form"
              ref="formRef"
              layout="vertical"
              :hideRequiredMark="hideRequiredMark"
              :validateOnRuleChange="validateOnRuleChange"
              :model="formState"
              :rules ="rules"
      >

        <a-form-item v-if="hasAlertList">
          <alert-list>
            <slot name="alertList"/>
          </alert-list>
        </a-form-item>

        <a-row :gutter="[32,0]">
          <template v-for="(field, key) in fields" :key="key">
            <a-col v-if="!field.hidden" :span="field.span"
                   :xs="field.styles.xs" :sm="field.styles.sm" :md="field.styles.md"
                   :lg="field.styles.lg" :xl="field.styles.xl" :class="field.styles.classes">
              <label v-if="field.type === 'label'" :fieldKey="key">
                {{formState[key]}}
              </label>
              <text-field v-if="field.type === 'text'" v-bind="field"
                          :fieldKey="key" :field-value="formState[key]"
                          @update:fieldValue="inputChanged(key, $event)" />
              <password-field v-if="field.type === 'password'" v-bind="field"
                              :fieldKey="key" :field-value="formState[key]"
                              :validateAgainst="field.validateAgainst ? formState[field.validateAgainst] : ''"
                              @update:fieldValue="inputChanged(key, $event)"
                              @validateField="validateField" />
              <number-field v-if="field.type === 'number'" v-bind="field"
                            :fieldKey="key" :field-value="formState[key]"
                            @update:fieldValue="inputChanged(key, $event)" />
              <text-field v-if="field.type === 'inputField'" v-bind="field"
                          :fieldKey="key" :field-value="formState[key]"
                          @update:fieldValue="inputChanged(key, $event)" />
              <text-area v-else-if="field.type === 'textArea'" v-bind="field"
                         :fieldKey="key" :field-value="formState[key]"
                         @update:fieldValue="inputChanged(key, $event)" />
              <date-picker v-else-if="field.type === 'datePicker'" v-bind="field"
                           :fieldKey="key" :field-value="formState[key]"
                           @update:fieldValue="inputChanged(key, $event)" />
              <time-picker v-else-if="field.type === 'timePicker'" v-bind="field"
                           :fieldKey="key" :field-value="formState[key]"
                           @update:fieldValue="inputChanged(key, $event)" />
              <select-field v-else-if="field.type === 'select'" v-bind="field"
                            :fieldKey="key" :field-value="formState[key]" :loading="field.loading"
                            @update:fieldValue="inputChanged(key, $event)" />
              <check-box v-else-if="field.type === 'checkbox'" v-bind="field"
                         :fieldKey="key" :field-value="formState[key]"
                         @update:fieldValue="inputChanged(key, $event)" />
              <switch-field v-else-if="field.type === 'switch'" v-bind="field"
                         :fieldKey="key" :field-value="formState[key]"
                         @update:fieldValue="inputChanged(key, $event)" />
              <radio-button v-else-if="field.type === 'radioButton'" v-bind="field"
                            :fieldKey="key" :field-value="formState[key]"
                            @update:fieldValue="inputChanged(key, $event)" />
              <multiple-select v-else-if="field.type === 'multipleSelect'" v-bind="field"
                               :fieldKey="key" :field-value="formState[key]"
                               @update:fieldValue="inputChanged(key, $event)" />
              <file-uploader v-else-if="field.type === 'file'" v-bind="field"
                             :fieldKey="key" :files-value="formState[key]"
                             @update:files-value="handleFiles($event, key)" />
            </a-col>
          </template>
        </a-row>

        <slot name="actions"/>

        <div class="sDash_form-action">
          <a-button v-if="!submitButton.hidden"
                    :class="`btn-signin mr-10 ${submitButton.classes}`"
                    size="small"
                    htmlType="submit"
                    :loading="loading"
                    :disabled="loading"
                    :type="submitButton.type"
                    @click="submitForm">
            <span v-if="!submitButton.icon">
              {{submitButton.label ? $t(submitButton.label) : $t('actions.submit')}}
            </span>
            <template v-else>
                <span>{{submitButton.label ? $t(submitButton.label) : $t('actions.submit')}}</span>
                <sdFeatherIcons :type="submitButton.icon" size="15" />
            </template>
          </a-button>
          <template v-if="cancelButton.visible">
            <sdButton v-if="cancelButton.visible"
                      :type="cancelButton.type"
                      class="btn-outlined "
                      :size="cancelButton.size"
                      :outlined="cancelButton.outlined"
                      @click="cancelSubmit">
                <span>{{cancelButton.label ? $t(cancelButton.label) : $t('actions.cancel')}}</span>
            </sdButton>
          </template>
        </div>
      </a-form>
    </VerticalFormStyleWrap>
  </FormValidationWrap>
</template>

<script>
import {
  defineComponent, ref, reactive, toRaw, toRefs, watch
} from 'vue';
import _ from 'lodash';
import moment from 'moment';
import VueTypes from 'vue-types';
import {VerticalFormStyleWrap, FormValidationWrap} from './Style';
import TextField from '../fields/Text';
import PasswordField from '../fields/Password';
import NumberField from '../fields/Number';
import DatePicker from '../fields/DatePicker';
import TimePicker from '../fields/TimePicker';
import SelectField from '../fields/Select';
import CheckBox from '../fields/CheckBox';
import RadioButton from '../fields/RadioButton';
import TextArea from '../fields/TextArea';
import MultipleSelect from '../fields/MultipleSelect';
import FileUploader from '../fields/FileUploader';
import {AlertList} from '../styledComponents/Main';
import {FieldStyles} from '../fields/styles';
import SwitchField from '@/components/shared/fields/Switch';
import {scrollToFirstError} from '@/helpers/scrollToFirstErrorInForm';

export default defineComponent({
  name: 'AppForm',
  components: {
    AlertList,
    VerticalFormStyleWrap, FormValidationWrap,
    TextField, PasswordField, NumberField, TextArea,
    DatePicker, TimePicker, SelectField, MultipleSelect,
    CheckBox, RadioButton, FileUploader, SwitchField
  },
  props: {
    hasAlertList: VueTypes.bool.def(false),
    fields: VueTypes.object,
    rules: VueTypes.object.def({}),
    title: VueTypes.string.def(''),
    hideRequiredMark: VueTypes.bool.def(false),
    loading: VueTypes.bool.def(false),
    submitButton: VueTypes.object.def({
      type: 'primary', block: false, size: 'middle'
    }),
    cancelButton: VueTypes.object.def({
      type: 'light', visible: false, block: false, size: 'middle', outlined: true
    }),
    resetForm: VueTypes.bool.def(false),
    validateOnRuleChange: VueTypes.bool.def(false),
    triggerFormSubmit: VueTypes.bool.def(false),
    trackTouched: VueTypes.bool.def(false)
  },
  emits: ['submitted', 'inputChanged', 'cancel', 'forceUpdated', 'submitFormTriggered', 'fieldsTouched', 'update:resetForm'],
  setup(props, context) {
    const formRef = ref();
    const {
      fields, triggerFormSubmit, trackTouched
    } = toRefs(props);
    const formState = reactive({});
    _.forEach(fields.value, (field, key) => {
      formState[key] = field.fieldValue;
      field.styles = new FieldStyles(field.styles);
    });

    watch(fields, (newValue) => {
      const updatedKeys = [];
      _.forEach(newValue, (field, key) => {
        if (field.forceUpdate) {
          updatedKeys.push(key);
          formRef.value.clearValidate(key);
          formState[key] = field.type === 'number' ? field.fieldValue || 0 :
            field.fieldValue || undefined;
        }
      });
      context.emit('forceUpdated', updatedKeys);
    }, {deep: true});

    watch(triggerFormSubmit, (value) => {
      if (value) {
        submitForm();
        context.emit('submitFormTriggered');
      }
    });

    const handleFieldsTouched = () => {
      if (trackTouched.value) {
        context.emit('fieldsTouched');
      }
    };

    const inputChanged = (key, value) => {
      handleFieldsTouched();
      formState[key] = value;
      if (fields.value[key].trackable) {
        context.emit('inputChanged', {key, value: formState[key]});
      }
    };

    const handleFiles = (value, key) => {
      handleFieldsTouched();
      formState[key] = value;
      fields.value[key]['forceUpdate'] = false;
    };

    const submitForm = (e) => {
      if (e) e.preventDefault();
      formRef.value
        .validate()
        .then(() => {
          const data = {};
          _.forOwn(toRaw(formState), function(value, key) {
            if (moment.isMoment(value)) {
              data[_.snakeCase(key)] = value.format('YYYY-MM-DD');
            } else if (fields.value[key].type === 'multipleSelect' && fields.value[key].selectAllOption) {
              data[_.snakeCase(key)] = _.without(value, 'systemSelection:selectAll');
            } else {
              data[_.snakeCase(key)] = value !== undefined ? value : '';
            }
          });
          context.emit('submitted', data);
        }).catch(({errorFields}) => {
          scrollToFirstError(formRef, errorFields);
        });
    };

    const cancelSubmit = (e) => {
      e?.preventDefault();
      formRef.value.resetFields();
      context.emit('cancel');
    };

    const {resetForm} = toRefs(props);
    watch(resetForm, (newValue) => {
      if (newValue) {
        formRef.value.resetFields();
        context.emit('update:resetForm', false);
      }
    });

    const validateField = (key) => {
      formRef.value.validateFields(key);
    };

    return {
      formState,
      formRef,
      inputChanged,
      submitForm,
      cancelSubmit,
      handleFiles,
      validateField
    };
  }
});
</script>
