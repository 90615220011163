import {utils} from 'jsonapi-vuex';
import {Meta} from '@/models/meta';
import _ from 'lodash';
import {messageStatuses} from '@/constants';

const state = {
  participants: {},
  isOpen: false,
  all: {
    data: [],
    page: 0,
    totalEntries: 0,
    totalPages: 0,
    unread: 0
  },
  pageable: {
    page: 1,
    perPage: 100
  }
};

const getters = {
  messages: (state) => state.all,
  participants: (state) => state.participants,
  me: () => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return {
      id: currentUser?._jv?.id,
      name: `${currentUser?.firstName} ${currentUser?.lastName}`,
      email: currentUser?.email
    };
  }
};

const actions = {
  async getMessages({state, commit}, id) {
    const params = {
      page: state.pageable.page,
      per_page: state.pageable.perPage
    };
    if (Array.isArray(state?.all?.data) && state?.all?.data?.length) {
      params['prev_page_last_id'] = state.all.data.at(0)?.id;
    }
    const response = await this.$http.get(`/shipment_quotes/${id}/messages`, {params});
    if (response.status === 200) {
      const participants = utils.jsonapiToNorm(response.data.included);
      const meta = new Meta(response.data.meta);
      const data = utils.jsonapiToNorm(response.data.data);

      commit('addParticipants', participants);
      commit('setPageable', meta);
      commit('unshiftMessages', data);
      commit('incrementMessagesPage');
    }
    return response.status;
  },
  async getUnreadMessagesCount({state, commit}, quoteId) {
    const response = await this.$http.get(`/shipment_quotes/${quoteId}/messages/unread_count`);
    if (response.status === 200) {
      commit('setUnread', response.data.count);
    }
  },
  async markMessagesAsRead({state, commit}, quoteId) {
    const previousUnread = state.all.unread;
    commit('setUnread', 0);
    const response = await this.$http.put(`/shipment_quotes/${quoteId}/messages/mark_as_read`);
    if (response.status !== 200) {
      commit('setUnread', previousUnread);
    }
  },
  subscribeToMessagesChannel({dispatch}, quoteId) {
    dispatch('channelSubscribe', {
      command: 'subscribe',
      identifier: JSON.stringify({
        channel: 'ChatChannel',
        shipment_quote_id: quoteId
      })
    });
  },
  unsubscribeToMessagesChannel({dispatch}, quoteId) {
    dispatch('channelUnsubscribe', {
      command: 'unsubscribe',
      identifier: JSON.stringify({
        channel: 'ChatChannel',
        shipment_quote_id: quoteId
      })
    });
  },
  async sendMessage({
    state, commit, getters
  }, {id, data}) {
    const formData = new FormData();

    formData.append('content', data.content);
    if (data.file != null) {
      formData.append('attachments', data.file);
    }

    if (_.isEmpty(data.file)) {
      const message = {
        ...data, author: getters.me, status: messageStatuses.PENDING
      };
      commit('pushPendingMessage', message);
      commit('incrementUnread', message);
    }

    const index = state.all.data.length - 1;
    const response = await this.$http.post(`/shipment_quotes/${id}/messages`, formData);
    if (_.isEmpty(data.file)) {
      if (response.status === 200) {
        commit('updateMessageStatus', {index: index, status: messageStatuses.RECEIVED});
      } else {
        commit('updateMessageStatus', {index: index, status: messageStatuses.ERROR});
      }
    }

    return response;
  },
  clearChat({commit}) {
    commit('clearParticipants');
    commit('clearMessages');
    commit('clearPageable');
  }
};

const mutations = {
  unshiftMessages: (state, messages) => {
    state.all.data.unshift(...normalizeAll(messages));
  },
  incrementUnread: (state, message) => {
    if (+getters.me().id !== message.sendableId && !state.isOpen) {
      state.all.unread += 1;
    }
  },
  pushMessages: (state, messages) => {
    if (Array.isArray(state.all.data) && state.all.data[state.all.data.length - 1]?.id !== messages[0]?._jv?.id) {
      state.all.data.push(...normalizeAll(messages));
    }
  },
  pushPendingMessage: (state, message) => {
    state.all.data.push(message);
  },
  updateMessageStatus: (state, {status, index}) => {
    state.all.data[index].status = status;
  },
  addParticipants: (state, participants) => Object.assign(state.participants, participants),
  setPageable: (state, pageable) => {
    state.all.page = pageable.page;
    state.all.totalEntries = pageable.totalEntries;
    state.all.totalPages = pageable.totalPages;
  },
  incrementMessagesPage: (state) => {
    if (state.pageable.page < state.all.totalPages) {
      state.pageable.page++;
    }
  },
  clearParticipants: (state) => state.participants = {},
  clearMessages: (state) => state.all = {
    data: [],
    page: 0,
    totalEntries: 0,
    totalPages: 0,
    unread: 0
  },
  clearPageable: (state) => state.pageable = {
    page: 1,
    perPage: 10
  },
  setUnread: (state, value) => state.all.unread = value,
  setIsOpen: (state, value) => state.isOpen = value
};

const normalizeAll = (messages) => {
  return _.values(messages).map((entry) => normalize(entry));
};

const normalize = (message) => {
  const author = state.participants[message.sendableId];

  return {
    id: message._jv.id,
    content: message.content,
    time: message.createdAt * 1000,
    seen: message.read,
    author: {
      id: author._jv.id,
      name: `${author.firstName} ${author.lastName}`,
      email: author.email
    },
    attachments: message.attachments,
    status: messageStatuses.RECEIVED
  };
};

export default {
  state,
  getters,
  actions,
  mutations
};
