<template>
  <CardFrame
    v-if="!headless"
    :size="size"
    :bodyStyle="bodyStyle && bodyStyle"
    :headStyle="headStyle && headStyle"
    :bordered="border"
    :bodypadding="bodypadding"
    :style="{ width: '100%' }"
  >
    <template v-slot:title>
      <template v-if="icon">
        <sdFeatherIcons :type="icon" size="20" />
      </template>
      {{title}}
      <template v-if="tag">
        <a-tooltip :title="tagTooltip">
          <a-tag :color="tagColor">{{tag}}</a-tag>
        </a-tooltip>
      </template>
      <template v-if="paymentTag">
        <a-tooltip :title="tagTooltip">
          <a-tag :color="paymentTagColor">{{$t('views.shipmentQuote.quotePaid')}}</a-tag>
        </a-tooltip>
      </template>

      <span v-if="caption">{{caption}}</span>
    </template>
    <template v-slot:extra>
      <sdDropdown v-if="more" :action="['click']" placement="bottomCenter">
        <template v-slot:overlay>
          <slot name="more"></slot>
        </template>
        <router-link v-if="!moreText" to="#"><sdFeatherIcons type="more-horizontal" size="24"/></router-link>
        <router-link v-else to="#">More</router-link>
      </sdDropdown>
      <slot name="button"></slot>
    </template>
    <slot></slot>
  </CardFrame>

  <CardFrame
    :bodypadding="bodypadding"
    :bodyStyle="bodyStyle && bodyStyle"
    :size="size"
    :style="{ width: '100%' }"
    :bordered="border"
    v-else
  >
    <sdHeading v-if="title" as="h4">{{ title }}</sdHeading>
    <template #title>
      <slot name="title"></slot>
    </template>
    <p v-if="caption">{{ caption }}</p>
    <slot></slot>
  </CardFrame>
</template>

<script>
import {CardFrame} from './style';
import VueTypes from 'vue-types';
export default {
  name: 'Cards',
  components: {
    CardFrame
  },
  props: {
    title: VueTypes.oneOfType([VueTypes.string, VueTypes.object, VueTypes.node]),
    size: VueTypes.oneOf(['default', 'small']).def('default'),
    more: VueTypes.bool.def(false),
    bodyStyle: VueTypes.object,
    headStyle: VueTypes.object,
    headless: VueTypes.bool.def(false),
    border: VueTypes.bool.def(false),
    caption: VueTypes.string,
    bodypadding: VueTypes.string.def('25px'),
    moreText: VueTypes.bool.def(false),
    tag: VueTypes.string.def(''),
    tagColor: VueTypes.string.def('blue'),
    tagTooltip: VueTypes.string.def(''),
    icon: VueTypes.string.def(''),
    paymentTag: VueTypes.bool.def(false),
    paymentTagColor: VueTypes.string.def('blue')
  }
};
</script>

<style lang="scss" scoped>
.ant-tag {
  color: #FFF !important;
}
</style>
