import {displayServerErrorsInToaster, displaySuccessToaster} from '@/helpers/toasters';
import _ from 'lodash';
import {utils} from 'jsonapi-vuex';
import buildAbility from '@/core/plugins/ability';

const state = {
  priceLists: []
};

const getters = {
  priceLists: (state) => state.priceLists
};

const actions = {
  async getPriceLists({commit}, data) {
    const response = await this.$http.get('/price_lists', {params: data});
    if (response.status === 200) {
      if (_.has(data, 'category_type')) {
        commit('setPriceLists', response.data);
      } else {
        commit('setCompanyPriceLists', response.data);
      }
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async updatePriceLists({commit, rootState}, {data, skipToaster}) {
    const response = await this.$http.put('/price_lists/update_prices', data);
    if (`${response.status}`.startsWith(2)) {
      commit('setCompanyPriceLists', response.data);
      commit('setUserCompanyStatus', response.data.status);
      this.$ability.update(buildAbility(rootState.session.currentUser.permissions).rules);
      if (!skipToaster) {
        displaySuccessToaster(this.$t('views.registration.draftSaved'));
      }
      return {success: true};
    } else {
      displayServerErrorsInToaster(response.errors[0][_.snakeCase('hsColumns')]);
      return {success: false, inlineErrors: response.errors[0]['price']};
    }
  }
};

const mutations = {
  setPriceLists: (state, {data}) => state.priceLists = _.values(utils.jsonapiToNorm(data))
};

export default {
  state,
  getters,
  actions,
  mutations
};
