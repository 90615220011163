<template>
  <a-tooltip :title="tooltip" :autoAdjustOverflow="true" :arrowPointAtCenter="true" :placement="tooltipPlacement">
    <span class="button-wrapper">
      <ButtonStyled
        :squared="squared"
        :outlined="outlined"
        :ghost="ghost"
        :transparent="transparented"
        :raised="raised"
        :data="type"
        :size="size"
        :shape="shape"
        :type="type"
        :icon="icon"
        :color="color"
        :social="social"
        :click="() => load && enterLoading"
        :loading="loading"
        :block="block"
        :disabled="disabled || loading"
        :class="`ant-btn ant-btn-${type} ${shape && `ant-btn-${shape}`} ${block && `ant-btn-block`}`"
      >
        <slot></slot>
      </ButtonStyled>
    </span>
  </a-tooltip>
</template>
<script>
import {ButtonStyled, ButtonStyledGroup} from './styled';
import VueTypes from 'vue-types';
export const Button = {
  components: {
    ButtonStyled
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    enterLoading: function() {
      this.loading = true;
    }
  },
  props: {
    type: VueTypes.oneOf([
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'link',
      'dark',
      'light',
      'white',
      'dashed',
      'error',
      'default'
    ]).def('default'),
    shape: VueTypes.string,
    icon: VueTypes.string,
    size: VueTypes.oneOf(['large', 'default', 'small']).def('default'),
    color: VueTypes.string,
    outlined: VueTypes.bool.def(false),
    block: VueTypes.bool.def(false),
    disabled: VueTypes.bool.def(false),
    transparented: VueTypes.bool.def(false),
    raised: VueTypes.bool.def(false),
    squared: VueTypes.bool.def(false),
    social: VueTypes.bool.def(false),
    load: VueTypes.bool.def(false),
    ghost: VueTypes.bool.def(false),
    tooltip: VueTypes.string.def(undefined),
    tooltipPlacement: VueTypes.string.def('top')
  }
};

export const BtnGroup = {
  components: {
    ButtonStyledGroup
  },

  render() {
    return <ButtonStyledGroup>{this.$slots.default()}</ButtonStyledGroup>;
  }
};
export default Button;
</script>

<style lang="scss" scoped>
.button-wrapper {
  width: 0px !important;
}
.fill-width {
  width: 100% !important;
}
</style>
