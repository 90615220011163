import {utils} from 'jsonapi-vuex';
import {displayServerErrorsInToaster} from '@/helpers/toasters';
import {Meta} from '@/models/meta';
import _ from 'lodash';

const state = () => ({
  all: {
    data: [],
    page: 0,
    totalEntries: 0,
    totalPages: 0,
    badge: 0
  },
  pageable: {
    page: 1,
    perPage: 5
  }
});

const getters = {
  notifications: (state) => state.all
};

const actions = {
  async getNotifications({state, commit}, {badgeOnly}) {
    const response = await this.$http.get('/notifications', {
      params: {
        page: state.pageable.page,
        per_page: state.pageable.perPage
      }
    });
    if (response.status === 200) {
      const data = _.values(utils.jsonapiToNorm(response.data.data))
        .reverse();
      const meta = new Meta(response.data.meta);
      commit('pushNotifications', {
        data, badge: response.data.badge, ...meta, badgeOnly
      });
      if (!badgeOnly) commit('incrementPage');
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async readNotification({commit}, id) {
    const response = await this.$http.put(`/notifications/${id}/read`);
    if (response.status === 200) {
      commit('setNotificationToRead', id);
      commit('decrementBadge');
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async markAllAsRead({commit}) {
    const response = await this.$http.put('/notifications/mark_all_as_read');
    if (response.status === 200) {
      commit('readAllNotifications');
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },

  // Notification Channel
  subscribeToNotificationChannel({dispatch}, userId) {
    dispatch('notificationChannelSubscribe', {
      command: 'subscribe',
      identifier: JSON.stringify({
        channel: 'NotificationChannel',
        user_id: userId
      })
    });
  },
  unsubscribeToNotificationChannel({dispatch}, userId) {
    dispatch('notificationChannelUnsubscribe', {
      command: 'unsubscribe',
      identifier: JSON.stringify({
        channel: 'NotificationChannel',
        user_id: userId
      })
    });
  }
};

const mutations = {
  pushNotifications: (state, notifications) => {
    if (!notifications.fromSocket) {
      if (!notifications.badgeOnly) state.all.data.push(...notifications.data);
      state.all.page = notifications.page;
      state.all.totalPages = notifications.totalPages;
      state.all.totalEntries = notifications.totalEntries;
      state.all.badge = notifications.badge;
    } else {
      state.all.data.unshift(...notifications.data);
      state.all.badge += 1;
      state.all.totalEntries += 1;
    }
  },
  incrementPage: (state) => {
    if (state.pageable.page < state.all.totalPages ) {
      state.pageable.page++;
    }
  },
  decrementBadge: (state) => state.all.badge = state.all.badge > 0 ? state.all.badge - 1 : 0,
  setNotificationToRead: (state, id) => {
    const index = _.findIndex(state.all.data, (notification) => notification._jv.id === id);
    state.all.data[index].read = true;
  },
  readAllNotifications: (state) => {
    _.forEach(state.all.data, (notification) => notification.read = true);
    state.all.badge = 0;
  },
  clearNotifications(state) {
    state.all = {
      data: [],
      page: 0,
      totalEntries: 0,
      totalPages: 0
    };
    state.pageable.page = 1;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
