import Launcher from './Launcher.vue';
import emitter from './emitter';

const defaultComponentName = 'AppTicket';

export default {
  install(app, options = {}) {
    if (this.installed) {
      return;
    }

    this.installed = true;
    app.config.globalProperties.$event = emitter;

    this.componentName = options.componentName || defaultComponentName;

    app.component(this.componentName, Launcher);
  }
};
