export default [
  {
    name: 'BackOffice',
    path: '/back-office',
    redirect: {name: 'BackOfficeSystemConfigurations'},
    component: () => import('@/layout/appLayout.vue'),
    children: [
      {
        name: 'BackOfficeShipments',
        path: 'shipments',
        component: () => import('@/views/backOffice/shipments/list.vue'),
        meta: {
          abilityAction: 'read',
          abilitySubject: 'shipment'
        }
      },
      {
        name: 'BackOfficeShipmentDetails',
        path: 'shipments/:shipmentId',
        component: () => import('@/views/common/shipmentRequests/shipmentDetails.vue'),
        meta: {
          abilityAction: 'read',
          abilitySubject: 'shipment'
        }
      },
      {
        name: 'BackOfficeShipmentQuotes',
        path: 'shipments/:shipmentId/quotes',
        component: () => import('@/views/common/shipmentQuotes/list.vue'),
        meta: {
          abilityAction: 'read',
          abilitySubject: 'shipmentQuote'
        }
      },
      {
        name: 'BackOfficeSystemConfigurations',
        path: 'system-configurations',
        component: () => import('@/views/backOffice/systemConfigurations/list.vue'),
        meta: {
          abilitySubject: 'systemConfiguration',
          abilityAction: 'read'
        }
      },
      {
        name: 'BackOfficeDynamicLists',
        path: 'dynamic-lists',
        component: () => import('@/views/backOffice/systemConfigurations/dynamicLists.vue'),
        meta: {
          abilitySubject: 'dynamicList',
          abilityAction: 'read'
        }
      },
      {
        name: 'BackOfficeSanctionedCountries',
        path: 'sanctioned-countries',
        component: () => import('@/views/backOffice/systemConfigurations/sanctionedCountries.vue'),
        meta: {
          abilitySubject: 'sanctionedCountry',
          abilityAction: 'read'
        }
      },
      {
        name: 'BackOfficeUsers',
        path: 'users',
        component: () => import('@/views/common/users'),
        meta: {
          abilitySubject: 'user',
          abilityAction: 'read'
        }
      },
      {
        name: 'BackOfficeAgentCompanies',
        path: 'companies/agent',
        component: () => import('@/views/backOffice/companies'),
        meta: {
          abilitySubject: 'company',
          abilityAction: 'read'
        }
      },
      {
        name: 'BackOfficeClientCompanies',
        path: 'companies/client',
        component: () => import('@/views/backOffice/companies'),
        meta: {
          abilitySubject: 'company',
          abilityAction: 'read'
        }
      },
      {
        name: 'CompanyProfile',
        path: 'companies/:companyId',
        component: () => import('@/views/common/companies/company'),
        meta: {
          abilitySubject: 'company',
          abilityAction: 'read'
        }
      },
      {
        name: 'BackOfficeProfileVerification',
        path: 'companies/:companyId/verify',
        component: () => import('@/views/backOffice/companies/profileVerification.vue')
      },
      {
        name: 'BackOfficeInviteUser',
        path: 'users/invite',
        component: () => import('@/views/common/users/invite'),
        meta: {
          abilitySubject: 'user',
          abilityAction: 'invite'
        }
      },
      {
        name: 'BackOfficeUserProfile',
        path: 'users/:userId',
        component: () => import('@/views/common/users/user/index.vue'),
        redirect: {name: 'BackOfficeUserBasicInfo'},
        children: [
          {
            path: 'profile',
            name: 'BackOfficeUserBasicInfo',
            component: () => import('@/views/common/users/user/actions/profile.vue'),
            meta: {
              abilitySubject: 'user',
              abilityAction: 'read',
              requireCurrentUser: true
            }
          },
          {
            path: 'password',
            name: 'BackOfficeChangePassword',
            component: () => import('@/views/common/users/user/actions/password.vue'),
            meta: {requireCurrentUser: true}
          },
          {
            path: 'permissions',
            name: 'BackOfficeAccountPermissions',
            component: () => import('@/views/common/users/user/actions/permissions.vue'),
            meta: {
              abilitySubject: 'user',
              abilityAction: 'read',
              requireCurrentUser: true
            }
          },
          {
            path: 'history',
            name: 'BackOfficeAccountHistory',
            component: () => import('@/views/common/users/user/actions/history.vue')
          }
        ]
      },
      {
        name: 'BackOfficePriceCategories',
        path: 'price-categories',
        component: () => import('@/views/backOffice/pricelists/priceCategories.vue'),
        meta: {
          abilitySubject: 'priceCategoryItem',
          abilityAction: 'manage'
        }
      },
      {
        name: 'BackOfficeSuggestedPrices',
        path: 'suggested-prices',
        component: () => import('@/views/backOffice/pricelists/suggestedPrices.vue'),
        meta: {
          abilitySubject: 'suggestedPrice',
          abilityAction: 'manage'
        }
      },
      {
        name: 'BackOfficeTickets',
        path: 'tickets',
        component: () => import('@/views/common/tickets/list.vue')
      },
      {
        name: 'BackOfficeTicketDetails',
        path: 'tickets/:ticketId/details',
        component: () => import('@/views/common/tickets/details.vue')
      },
      {
        name: 'BackOfficeReports',
        path: 'reports',
        component: () => import('@/views/backOffice/reports/list.vue'),
        meta: {
          abilitySubject: 'report',
          abilityAction: 'manage'
        }
      }
    ],
    meta: {auth: true, backOffice: true}
  }
];
