<template>
  <div>
    <a-modal :visible="visible" :class="classes" :title="title" @ok="onOk" :closable="true"
              @cancel="onCancel" :footer="null" :confirmLoading="confirmLoading"
              :width="width">
      <slot></slot>
    </a-modal>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
export default defineComponent({
  name: 'Modal',
  components: {
  },
  props: {
    onCancel: VueTypes.func,
    onOk: VueTypes.func,
    visible: VueTypes.bool.def(false),
    confirmLoading: VueTypes.bool.def(false),
    title: VueTypes.string.def(''),
    classes: VueTypes.string,
    width: VueTypes.number.def(620)
  }
});
</script>

<style lang="scss" scoped>
.ant-modal-title {
  color: #fff;
}
.ant-modal-footer button {
  background: #fff;
  color: #999;
  border: 1px solid #ffff;
}
</style>
