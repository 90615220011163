<template>
  <ul ref="container" class="atbd-chatbox" style="overflow-y:auto">
    <vue-eternal-loading :load="onLoad" :container="container" position="top">
      <template #loading>
        <div class="text-center">
          <a-spin/>
        </div>
      </template>
      <template #no-more>
        <div/>
      </template>
      <template #no-results>
        <div>
          <a-empty
            v-if="showEmptyMessage && $_.isEmpty(messages)"
            :description="$t('messages.help.chatEmpty')"
          />
        </div>
      </template>
    </vue-eternal-loading>

      <li v-for="message in messages" :key="message.id" class="atbd-chatbox__single">
        <message :key="message.id"
                 :content="message.content"
                 :author="message.author"
                 :time="message.time"
                 :image="message.image"
                 :attachments="message.attachments"
                 :status="message.status"
                 :me="me"/>

      </li>
  </ul>
</template>

<script>
import Message from './Message.vue';
import VueTypes from 'vue-types';
import {
  computed, nextTick, ref, toRefs, watch
} from 'vue';

export default {
  components: {
    Message
  },
  props: {
    messages: VueTypes.array.required,
    me: VueTypes.object.required,
    onLoad: VueTypes.func.required,
    scrollBottom: VueTypes.bool.required,
    showEmptyMessage: VueTypes.bool.def(false)
  },
  setup(props) {
    const container = ref(null);
    const handleScrollBottom = () => {
      nextTick(() => {
        container.value.scrollTop = container.value.scrollHeight;
      });
    };

    const {scrollBottom, messages} = toRefs(props);
    const messagesLength = computed(() => messages.value.length);
    let prevMessagesLength = messagesLength.value;

    watch(messagesLength, () => {
      if (messagesLength.value - prevMessagesLength === 1) {
        handleScrollBottom();
      }
      prevMessagesLength = messagesLength.value;
    });
    watch(scrollBottom, () => handleScrollBottom());

    return {
      container
    };
  }
};
</script>
