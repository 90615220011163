<template>
  <div>
    <a-form-item :label="label? $t(label) : ''" :name="fieldKey"  :rules="rules">
      <a-textarea :value="fieldValue" :allowClear="allowClear" :placeholder="placeholder ? $t(placeholder) : ''"
                  :disabled="disabled" @change="$emit('update:fieldValue', $event.target.value)" />
    </a-form-item>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'TextArea',
  emits: ['update:fieldValue'],
  props: {
    label: VueTypes.string,
    disabled: VueTypes.bool.def(false),
    fieldValue: VueTypes.string.def(''),
    fieldKey: VueTypes.oneOfType([VueTypes.array, VueTypes.string]),
    placeholder: VueTypes.string.def(''),
    allowClear: VueTypes.bool.def(false),
    rules: VueTypes.array.def(undefined)
  }
});
</script>
