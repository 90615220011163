const state = () => ({
  rtl: false,
  topMenu: false,
  loading: false,
  darkMode: true,
  error: ''
});

const getters = {
  loading: (state) => state.loading
};

const mutations = {
  setRtl: (state, rtl) => state.rtl = rtl,
  setTopMenu: (state, topMenu) => state.topMenu = topMenu,
  setLoading: (state, loading) => state.loading = loading,
  setDarkMode: (state, darkMode) => state.darkMode = darkMode,
  setError: (state, error) => state.error = error,
  clearError: (state) => state.error = ''
};

const actions = {
  toggleLoading({commit}, isLoading) {
    commit('setLoading', isLoading);
  }
};

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions
};
