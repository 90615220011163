import _ from 'lodash';

/**
 * Scroll to first error in form
 * @param  {object} formRef ref of form
 * @param  {array} errorFields all error fields
 */
export const scrollToFirstError = async (formRef, errorFields) => {
  if (!errorFields) return;
  formRef.value.scrollToField(errorFields[0]?.name?.join('.'), {
    behavior: (actions) => {
      actions.forEach(({
        el, top, left
      }) => {
        el.scrollTop = top - 100;
        el.scrollLeft = left;
      });
    }
  });
};

/**
 * Scroll to first error in forms where the previous function isn't working
 * @param  {object} formRef ref of form
 * @param  {array} errorFields all error fields
 * @param {object} document DOM document
 * @param {object} window open window
 */
export const scrollToFirstErrorAlternative = async (formRef, errorFields, document, window) => {
  const field = _.find(formRef.value.fields, (field) => _.isEqual(field.fieldName, errorFields[0].name));
  if (!field?.fieldId) return;

  const top = document.getElementById(field.fieldId).getBoundingClientRect().top + window.pageYOffset - document.getElementsByClassName('ant-layout-header')[0].offsetHeight;

  window.scrollTo({top, behavior: 'smooth'});
};
