import {displayServerErrorsInToaster, displaySuccessToaster} from '@/helpers/toasters';
import {utils} from 'jsonapi-vuex';
import _ from 'lodash';
import {Meta} from '@/models/meta';

const state = {
  permissions: [],
  clientPermissions: [],
  agentPermissions: [],
  users: [],
  user: {}
};

const getters = {
  permissions: (state) => state.permissions,
  clientPermissions: (state) => state.clientPermissions,
  agentPermissions: (state) => state.agentPermissions,
  users: (state) => state.users,
  user: (state) => state.user
};

const actions = {
  async getUsers({commit}, params) {
    const response = await this.$http.get('/users', {params});
    if (response.status === 200) {
      commit('setUsers', response.data);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async getUser({commit}, {id, params}) {
    const response = await this.$http.get(`/users/${id}`, {params});
    if (response.status === 200) {
      commit('setUser', response.data);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async inviteUser({commit}, data) {
    const response = await this.$http.post('/auth/invitation', data);
    if (response.status === 200) {
      displaySuccessToaster(this.$t('messages.success.userInvited'));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async updateUser({state, commit}, {id, form}) {
    const response = await this.$http.put(`/users/${id}`, form);
    if (response.status === 200) {
      commit('updateUserInList', response.data);
      if (!_.isEmpty(state.user) && state.user._jv.id === id) commit('setUser', response.data);
      displaySuccessToaster(this.$t('messages.success.updated', {entity: this.$t('models.user.entity', 1)}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async deleteUsers({commit}, {id, index}) {
    const response = await this.$http.delete(`/users/${id}`);
    if (response.status === 200) {
      commit('setDeletedUser', index);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async getPermissions({rootState, commit}) {
    const response = await this.$http.get('/permissions', {params: {per_page: 100}});
    if (response.status === 200) {
      commit('setPermissions', {
        permissions: response.data, rootState, $t: this.$t
      });
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async getClientPermissions({commit}) {
    const response = await this.$http.get('/permissions', {params: {per_page: 100, user_type: 'client'}});
    if (response.status === 200) {
      commit('setClientPermissions', {permissions: response.data, $t: this.$t});
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async getAgentPermissions({commit}) {
    const response = await this.$http.get('/permissions', {params: {per_page: 100, user_type: 'agent'}});
    if (response.status === 200) {
      commit('setAgentPermissions', {permissions: response.data, $t: this.$t});
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  }
};

const mutations = {
  setPermissions: (state, {
    permissions, rootState, $t
  }) => state.permissions = normalize(permissions, rootState.session.userType, $t),
  setClientPermissions: (state, {
    permissions, $t
  }) => state.clientPermissions = normalize(permissions, 'ClientUser', $t),
  setAgentPermissions: (state, {
    permissions, $t
  }) => state.agentPermissions = normalize(permissions, 'AgentUser', $t),
  setUsers: (state, users) => {
    const normalizedUsers = _.map(users.data, (user) => {
      return {
        ...utils.jsonapiToNorm(user),
        permissionIds: _.map(user.attributes.permissionIds, (id) => id.toString())
      };
    });
    state.users = {data: normalizedUsers, ...new Meta(users.meta)};
  },
  setUser: (state, {data, included}) => {
    state.user = {
      ...utils.jsonapiToNorm(data),
      permissionIds: _.map(data.attributes.permissionIds, (id) => id.toString())
    };
    state.user._jv.relationships.company = utils.jsonapiToNorm(_.find(included, {type: 'company'}));
  },
  setDeletedUser: (state, index) => Object.assign(state.users.data[index], {deletedAt: new Date().toISOString()}),
  updateUserInList: (state, {data}) => {
    const index = _.findIndex(state.users.data, (user) => user._jv.id === data.id);
    if (index === -1) return;
    state.users.data[index] = {
      ...utils.jsonapiToNorm(data), permissionIds:
      _.map(data.attributes.permissionIds, (id) => id.toString())
    };
  }
};

const normalize = (permissions, userType, $t) => {
  return _.map(
    utils.jsonapiToNorm(permissions.data),
    (permission) => {
      return {
        id: permission._jv.id,
        ...permission,
        displayName: $t(`permissions.${userType}.${permission.key}`)
      };
    });
};

export default {
  state,
  getters,
  actions,
  mutations
};
