import app from '@/config/configApp';
import Alerts from '@/components/alerts/Alerts';
import Cards from '@/components/cards/frame/CardsFrame.vue';
import FeatherIcons from '@/components/utilities/featherIcons.vue';
import {Button, BtnGroup} from '@/components/buttons/Buttons.vue';
import {PageHeader} from '@/components/pageHeaders/PageHeaders.vue';
import Heading from '@/components/heading/Heading';
import Dropdown from '@/components/dropdown/Dropdown';
import Popover from '@/components/popup/Popup';

[
  Alerts,
  Heading,
  Cards,
  {
    name: 'Button',
    ...Button
  },
  {
    name: 'BtnGroup',
    ...BtnGroup
  },
  {name: 'PageHeader', ...PageHeader},
  Dropdown,
  Popover,
  FeatherIcons
].map((c) => {
  app.component(`sd${c.name}`, c);
});
