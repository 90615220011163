<template>
  <div :class="label ? '' : 'field-without-label'">
    <a-form-item :label="label ? $t(label) : ' '" :name="fieldKey" :rules="rules" :class='_class'>
      <a-input :size="size" :value="fieldValue" :placeholder="placeholder ? $t(placeholder) : ''"
               :maxLength="maxLength"
               :disabled="disabled" @change="$emit('update:fieldValue', $event.target.value)"
               @pressEnter="$emit('pressEnter', $event.target)">
        <template #prefix v-if="icon"><sdFeatherIcons :type="icon" size="14" /></template>
      </a-input>
    </a-form-item>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'TextField',
  emits: ['update:fieldValue', 'pressEnter'],
  props: {
    label: VueTypes.string,
    disabled: VueTypes.bool.def(false),
    fieldKey: VueTypes.oneOfType([VueTypes.array, VueTypes.string]),
    fieldValue: VueTypes.string.def(''),
    placeholder: VueTypes.string.def(''),
    icon: VueTypes.string.def(undefined),
    rules: VueTypes.array.def(undefined),
    size: VueTypes.string.def('default'),
    maxLength: VueTypes.integer.def(50),
    _class: VueTypes.string.def('')
  }
});
</script>
