export default {
  AgentUser: {
    add_price_list: 'Add Price List',
    edit_agent_profile: 'Edit Company Profile',
    view_agent_user_list: 'View Users List',
    manage_agent_users: 'Manage Company Users',
    manage_shipment_request: 'Manage Shipment Requests',
    review_agent_quote: 'Review Agent Quotes',
    fill_survey: 'Fill Surveys',
    messages: 'Send Messages',
    view_change_history: 'View Changes History',
    create_ticket: 'Create Tickets',
    manage_tickets: 'Manage Tickets'
  },
  ClientUser: {
    edit_client_profile: 'Edit Company Profile',
    manage_client_users: 'Manage Company Users',
    view_client_user_list: 'View Users List',
    view_change_history: 'View Changes History',
    view_instant_quote: 'View Instant Quotes',
    view_quote_history: 'View Quote History',
    view_quotes: 'View Shipment Quotes',
    quote_approval_rejection: 'Approve/Reject Quote',
    generate_invoice: 'Generate Invoice',
    payment_management: 'Manage Payments',
    manage_shipment_request: 'Manage Shipment Request',
    fill_survey: 'Fill Surveys',
    create_ticket: 'Create Tickets',
    manage_tickets: 'Manage Ticketss',
    reporting: 'Reporting',
    messages: 'Send Messages'
  },
  BackOfficeUser: {
    view_back_office_user: 'View Back Office Users',
    manage_back_office_users: 'Manage Back Office Users',
    master_data_administration: 'Master Data Administration',
    system_configuration_management: 'System Configuration Management',
    add_price_list_suggestions: 'Add Price List Suggestions',
    create_agent_quote_template: 'Create Agent Quote Template',
    financial_data_administration: 'Financial Data Administration',
    create_survey: 'Create Surveys',
    view_agent_user_list: 'View Agent Users List',
    view_client_user_list: 'View Client Users List',
    verify_agent_profile: 'Verify Agent Profiles',
    verify_client_profile: 'Verify Client Profiles',
    manage_profile_status: 'Manage Profile Statuses',
    view_vas_requests: 'View VAS Requests',
    create_vas_quote: 'Create VAS Quotes',
    cancel_client_shipment: 'Cancel Client Shipments',
    create_ticket: 'Create Tickets',
    manage_tickets: 'Manage Tickets',
    messages: 'Send Messages'
  }
};
