<template>
  <div :class="label ? '' : 'field-without-label'">
    <a-form-item :label="label ? $t(label) : ' '" :name="fieldKey"  :rules="rules">
      <a-input-number :type="!percentage? 'number' : 'text'"
                      :value="fieldValue" :placeholder="placeholder ? $t(placeholder) : ''"
                      :min="min" :max="max" :disabled="disabled" :size="size"
                      :formatter="value => percentage ? `%${value}` : value"
                      :parser="value => percentage ? value.replace('%', '') : value"
                      @change="onChange"
                      @keydown="onKeyDown">
      </a-input-number>
      <label v-if="bottomLabel" class="bottom-label">
        {{$t(bottomLabel)}}
      </label>
    </a-form-item>
  </div>
</template>

<script>
import {defineComponent, ref} from 'vue';
import VueTypes, {oneOfType} from 'vue-types';
import _ from 'lodash';
export default defineComponent({
  name: 'NumberField',
  emits: ['update:fieldValue', 'update:unitValue'],
  props: {
    bottomLabel: VueTypes.string,
    label: VueTypes.string,
    disabled: VueTypes.bool.def(false),
    fieldKey: VueTypes.oneOfType([VueTypes.array, VueTypes.string]),
    allowDecimal: VueTypes.bool.def(true),
    percentage: VueTypes.bool.def(false),
    fieldValue: oneOfType([VueTypes.string.def('0'), VueTypes.number.def(0)]),
    placeholder: VueTypes.string.def(''),
    icon: VueTypes.string.def(undefined),
    min: VueTypes.number.def(),
    max: VueTypes.number.def(),
    rules: VueTypes.array.def(undefined),
    size: VueTypes.string.def(undefined),
    unitOptions: VueTypes.object.def(undefined)
  },
  setup(props, context) {
    const onChange = (value) => {
      context.emit('update:fieldValue', value);
    };
    const forbiddenKeys = ref([107, 109, 187, 189, 69]);
    const arrowsTabDeleteKeys = ref([8, 9, 46, 37, 38, 39, 40]);
    const onKeyDown = (e) => {
      const keyCode = e.keyCode;
      if (props.percentage && !((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) ||
      ((keyCode === 190 || keyCode === 110) && !props.fieldValue.toString().includes('.')) || _.includes(arrowsTabDeleteKeys.value, keyCode))) {
        e.preventDefault();
        return false;
      }
      if ((keyCode === 190 || keyCode === 110) && !props.allowDecimal) {
        e.preventDefault();
        return false;
      }
      // prevent + - e
      if (_.includes(forbiddenKeys.value, keyCode)) {
        e.preventDefault();
        return false;
      }
    };

    return {
      onChange,
      onKeyDown
    };
  }
});
</script>

<style scoped>
.ant-input-number {
  width: 100% !important
}
.bottom-label {
  font-weight: 500;
  top: 5px;
}
</style>
