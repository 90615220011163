import {displayServerErrorsInToaster, displaySuccessToaster} from '@/helpers/toasters';
import {utils} from 'jsonapi-vuex';
import router from '@/routes';
import _ from 'lodash';
import {Meta} from '@/models/meta';
import moment from 'moment';

const state = {
  shipments: [],
  shipment: {},
  shipmentItems: [],
  preferredAgents: []
};

const getters = {
  shipment: (state) => state.shipment,
  shipments: (state) => state.shipments,
  shipmentItems: (state) => state.shipmentItems,
  preferredAgents: (state) => state.preferredAgents,
  shipmentColumns: () => window.localStorage.getItem('shipmentColumns')
};

const actions = {
  async createShipment({commit}, {shipmentRequestId, formData}) {
    const response = await this.$http.post(`/shipment_requests/${shipmentRequestId}/shipments`, formData);
    if (response.status === 200) {
      commit('setShipment', {shipment: response.data});
      displaySuccessToaster(this.$t('messages.success.created', {entity: this.$t('models.shipment.entity', 1)}));
    } else {
      if (response.status === 404) {
        router.push({name: 'ClientShipmentRequests'});
      }
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async updateShipment({commit}, data) {
    const response = await this.$http.put(
      `/shipment_requests/${data.shipmentRequestId}/shipments/${data.shipmentId}`,
      data.formData
    );
    if (response.status === 200) {
      commit('setShipment', {shipment: response.data});
      displaySuccessToaster(this.$t('messages.success.updated', {entity: this.$t('models.shipment.entity', 1)}));
    } else if (!`${response.status}`.startsWith(2)) {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async cloneShipment({commit}, {shipmentRequestId, shipmentId}) {
    const response = await this.$http.get(`/shipment_requests/${shipmentRequestId}/shipments/${shipmentId}/clone`);
    if (response.status === 200) {
      commit('setShipment', {shipment: response.data, isClone: true});
    } else {
      router.push({name: 'ClientShipmentRequestDetails', params: {shipmentRequestId}});
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async getShipment({commit, rootState}, {
    shipmentRequestId, shipmentId, params
  }) {
    const response = await this.$http.get(`/shipment_requests/${shipmentRequestId}/shipments/${shipmentId}`, {params});
    if (response.status === 200) {
      commit('setShipment', {shipment: response.data});
    } else {
      const target =
        rootState.session.userType === 'ClientUser' ? 'ClientShipmentRequestDetails' : 'AgentShipmentRequestDetails';
      router.push({name: target, params: {shipmentRequestId}});
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async addDocumentsToShipment({rootState, commit}, data) {
    const {
      shipmentRequestId, shipmentId, documents, trigger
    } = data;
    const response = await this.$http.put(
      `/shipment_requests/${shipmentRequestId}/shipments/${shipmentId}/add_documents`,
      documents
    );
    if (response.status === 200) {
      commit('setShipment', {shipment: response.data});
      if (trigger === 'shipmentQuote') {
        commit('updateShipmentInQuote', rootState);
      }
      displaySuccessToaster(
        this.$t('messages.success.uploaded', {entity: this.$t('models.shipment.attributes.documents')})
      );
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async deleteShipmentDocuments({rootState, commit}, data) {
    const {
      shipmentRequestId, shipmentId, documents, trigger
    } = data;
    const response = await this.$http.put(
      `/shipment_requests/${shipmentRequestId}/shipments/${shipmentId}/delete_documents`,
      {ids: documents}
    );
    if (response.status === 200) {
      commit('setShipment', {shipment: response.data});
      if (trigger === 'shipmentQuote') {
        commit('updateShipmentInQuote', rootState);
      }
      displaySuccessToaster(
        this.$t('messages.success.deleted', {entity: this.$t('models.shipment.attributes.document')})
      );
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async backOfficeCompleteShipment({getters, commit}, shipmentId) {
    const response = await this.$http.put(`/shipments/${shipmentId}/complete`);
    if (response.status === 200) {
      displaySuccessToaster(this.$t('messages.success.completed', {entity: this.$t('models.shipment.entity', 1)}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async moveShipmentToWaitingForCompletionApproval({getters, commit}, params) {
    const response = await this.$http.put(`shipment_requests/${params.shipmentRequest}/shipments/${params.shipment}/wait_for_completion_approval`);
    if (response.status === 200) {
      displaySuccessToaster(this.$t('messages.success.updated', {entity: this.$t('models.shipment.entity', 1)}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async reopenShipment({getters, commit}, id) {
    const response = await this.$http.put(`/shipments/${id}/reopen`);
    if (response.status === 200) {
      displaySuccessToaster(this.$t('messages.success.reopened', {entity: this.$t('models.shipment.entity', 1)}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async cancelShipment({
    getters, commit, rootState
  }, shipment) {
    const id = shipment._jv.id;
    const shipmentRequestId = shipment.shipmentRequestId;
    const response = await this.$http.put(`/shipment_requests/${shipmentRequestId}/shipments/${id}/cancel`);
    if (response.status === 200) {
      const index = getters.shipments.data.indexOf(shipment);
      const status = response.data.data.attributes.status;
      commit('updateCancelShipment', {
        index, rootState, status
      });
      if (status === 'cancelled') {
        displaySuccessToaster(this.$t('messages.success.cancelled', {entity: this.$t('models.shipment.entity', 1)}));
      } else {
        displaySuccessToaster(this.$t('messages.success.cancellationRequest', {entity: this.$t('models.shipment.entity', 1)}));
      }
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async cancelAffectedShipment({getters, commit}, {shipment, isApproval}) {
    const id = shipment._jv.id;
    const response = await this.$http.put(`/shipments/${id}/cancel`, {is_approval: isApproval});
    if (response.status === 200) {
      const index = getters.shipments.data.indexOf(shipment);
      commit('removeShipment', index);
      displaySuccessToaster(this.$t('messages.success.cancelled', {entity: this.$t('models.shipment.entity', 1)}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async rejectShipmentCancellation({getters, commit}, shipment) {
    const id = shipment._jv.id;
    const response = await this.$http.put(`/shipments/${id}/reject_cancellation`);
    if (response.status === 200) {
      const index = getters.shipments.data.indexOf(shipment);
      commit('removeShipment', index);
      displaySuccessToaster(this.$t('messages.success.cancellationRejected', {entity: this.$t('models.shipment.entity', 1)}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async rejectShipment({getters, commit}, {shipment, reason}) {
    const response = await this.$http.put(
      `/shipment_requests/${shipment.shipmentRequestId}/shipments/${shipment._jv.id}/reject`,
      {
        reason: reason
      }
    );
    if (response.status !== 200) {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async republishShipment({getters, commit}, {shipment}) {
    const response = await this.$http.put(`/shipment_requests/${shipment.shipmentRequestId}/shipments/${shipment._jv.id}/publish_to_other_agents`);
    if (response.status !== 200) {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async approveShipmentRejection({getters, commit}, {shipment, reason}) {
    const response = await this.$http.put(
      `/shipment_requests/${shipment.shipmentRequestId}/shipments/${shipment._jv.id}/approve_rejection`,
      {
        reason: reason
      }
    );
    if (response.status !== 200) {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async cancelShipmentRejection({getters, commit}, {shipment, reason}) {
    const response = await this.$http.put(
      `/shipment_requests/${shipment.shipmentRequestId}/shipments/${shipment._jv.id}/cancel_rejection`,
      {
        reason: reason
      }
    );
    if (response.status !== 200) {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async getShipments({commit}, {shipmentRequestId, params}) {
    const response = await this.$http.get(`/shipment_requests/${shipmentRequestId}/shipments`, {params});
    if (response.status === 200) {
      commit('setShipments', response.data);
    } else {
      commit('setShipments', []);
    }
  },
  async getAllShipments({commit}, {params}) {
    const response = await this.$http.get('/shipments', {params});
    if (response.status === 200) {
      commit('setShipments', response.data);
    } else {
      commit('setShipments', []);
    }
  },
  async getPreferredAgents({commit}, params) {
    const response = await this.$http.get('/preferred_agents', {params});
    if (response.status === 200) {
      commit('setPreferredAgents', response.data);
    } else {
      commit('setPreferredAgents', []);
    }
  },
  async getAffectedShipments({commit}, {params}) {
    const response = await this.$http.get('/shipments', {params});
    if (response.status === 200) {
      commit('setShipments', response.data);
    } else {
      commit('setShipments', []);
    }
  },
  async getAffectedShipment({commit}, {shipmentId}) {
    const response = await this.$http.get(`/shipments/${shipmentId}`);
    if (response.status === 200) {
      commit('setShipment', {shipment: response.data});
    } else {
      commit('setShipment', {shipment: []});
    }
  },
  async updateTrackingStatus({commit}, data) {
    const response = await this.$http.put(
      `/shipment_requests/${data.shipmentRequestId}/shipments/${data.shipmentId}/update_tracking_status`,
      data.form
    );
    if (response.status === 200) {
      displaySuccessToaster(
        this.$t('messages.success.statusUpdated', {entity: this.$t('models.shipment.entity', 1)})
      );
      commit('setShipment', {shipment: response.data});
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async getShipmentItems({commit}, data) {
    const {
      shipmentId, shipmentRequestId, params
    } = data;
    const response = await this.$http.get(`/shipment_requests/${shipmentRequestId}/shipments/${shipmentId}/items`, {
      params
    });
    if (response.status === 200) {
      const data = _.values(utils.jsonapiToNorm(response.data.data));
      const meta = new Meta(response.data.meta);
      commit('setShipmentItems', {data, ...meta});
    } else {
      router.push({name: 'AgentShipments'});
      displayServerErrorsInToaster(response.errors);
    }
  },
  async getAffectedShipmentItems({commit}, data) {
    const {
      shipmentId, params
    } = data;
    const response = await this.$http.get(`/shipments/${shipmentId}/items`, {params});
    if (response.status === 200) {
      const data = _.values(utils.jsonapiToNorm(response.data.data));
      const meta = new Meta(response.data.meta);
      commit('setShipmentItems', {data, ...meta});
    } else {
      commit('setShipmentItems', {});
    }
  }
};

const mutations = {
  setShipments: (state, shipments) => {
    const normalisedShipments = _.map(shipments.data, (shipment) => {
      const normalisedShipment = utils.jsonapiToNorm(shipment);
      const instantQuote = normalisedShipment._jv.relationships.instantQuote;
      if (instantQuote && instantQuote.data) {
        normalisedShipment._jv.relationships.instantQuote = utils.jsonapiToNorm(
          _.find(shipments.included, {
            id: instantQuote.data.id,
            type: 'shipmentQuote'
          })
        );
      }
      if (normalisedShipment.agentMainQuote) {
        const agentMainQuote = normalisedShipment.agentMainQuote;
        if (agentMainQuote && agentMainQuote.data) {
          normalisedShipment.agentMainQuote = utils.jsonapiToNorm(agentMainQuote.data);
        }
      }
      return normalisedShipment;
    });
    const meta = new Meta(shipments.meta);
    state.shipments = {data: normalisedShipments, ...meta};
  },
  setShipment: (state, {shipment, isClone}) => {
    const normalisedShipment = utils.jsonapiToNorm(shipment.data);
    normalisedShipment.quoteDeadline = normalisedShipment.quoteDeadline ?
      moment(normalisedShipment.quoteDeadline) :
      undefined;
    if (normalisedShipment._jv.relationships.instantQuote) {
      const instantQuote = normalisedShipment._jv.relationships.instantQuote;
      if (instantQuote && instantQuote.data) {
        normalisedShipment._jv.relationships.instantQuote = utils.jsonapiToNorm(
          _.find(shipment.included, {id: instantQuote.data.id, type: 'shipmentQuote'})
        );
      }
    }
    const shipmentRequest = normalisedShipment._jv.relationships.shipmentRequest;
    normalisedShipment._jv.relationships.shipmentRequest = utils.jsonapiToNorm(
      _.find(shipment.included, {id: shipmentRequest?.data.id, type: 'shipmentRequest'})
    );
    if (normalisedShipment.agentMainQuote) {
      const agentMainQuote = normalisedShipment.agentMainQuote;
      if (agentMainQuote && agentMainQuote.data) {
        normalisedShipment.agentMainQuote = utils.jsonapiToNorm(agentMainQuote.data);
      }
    }
    let items = [];
    if (isClone) {
      items = _.map(shipment.items, (item) => _.mapKeys(item, (value, key) => _.camelCase(key)));
    } else if (normalisedShipment._jv?.relationships?.items) {
      items = _.map(normalisedShipment._jv?.relationships?.items?.data, (item) => {
        return utils.jsonapiToNorm(_.find(shipment.included, {id: item.id, type: 'shipmentItem'}));
      });
    }
    normalisedShipment._jv.relationships.items = items;
    state.shipment = normalisedShipment;
  },
  setShipmentItems: (state, items) => state.shipmentItems = items,
  setPreferredAgents: (state, agents) => state.preferredAgents = agents,
  removeShipment: (state, index) => {
    state.shipments.data.splice(index, 1);
    state.shipments.totalEntries -= 1;
  },
  updateCancelShipment: (state, {
    index, rootState, status
  }) => {
    state.shipments.data[index].status = status;
    if (status !== 'cancelled' || +rootState.shipmentRequests.shipmentRequest._jv.id !== state.shipments.data[index].shipmentRequestId) return;

    rootState.shipmentRequests.shipmentRequest.cancelledShipmentsNumber += 1;
    _.remove(rootState.shipmentRequests.shipmentRequest.destinations, (dest) => {
      return dest === state.shipments.data[index].destinationCountry;
    });
    if (rootState.shipmentRequests.shipmentRequest.destinations.length > 0) return;

    rootState.shipmentRequests.shipmentRequest.cancelledAt = Date.now();
  },
  updateTotalValue: (state, {removedValue, rootState}) => {
    rootState.shipmentRequests.shipmentRequest.totalValue -= removedValue;
  },
  updateShipmentInQuote: (state, rootState) => {
    rootState.shipmentQuotes.shipmentQuote._jv.relationships.shipment = state.shipment;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
