import {displayServerErrorsInToaster, displaySuccessToaster} from '@/helpers/toasters';
import _ from 'lodash';
import {utils} from 'jsonapi-vuex';

const state = {
  suggestedPrices: []
};

const getters = {
  suggestedPrices: (state) => state.suggestedPrices
};

const actions = {
  async getSuggestedPrices({commit}, data) {
    const response = await this.$http.get('/suggested_prices', {params: data});
    if (response.status === 200) {
      commit('setSuggestedPrices', response.data);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async updateSuggestedPrices({commit}, data) {
    const response = await this.$http.put('/suggested_prices/update_prices', data);
    if (response.status === 200) {
      commit('setSuggestedPrices', response.data);
      displaySuccessToaster(this.$t('messages.success.updated',
        {entity: this.$t('models.suggestedPrice.entity', 1)}));
    } else {
      return response.errors;
    }
  }
};

const mutations = {
  setSuggestedPrices: (state, {data}) => state.suggestedPrices = _.values(utils.jsonapiToNorm(data))
};

export default {
  state,
  getters,
  actions,
  mutations
};
