export default [
  {
    name: 'Agent',
    path: '/agent',
    redirect: {name: 'AgentShipments'},
    component: () => import('@/layout/appLayout.vue'),
    children: [
      {
        name: 'AgentShipments',
        path: 'shipments',
        component: () => import('@/views/agent/shipments/list.vue')
      },
      {
        name: 'AgentEdit',
        path: 'edit',
        component: () => import('@/views/agent/EditProfile.vue'),
        meta: {
          abilitySubject: 'company',
          abilityAction: 'edit'
        }
      },
      {
        name: 'AgentShipmentRequestDetails',
        path: 'shipment-requests/:shipmentRequestId',
        component: () => import('@/views/common/shipmentRequests/details.vue')
      },
      {
        name: 'AgentShipmentDetails',
        path: 'shipment-requests/:shipmentRequestId/shipments/:shipmentId',
        component: () => import('@/views/common/shipmentRequests/shipmentDetails.vue')
      },
      {
        name: 'AgentShipmentQuotes',
        path: 'shipment-requests/:shipmentRequestId/shipments/:shipmentId/quotes',
        component: () => import('@/views/common/shipmentQuotes/list.vue'),
        meta: {
          abilityAction: 'read',
          abilitySubject: 'shipmentQuote'
        }
      },
      {
        name: 'AgentShipmentQuote',
        path: 'shipment-requests/:shipmentRequestId/shipments/:shipmentId/quote/:quoteId',
        component: () => import('@/views/common/shipmentQuotes/quote.vue')
      },
      {
        name: 'AgentShipmentRequestHistory',
        path: 'shipment-requests/:shipmentRequestId/history',
        component: () => import('@/views/client/shipmentRequests/history.vue'),
        meta: {
          abilityAction: 'read',
          abilitySubject: 'shipmentRequestHistory'
        }
      },
      {
        name: 'AgentQuoteForm',
        path: 'shipment-requests/:shipmentRequestId/shipments/:shipmentId/submit-quote',
        component: () => import('@/views/agent/shipmentQuotes/form.vue'),
        meta: {
          abilityAction: 'update',
          abilitySubject: 'shipmentQuote',
          companyGuard: true
        }
      },
      {
        name: 'AgentEditQuoteForm',
        path: 'shipment-requests/:shipmentRequestId/shipments/:shipmentId/quotes/:id/edit',
        component: () => import('@/views/agent/shipmentQuotes/form.vue'),
        meta: {
          abilityAction: 'update',
          abilitySubject: 'shipmentQuote'
        }
      },
      {
        name: 'AgentAdditionalQuoteForm',
        path: 'shipment-requests/:shipmentRequestId/shipments/:shipmentId/additional-quote',
        component: () => import('@/views/agent/shipmentQuotes/form.vue'),
        meta: {
          abilityAction: 'update',
          abilitySubject: 'shipmentQuote'
        }
      },
      {
        name: 'AgentQuotes',
        path: 'shipment-quotes',
        component: () => import('@/views/common/shipmentQuotes/list.vue')
      },
      {
        name: 'AgentQuote',
        path: 'shipment-quotes/:quoteId',
        component: () => import('@/views/common/shipmentQuotes/quote.vue')
      },
      {
        name: 'AgentQuoteTemplate',
        path: 'shipment-quotes/template',
        component: () => import('@/views/agent/shipmentQuotes/templateForm.vue'),
        meta: {
          abilitySubject: 'shipmentQuoteTemplate',
          abilityAction: 'read',
          companyGuard: true
        }
      },
      {
        name: 'AgentUsers',
        path: 'users',
        component: () => import('@/views/common/users/index'),
        meta: {
          abilitySubject: 'user',
          abilityAction: 'read',
          companyGuard: true
        }
      },
      {
        name: 'AgentInviteUser',
        path: 'users/invite',
        component: () => import('@/views/common/users/invite'),
        meta: {
          abilitySubject: 'user',
          abilityAction: 'invite',
          companyGuard: true
        }
      },
      {
        name: 'AgentUserProfile',
        path: 'users/:userId',
        component: () => import('@/views/common/users/user/index.vue'),
        redirect: {name: 'AgentUserBasicInfo'},
        children: [
          {
            path: 'profile',
            name: 'AgentUserBasicInfo',
            component: () => import('@/views/common/users/user/actions/profile.vue'),
            meta: {
              abilitySubject: 'user',
              abilityAction: 'read',
              requireCurrentUser: true
            }
          },
          {
            path: 'password',
            name: 'AgentChangePassword',
            component: () => import('@/views/common/users/user/actions/password.vue'),
            meta: {
              requireCurrentUser: true,
              companyGuard: true
            }
          },
          {
            path: 'permissions',
            name: 'AgentAccountPermissions',
            component: () => import('@/views/common/users/user/actions/permissions.vue'),
            meta: {
              abilitySubject: 'user',
              abilityAction: 'read',
              requireCurrentUser: true,
              companyGuard: true
            }
          },
          {
            path: 'history',
            name: 'AgentAccountHistory',
            component: () => import('@/views/common/users/user/actions/history.vue'),
            meta: {
              abilitySubject: 'allHistory',
              abilityAction: 'read',
              requireCurrentUser: true,
              companyGuard: true
            }
          }
        ]
      },
      {
        name: 'AgentCompanyProfile',
        path: 'company',
        component: () => import('@/views/common/companies/company'),
        meta: {
          abilitySubject: 'company',
          abilityAction: 'read'
        }
      },
      {
        name: 'AgentTickets',
        path: 'tickets',
        component: () => import('@/views/common/tickets/list.vue')
      },
      {
        name: 'AgentTicketDetails',
        path: 'tickets/:ticketId/details',
        component: () => import('@/views/common/tickets/details.vue')
      }
    ],
    meta: {
      auth: true, agent: true, companyApproved: true
    }
  }
];
