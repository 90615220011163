export default {
  submit: 'Submit',
  save: 'Save',
  close: 'Close',
  signIn: 'Sign In',
  signInAs: 'Sign in as',
  resetPassword: 'Reset Password',
  cancel: 'Cancel',
  browse: 'Browse',
  clickHere: 'Click here',
  returnTo: 'Return to',
  edit: 'Edit',
  clone: 'Clone',
  view: 'View',
  viewChatHistory: 'View Chat History',
  publish: 'Publish',
  approve: 'Approve',
  reject: 'Reject',
  return: 'Return',
  delete: 'Delete',
  modify: 'Modify',
  upload: 'Upload',
  add: 'Add',
  addItem: 'Add Item',
  remove: 'Remove',
  requestModifications: 'Request Modifications',
  requestDocuments: 'Request Documents',
  requestRevalidation: 'Request Revalidation',
  download: 'Download',
  priceListDownload: 'Download price list',
  priceListPreview: 'Preview price list',
  preview: 'Preview',
  search: 'Search',
  confirm: 'Confirm',
  yes: 'Yes',
  no: 'No',
  generateQuote: 'Generate Quote',
  uploadQuote: 'Upload Quote',
  reset: 'Reset',
  ok: 'OK',
  saveAndNext: 'Save & Next',
  next: 'Next',
  previous: 'Previous',
  done: 'Done',
  revalidate: 'Mark as revalidated',
  cancelShipment: 'Cancel Shipment',
  completeShipment: 'Approve Completion',
  reopenShipment: 'Reopen Shipment',
  approveCancellation: 'Approve Cancellation',
  rejectCancellation: 'Reject Cancellation',
  cancelShipmentRequest: 'Cancel Shipment Request',
  contact: {
    client: 'Contact Client',
    agent: 'Contact Agent'
  },
  selectAll: 'Select All',
  select: 'Select',
  saveDraftAndNext: 'Save Draft & Next',
  saveDraft: 'Save Draft',
  continueRegistration: 'Continue Registration',
  blacklist: 'Blacklist',
  verify: 'Verify',
  finish: 'Finish',
  action: 'Action',
  uploadBusinessRegistrationCertificate: 'Upload Business Registration Certificate',
  uploadTradeLicence: 'Upload Trade Licence',
  uploadTicketAttachments: 'Upload Ticket Attachments',
  addComment: 'Add Comment',
  reapply: 'Reapply',
  productIsAlreadyLinkedToAShipment: 'Product is already linked to a shipment',
  publishDisabled: 'You are not allowed to publish shipment request until your company is approved',
  markAsTemplate: 'Mark as a template',
  waitForCompletionApproval: 'Wait for completion approval'
};
