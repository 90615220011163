import {createWebHistory, createRouter} from 'vue-router';
import agent from './agent';
import client from './client';
import backOffice from './backOffice';
import errors from './errors';
import session from './session';
import registration from './registration';
import store from '@/store';

const routes = [
  {
    name: 'Home',
    path: '/',
    meta: {auth: true}
  },
  ...session,
  ...registration,
  ...agent,
  ...client,
  ...backOffice,
  ...errors
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name === 'theNetworkError' || to.name === 'Forbidden' || to.name === 'NotFound') {
    next();
  } else if (to.name === 'Login' || to.name === 'Home') {
    store.state.session.isLoggedIn && !companyDraft() ? toHome(next) : toLogin(next);
  } else if (to.meta.auth && !store.state.session.isLoggedIn) {
    toLogin(next);
  } else if ((to.name === 'ClientLogin' || to.name === 'AgentLogin' || to.name === 'BackOfficeLogin') && store.state.session.isLoggedIn && companyApproved()) {
    toHome(next);
  } else if (to.meta.client && store.state.session.userType !== 'ClientUser') {
    next({name: 'ClientLogin'});
  } else if (to.meta.agent && store.state.session.userType !== 'AgentUser') {
    next({name: 'AgentLogin'});
  } else if (to.meta.backOffice && store.state.session.userType !== 'BackOfficeUser') {
    next({name: 'BackOfficeLogin'});
  } else if (to.meta.companyApproved && !companyApproved() && to.meta.agent && to.meta.companyGuard) {
    next({name: 'AgentCompanyProfile'});
  } else if (to.meta.companyNotRejected && companyRejected() && to.meta.client && !to.meta.skipCompanyGuard) {
    next({name: 'ClientCompanyProfile'});
  } else {
    if (to.meta.requireCurrentUser && (to.params.userId === 'me' || to.params.userId === store.state.session.currentUser._jv.id)) {
      next();
    } else if (to.meta?.abilitySubject === 'company' && store.$ability.can(to.meta.abilityAction || 'read', company() || 'company')) {
      next();
    } else if (to.meta.abilitySubject && to.meta.abilitySubject !== 'company' && store.$ability.can(to.meta.abilityAction || 'read', to.meta.abilitySubject)) {
      next();
    } else if (!to.meta.requireCurrentUser && !to.meta.abilitySubject) {
      next();
    } else {
      next({name: 'NotFound'});
    }
  }
});

const toLogin = (next) => {
  switch (store.state.session.userType) {
  case 'AgentUser':
    next({name: 'AgentLogin'});
    break;
  case 'ClientUser':
    next({name: 'ClientLogin'});
    break;
  default:
    next({name: 'BackOfficeLogin'});
    break;
  }
};
const toHome = (next) => {
  switch (store.state.session.userType) {
  case 'AgentUser':
    next({name: 'Agent'});
    break;
  case 'ClientUser':
    next({name: 'Client'});
    break;
  default:
    next({name: 'BackOffice'});
    break;
  }
};

const company = () => store.state.session.currentUser?._jv?.relationships?.company;
const companyRejected = () => company()?.status === 'rejected';
const companyDraft = () => company()?.status === 'draft';
const companyApproved = () => {
  return store.state.session.userType === 'BackOfficeUser' || company()?.status === 'approved';
};

export default router;
