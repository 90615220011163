export default {
  home: 'Home',
  login: {
    forgotPassword: 'Forgot password?',
    keepMeLoggedIn: 'Keep me logged in',
    wantToSignInAs: 'Want to sign in as'
  },
  forgotPassword: {
    title: 'Forgot Password?',
    message: 'Enter the email address you used when you joined and we’ll send you instructions to reset your password.',
    sendResetInstructions: 'Send Reset Instructions'
  },
  shipmentRequest: {
    view: 'View',
    details: 'Shipment request details',
    title: 'Shipment Request',
    new: 'New Shipment Request',
    index: 'Shipment Requests',
    requests: 'Requests',
    shipments: 'Shipments',
    history: 'History'
  },
  shipmentQuote: {
    index: 'Shipment Quotes',
    title: 'Shipment Quote',
    total: 'Total',
    template: 'Shipment Quote Template'
  },
  ShipmentRejectionPopover: {
    title: 'Reject shipment',
    message: 'Are you sure you want to reject this shipment? If so, please state a reason.'
  },
  ApproveShipmentRejectionPopover: {
    title: 'Approve shipment rejection',
    message: 'Are you sure you want to approve this shipment rejection?'
  },
  ticket: {
    new: 'New Ticket',
    index: 'Tickets',
    title: 'Tickets'
  },
  report: {
    title: 'Report',
    index: 'Reports',
    new: 'Generate report',
    exportShipments: 'Export Shipments'
  },
  dynamicLists: {
    title: 'Dynamic Lists'
  },
  systemSettings: {
    title: 'System Settings',
    systemConfigurations: 'System Configurations',
    dynamicLists: 'Dynamic Lists',
    sanctionedCountries: 'Sanctioned Countries'
  },
  users: {
    title: 'Users',
    invite: {
      title: 'Invite User',
      subTitle: 'Invite',
      details: 'User Details',
      permissions: 'Permissions',
      review: 'Review'
    }
  },
  companies: {
    title: 'Company',
    profile: 'Profile',
    index: 'Companies',
    users: 'Company Users',
    agent: 'Agent',
    client: 'Client'
  },
  bankAccountInformation: 'Bank Account Info',
  priceLists: {
    title: 'Price List',
    categories: 'Price Categories',
    suggested: 'Suggested Prices',
    country: 'Country Pricelist'
  },
  info: {
    profile: 'Profile',
    company: 'Company',
    signout: 'Sign Out'
  }
};
