<template>
  <div>
    <a-form-item :label="$t(label)" :name="fieldKey" >
      <a-time-picker :value="fieldValue" :allowClear="allowClear"
                     :placeholder="placeholder ? $t(placeholder) : ''" :disabled="disabled"
                     :inputReadOnly="true" format="h:mm a" use12-hours
                     @change="onChange" />
    </a-form-item>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'TimePicker',
  emits: ['update:fieldValue'],
  props: {
    /**
   * fieldValue Moment object in correct time format (h:mm a)
   */
    fieldValue: VueTypes.object.def(undefined),
    label: VueTypes.string,
    disabled: VueTypes.bool.def(false),
    fieldKey: VueTypes.oneOfType([VueTypes.array, VueTypes.string]),
    placeholder: VueTypes.string.def(''),
    allowClear: VueTypes.bool.def(true)
  },
  setup(_, context) {
    const onChange = (time, timeString) => {
      context.emit('update:fieldValue', time);
    };

    return {
      onChange
    };
  }
});
</script>

<style scoped>
span.ant-time-picker {
    width: 300px !important;
}
</style>

