import * as EN from './en';

export default {
  'en-US': {
    models: EN.Models,
    views: EN.Views,
    actions: EN.Actions,
    messages: EN.Messages,
    layout: EN.Layout,
    permissions: EN.Permissions
  }
};
