import i18n from '@/core/plugins/i18n';
import store from '@/store';

export const required = {
  required: true,
  message: i18n.global.t('messages.error.required'),
  trigger: 'blur',
  whitespace: true
};

export const email = {
  type: 'email',
  message: i18n.global.t('messages.error.email'),
  trigger: 'blur'
};

export const requiredNumber = {
  ...required,
  type: 'number'
};

export const integer = {
  type: 'integer',
  trigger: 'blur',
  message: i18n.global.t('messages.error.noDecimals')
};

export const rangeNumerical = (min, max) => {
  return {
    type: 'integer',
    asyncValidator: (rule, value) => {
      return new Promise((resolve, reject) => {
        if (!value) {
          reject(i18n.global.t('messages.error.required'));
        } else if (!/^\d+$/.test(value.toString()) || (value?.toString().length > max || value?.toString().length < min) ) {
          reject(i18n.global.t('messages.error.rangeNumerical', {min, max}));
        } else {
          resolve();
        }
      });
    },
    trigger: 'blur'
  };
};
export const rangeText = (max) => {
  return {
    type: 'integer',
    asyncValidator: (rule, value) => {
      return new Promise((resolve, reject) => {
        if (!value) {
          reject(i18n.global.t('messages.error.required'));
        } else if ( (value?.toString().length > max) ) {
          reject(i18n.global.t('messages.error.rangeText', {max}));
        } else {
          resolve();
        }
      });
    },
    trigger: 'blur'
  };
};

export const phoneNumber = (min, max) => {
  return {
    type: 'string',
    asyncValidator: (rule, value) => {
      return new Promise((resolve, reject) => {
        if (!value) {
          reject(i18n.global.t('messages.error.required'));
        } else if (!/[0-9]/g.test(value.toString()) || (value?.toString().length > max || value?.toString().length < min) ) {
          reject(i18n.global.t('messages.error.phoneNumberRange', {min, max}));
        } else {
          resolve();
        }
      });
    },
    trigger: 'blur'
  };
};

export const nonZero = {
  type: 'number',
  asyncValidator: (rule, value) => {
    return new Promise((resolve, reject) => {
      if (value <= 0) {
        reject(i18n.global.t('messages.error.nonZero'));
      } else {
        resolve();
      }
    });
  },
  trigger: 'blur'
};

export const minRange = (min) => {
  return {
    min: min,
    message: i18n.global.t('messages.error.minRange', {min}),
    type: 'number',
    trigger: 'blur'
  };
};

export const maxRange = (max) => {
  return {
    max: max,
    message: i18n.global.t('messages.error.maxRange', {max}),
    type: 'number',
    trigger: 'blur'
  };
};

export const nonNegative = {
  min: 0,
  message: i18n.global.t('messages.error.nonNegative'),
  type: 'number',
  trigger: 'blur'
};

export const availableEmail = {
  message: i18n.global.t('messages.error.emailTaken'),
  asyncValidator: (rule, value) => {
    return new Promise((resolve, reject) => {
      store.dispatch('isEmailAvailable', value).then((value) => {
        // eslint-disable-next-line prefer-promise-reject-errors
        return value ? resolve() : reject('');
      });
    });
  },
  trigger: 'blur'
};
