/**
 * A class that can return the meta model
 */
export class Meta {
  /** @param {Object} meta object */
  constructor(meta = {}) {
    this.page = meta.page || 0;
    this.perPage = meta.per_page || 10;
    this.totalEntries = meta.total_entries || 0;
    this.totalPages = meta.total_pages || 0;
  }
}
