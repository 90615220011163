<template>
    <div class="hvr-bob hvr-glow ticket-icon-container"
    >
        <modal-form
            :title="$t('layout.ticket.new')"
            :fields="fields"
            :rules="rules"
            :loading="submitLoading"
            :visible="modalVisible"
            @close-modal="closeModal"
            @submit-form="submitForm"
            :cancelButton="cancelButton"
            :resetForm="resetForm"
            @inputChanged="inputChanged($event, field)"
        />

        <a-tooltip :title="$t('views.ticket.title')">
            <sd-feather-icons class="ticket-icon"
                size="28"
                type="help-circle"
                :stroke="theme['white-color']"
                :style="{'background-color':theme['primary-color']}"
                @click.prevent="openModal()"/>
        </a-tooltip>
    </div>
</template>

<script>
import {theme} from '@/config/theme/themeVariables';
import ModalForm from '@/components/modals/ModalForm';
import {
  ref, computed,
  reactive
} from 'vue';
import {useRoute} from 'vue-router';
import {
  required, requiredNumber
} from '@/helpers/validationRules';
import {FieldStyles} from '@/components/shared/fields/styles';
import {useStore} from 'vuex';
import _ from 'lodash';

export default {
  name: 'Launcher',
  components: {
    ModalForm
  },
  setup() {
    const {dispatch, getters} = useStore();
    const route = useRoute();
    const chatExist = computed(() => route.name === 'ClientShipmentQuote');

    const getDynamicLists = async () => {
      dispatch('toggleLoading', true);
      await dispatch('getList', 'TICKET_CATEGORIES');
      dispatch('toggleLoading', false);
    };
    getDynamicLists();
    const fields = reactive({
      categoryId: {
        type: 'select',
        label: 'models.ticket.attributes.category',
        placeholder: 'models.ticket.attributes.categoryPlaceholder',
        rules: [required],
        options: computed(() => {
          return _.map(getters.ticketCategories, (category) => {
            return {
              label: category.label, value: category.value
            };
          });
        }),
        trackable: true,
        styles: new FieldStyles({md: 12})
      },
      shipmentId: {
        type: 'select',
        selectType: 'text',
        label: 'models.ticket.attributes.shipmentId',
        placeholder: 'models.ticket.attributes.shipmentIdPlaceholder',
        rules: [requiredNumber],
        options: computed(() => {
          return _.map(getters.shipments.data, (shipment) => {
            return {
              label: shipment.identifier, value: shipment.id
            };
          });
        }),
        enableSearch: true,
        hidden: true,
        trackable: true,
        loading: false,
        styles: new FieldStyles({md: 12})
      },
      title: {
        type: 'text',
        label: 'models.ticket.attributes.title',
        fieldValue: '',
        placeholder: 'models.ticket.attributes.titlePlaceholder',
        disabled: false,
        size: 'large'
      },
      description: {
        type: 'textArea',
        label: 'models.ticket.attributes.description',
        fieldValue: '',
        placeholder: 'models.ticket.attributes.descriptionPlaceholder',
        disabled: false,
        size: 'large'
      },
      attachments: {
        type: 'file',
        label: 'models.ticket.attributes.file',
        disabled: false,
        size: 'large',
        placeholder: 'actions.uploadTicketAttachments',
        fieldValue: [],
        multiple: true
      }
    });
    const rules = reactive({
      title: [required],
      description: [required]
    });

    const modalVisible = ref(false);
    const openModal = () => {
      modalVisible.value = true;
    };
    const closeModal = (resetForm) => {
      if (resetForm) {
        fields.shipmentId.hidden = true;
      }
      modalVisible.value = false;
    };
    const inputChanged = async (field) => {
      if (field.key === 'categoryId') {
        const label = fields.categoryId.options.find((el) => el.value === field.value).label;
        fields.shipmentId.hidden = label !== 'Shipment';
      }
      if (field.key === 'shipmentId' && field.value && field.value.toString().length >= 2) {
        fields.shipmentId.loading = true;
        const params = {
          page: 1,
          per_page: 10,
          identifier: field.value,
          fields: ['identifier', 'id']
        };
        await dispatch('getAllShipments', {params});
        fields.shipmentId.loading = false;
      }
    };
    const submitLoading = ref(false);
    const resetForm = ref(false);
    const submitForm = async (data) => {
      resetForm.value = false;
      submitLoading.value = true;
      const formData = new FormData();
      _.forEach(data, (value, key) => {
        if (key === 'attachments' && value) {
          _.forEach(value, (file) => {
            file instanceof File ? formData.append('attachments[]', file) :
              formData.append('attachments[][id]', file.uid);
          });
        } else {
          formData.append(key, value || '');
        }
      });
      await dispatch('createTicket', {formData});
      submitLoading.value = false;
      resetForm.value = true;
      closeModal();
      fields.shipmentId.hidden = true;
    };
    const cancelButton = {type: 'secondary', visible: true};

    return {
      theme,
      fields,
      rules,
      modalVisible,
      openModal,
      closeModal,
      inputChanged,
      chatExist,
      submitForm,
      submitLoading,
      cancelButton,
      resetForm
    };
  },
  methods: {
  }
};
</script>

<style scoped>
.ticket-icon-container {
  position: fixed;
  bottom: 22px;
  right: 22px;
  z-index: 2!important;
}

.ticket-icon {
  opacity: 0.9;
  padding-top: 12px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  position: fixed;
  right: 22px;
  bottom: 22px;
  text-align: center;
  cursor: pointer;
}

.ant-badge {
  right: 5px !important;
  bottom: 55px !important;
  position: fixed;
}

@-webkit-keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

@keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

@-webkit-keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

@keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

.hvr-bob {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-bob:hover, .hvr-bob:focus, .hvr-bob:active {
  -webkit-animation-name: hvr-bob-float, hvr-bob;
  animation-name: hvr-bob-float, hvr-bob;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

.hvr-glow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
}

.hvr-glow .support-icon:hover, .hvr-glow .support-icon:focus, .hvr-glow .support-icon:active {
  box-shadow: 0 0 10px rgba(67, 71, 217, 0.9) !important;
}
</style>
