import {displayServerErrorsInToaster, displaySuccessToaster} from '@/helpers/toasters';
import {utils} from 'jsonapi-vuex';
import _ from 'lodash';

const state = {
  companyProfileSteps: []
};

const getters = {
  companyProfileSteps: (state) => state.companyProfileSteps
};

const actions = {
  async getCompanyProfileSteps({commit}, companyId) {
    const response = await this.axios.get(`/companies/${companyId}/company_profile_steps`);
    if (response.status === 200) {
      commit('setCompanyProfileSteps', response.data);
    } else {
      displayServerErrorsInToaster(response.errors.full_messages);
      this.$router.push({name: 'Companies'});
    }
    return response.status;
  },
  async updateCompanyProfileStep({commit}, data) {
    const {
      companyId, id, form
    } = data;
    const response = await this.axios.put(`/companies/${companyId}/company_profile_steps/${id}`, form);
    if (response.status === 200) {
      commit('setCompanyProfileStep', response.data);
      displaySuccessToaster(this.$t('messages.success.updated', {entity: this.$t('models.companyProfileStep.attributes.status')}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  }
};

const mutations = {
  setCompanyProfileSteps: (state, data) => {
    const steps = utils.jsonapiToNorm(data.data);
    const priceListSteps = utils.jsonapiToNorm(data.included);
    state.companyProfileSteps = {steps, priceListSteps};
  },
  setCompanyProfileStep: (state, data) => {
    state.companyProfileSteps.steps[data.data.id] = utils.jsonapiToNorm(data.data);
    const newIncluded = _.isEmpty(data.included) ? {} : utils.jsonapiToNorm(data.included);
    state.companyProfileSteps['priceListSteps'] = {
      ...state.companyProfileSteps.priceListSteps,
      ...newIncluded
    };
  }
};

export default {
  namespaced: false,
  actions,
  getters,
  mutations,
  state
};
