<template>
    <div v-if="showLauncher" class="chat-icon-container hvr-bob hvr-glow">

      <a-badge :count="badge"  class="" :offset="[-20, -20]">
        <sd-feather-icons class="chat-icon"
                          size="28"
                          :type="isOpen ? 'x' : 'message-circle'"
                          :stroke="theme['white-color']"
                          :style="{'background-color':theme['primary-color']}"
                          @click.prevent="isOpen ? close() : openAndFocus()"/>
      </a-badge>
    </div>

    <chat-window :messages="messages"
                :me="me"
                :show-file="showFile"
                :show-close-button="showCloseButton"
                :show-header="showHeader"
                :placeholder="placeholder"
                :is-open="isOpen"
                :sendAttachment="sendAttachment"
                :disabled="disabled"
                :on-load="onLoad"
                @onSubmit="onSubmit"
                @close="close"
                @onType="$emit('onType', $event)"
                :quote-Id="quoteId">
    </chat-window>
</template>

<script>
import ChatWindow from './ChatWindow.vue';
import VueTypes from 'vue-types';
import {theme} from '@/config/theme/themeVariables';

export default {
  name: 'Launcher',
  components: {
    ChatWindow
  },
  emits: ['onSubmit'],
  props: {
    isOpen: VueTypes.bool.def(false),
    sendAttachment: VueTypes.bool.def(false),
    open: VueTypes.func.required,
    close: VueTypes.func.required,
    showFile: VueTypes.bool.def(false),
    showLauncher: VueTypes.bool.def(true),
    showCloseButton: VueTypes.bool.def(true),
    showHeader: VueTypes.bool.def(true),
    disabled: VueTypes.bool.def(false),
    messages: VueTypes.array.required,
    me: VueTypes.object.required,
    placeholder: VueTypes.string.required,
    onLoad: VueTypes.func.required,
    quoteId: VueTypes.string.required,
    badge: VueTypes.number.required
  },
  setup(props, context) {
    const onSubmit = (event)=> {
      context.emit('onSubmit', event);
    };
    return {
      theme,
      onSubmit
    };
  },
  methods: {
    openAndFocus() {
      this.open();
      this.$event.$emit('focusUserInput');
    }
  }
};
</script>

<style scoped>
.chat-icon-container {
  position: fixed;
  right: 22px;
  bottom: 22px;
}

.chat-icon {
  opacity: 0.9;
  padding-top: 12px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  position: fixed;
  right: 22px;
  bottom: 22px;
  text-align: center;
  cursor: pointer;
}

.ant-badge {
  right: 5px !important;
  bottom: 55px !important;
  position: fixed;
}

@-webkit-keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

@keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

@-webkit-keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

@keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

.hvr-bob {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-bob:hover, .hvr-bob:focus, .hvr-bob:active {
  -webkit-animation-name: hvr-bob-float, hvr-bob;
  animation-name: hvr-bob-float, hvr-bob;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

.hvr-glow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow;
  transition-property: box-shadow;
}

.hvr-glow .chat-icon:hover, .hvr-glow .chat-icon:focus, .hvr-glow .chat-icon:active {
  box-shadow: 0 0 10px rgba(67, 71, 217, 0.9) !important;
}
</style>
