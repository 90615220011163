<template>
  <app-modal :classes="classes" :title="title" type="primary" :visible="visible" :onCancel="onCancel">
    <app-form :fields="fields" :rules="rules" :cancelButton="cancelButton"
              @submitted="handleSubmit" @cancel="closeModal(true)" :resetForm="resetForm"
              :loading="loading" @update:resetForm="$emit('update:resetForm', false)"
              @forceUpdated="$emit('forceUpdated', $event)"
              @inputChanged="inputChanged"/>
  </app-modal>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import AppModal from './Modal';

export default defineComponent({
  name: 'Modal',
  emits: ['closeModal', 'submitForm', 'update:resetForm', 'forceUpdated'],
  components: {AppModal},
  props: {
    visible: VueTypes.bool.def(false),
    loading: VueTypes.bool.def(false),
    title: VueTypes.string.def(''),
    classes: VueTypes.string,
    fields: VueTypes.object,
    rules: VueTypes.object.def(undefined),
    cancelButton: VueTypes.object.def(undefined),
    submitButton: VueTypes.object,
    resetForm: VueTypes.bool.def(false)
  },
  setup(props, context) {
    const handleSubmit = (data) => context.emit('submitForm', data);

    const closeModal = (resetForm) => context.emit('closeModal', resetForm);
    const onCancel = () => context.emit('closeModal');

    const inputChanged = (data) => context.emit('inputChanged', data);

    return {
      handleSubmit,
      closeModal,
      onCancel,
      inputChanged
    };
  }
});
</script>

<style lang="scss" scoped>
.ant-modal-body {
  padding-top: 0px !important;
}
</style>
