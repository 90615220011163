/**
 * A class that can return field styles and column spans
 */
export class FieldStyles {
  /** @param {Object} styles object  */
  constructor(styles = {}) {
    this.xs = styles.xs || 24;
    this.sm = styles.sm || 24;
    this.md = styles.md || styles.sm;
    this.lg = styles.lg || styles.md;
    this.xl = styles.xl || styles.lg;
    this.classes = styles.classes || '';
  }
}
