import _ from 'lodash';
import {Notification} from 'ant-design-vue';

const toaster = (message, status) => {
  Notification[status]({
    message: message,
    placement: 'topRight'
  });
};

export const displaySuccessToaster = (message) => {
  toaster(message, 'success');
};

export const displayServerErrorsInToaster =(errors) => {
  _.forEach(errors, (error) => {
    setTimeout(() => {
      toaster(error, 'error');
    }, 1);
  });
};

