const initialState = {};
const state = () => initialState;

const mutations = {
  searchHeaderSuccess(state, data) {
    state = data;
  },

  searchHeaderErr(state, err) {
    state = err;
  }
};

const actions = {
  async headerSearchAction({commit}, searchData) {
    try {
      commit('searchHeaderBegin');
      const data = initialState.filter((item) => {
        return item.title.startsWith(searchData);
      });
      commit('searchHeaderSuccess', data);
    } catch (err) {
      commit('searchHeaderErr', err);
    }
  }
};

export default {
  namespaced: false,
  state,
  actions,
  mutations
};
