export default [
  {
    name: 'ClientRegistration',
    path: '/client/registration',
    component: () => import('../layout/registrationLayout.vue'),
    children: [
      {
        name: 'ClientRegistrationForm',
        path: '',
        component: () => import('@/views/client/registration/form.vue')
      },
      {
        name: 'ClientRegistrationSuccess',
        path: 'success',
        component: () => import('@/views/common/registration/success.vue')
      }
    ]
  },
  {
    name: 'AgentRegistration',
    path: '/agent/registration',
    component: () => import('../layout/registrationLayout.vue'),
    children: [
      {
        name: 'AgentRegistrationForm',
        path: '',
        component: () => import('@/views/agent/registration/form.vue')
      },
      {
        name: 'AgentRegistrationSuccess',
        path: 'success',
        component: () => import('@/views/common/registration/success.vue')
      }
    ]
  }
];
