<template>
  <div>
    <a-form-item :label="label ? $t(label): ''" :name="fieldKey" :rules="rules">
      <a-upload
        class="sDash_upload-basic"
        :beforeUpload="beforeUpload"
        :remove="handleRemove"
        :file-list="files"
        :disabled="disabled"
        :multiple="multiple"
        :placeholder="'test'"
        @preview="previewFile"
      >
       <template v-if="iconButton && !hideUploadIcon">
         <a-tooltip>
          <template #title>{{$t('actions.upload')}}</template>
            <sdFeatherIcons type="upload" size="14" />
         </a-tooltip>
       </template>
       <template v-if="!iconButton && !hideUploadIcon">
          <span v-if="placeholder" class="sDash_upload-text">{{$t(placeholder)}}</span>
          <a to="#" class="sDash_upload-browse">
            {{ $t('actions.browse') }}
          </a>
       </template>
      </a-upload>
    </a-form-item>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import {
  defineComponent, toRefs, watch
} from 'vue';
import {uploader} from '@/helpers/uploader';
import {downloadFile} from '@/helpers/downloadFile';


export default defineComponent({
  name: 'FileUploader',
  emits: ['update:filesValue'],
  props: {
    label: VueTypes.string.def(''),
    disabled: VueTypes.bool.def(false),
    fieldKey: VueTypes.oneOfType([VueTypes.array, VueTypes.string]),
    placeholder: VueTypes.string.def(''),
    filesValue: VueTypes.array.def([]),
    multiple: VueTypes.bool.def(false),
    iconButton: VueTypes.bool.def(false),
    required: VueTypes.bool.def(false),
    hideUploadIcon: VueTypes.bool.def(false)
  },
  setup(props, context) {
    const {
      files, rules, beforeUpload, handleRemove
    } = uploader(props.multiple, context, props.required);
    const previewFile = (file) => {
      const fileUrl = file.url.replace(process.env.VUE_APP_GCE_BASE_URL, '');
      downloadFile(file.name, fileUrl);
    };
    const {filesValue} = toRefs(props);
    watch(filesValue, (value) => {
      files.value = value;
    }, {immediate: true});

    return {
      files,
      rules,
      handleRemove,
      beforeUpload,
      previewFile
    };
  }
});
</script>

