import {displaySuccessToaster, displayServerErrorsInToaster} from '@/helpers/toasters';
import {utils} from 'jsonapi-vuex';
import _ from 'lodash';
import moment from 'moment';
import {Meta} from '@/models/meta';
import router from '@/routes';

const state = {
  shipmentQuotes: {},
  shipmentQuote: {}
};

const getters = {
  shipmentQuote: (state) => state.shipmentQuote,
  shipmentQuotes: (state) => state.shipmentQuotes
};

const actions = {
  async getShipmentQuote({commit}, {id, params}) {
    const response = await this.$http.get(`/shipment_quotes/${id}`, {params});
    if (response.status === 200) {
      commit('setShipmentQuote', response.data);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async updateShipmentQuote({commit}, {
    id, form, saveDraft, updateType
  }) {
    const url = saveDraft ? `/shipment_quotes/${id}/save_draft` : `/shipment_quotes/${id}`;
    const response = await this.$http.put(url, {...form, update_type: updateType});
    if (response.status === 200) {
      commit('setShipmentQuote', response.data);
      displaySuccessToaster(this.$t('messages.success.updated', {entity: this.$t('models.shipmentQuote.entity', 1)}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response;
  },
  async createShipmentQuote({commit}, {form}) {
    const response = await this.$http.post('/shipment_quotes/', form);
    if (response.status === 200) {
      commit('setShipmentQuote', response.data);
      displaySuccessToaster(this.$t('messages.success.created', {entity: this.$t('models.shipmentQuote.entity', 1)}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response;
  },
  async approveShipmentQuote({commit}, {id, form}) {
    const response = await this.$http.put(`/shipment_quotes/${id}/approve`, form);
    if (response.status === 200) {
      commit('setShipmentQuote', response.data);
      displaySuccessToaster(this.$t('messages.success.approved', {entity: this.$t('models.shipmentQuote.entity', 1)}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async revalidateShipmentQuote({commit}, id) {
    const response = await this.$http.put(`/shipment_quotes/${id}/revalidate`);
    if (response.status === 200) {
      commit('setShipmentQuote', response.data);
      displaySuccessToaster(this.$t('messages.success.revalidated', {entity: this.$t('models.shipmentQuote.entity', 1)}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async markShipmentQuoteAsTemplate({}, id) {
    const response = await this.$http.post(`/shipment_quotes/${id}/mark_as_template`);
    if (response.status === 200) {
      displaySuccessToaster(this.$t('messages.success.markedAsTemplate', {entity: this.$t('models.shipmentQuote.entity', 1)}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async rejectShipmentQuote({commit}, {id, form}) {
    const response = await this.$http.put(`/shipment_quotes/${id}/reject`, form);
    if (response.status === 200) {
      commit('setShipmentQuote', response.data);
      displaySuccessToaster(this.$t('messages.success.rejected', {entity: this.$t('models.shipmentQuote.entity', 1)}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async requestQuoteRevalidation({commit}, id) {
    const response = await this.$http.put(`/shipment_quotes/${id}/request_revalidation`);
    if (response.status === 200) {
      commit('setShipmentQuote', response.data);
      displaySuccessToaster(this.$t('messages.success.requestRevalidation'));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async getQuoteByShipment({commit}, {
    shipmentRequestId, shipmentId, params
  }) {
    const response = await this.$http.get(`/shipment_requests/${shipmentRequestId}/shipments/${shipmentId}/quote`, {params});
    if (response.status === 200) {
      if (params && params[_.snakeCase('additionalQuote')]) {
        response.data.data.attributes['note'] = '';
        response.data.data.attributes['route'] = '';
        response.data.data.attributes['reference'] = '';
        response.data.data.attributes['categories'] = response.data.data.attributes['unlinkedSubCategories'];
        response.data.data.attributes['unlinkedSubCategories'] = [];
      }
      commit('setShipmentQuote', response.data);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async getQuoteTemplate({commit}, {params}) {
    const response = await this.$http.get('/template_quote', {params});
    if (response.status === 200) {
      commit('setShipmentQuote', response.data);
    } else {
      commit('setShipmentQuote', null);
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async createDummyQuote(_, {shipmentRequestId, shipmentId}) {
    const response = await this.$http.get(`/shipment_requests/${shipmentRequestId}/shipments/${shipmentId}/generate_quote`);
    if (response.status !== 200) {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async getShipmentQuotes({commit}, data) {
    const response = await this.$http.get('/shipment_quotes', {params: data});
    if (response.status === 200) {
      commit('setShipmentQuotes', response.data);
    } else {
      commit('setShipmentQuotes', []);
    }
    return response.status;
  },
  async markPaymentShipmentQuote({commit}, {id, form}) {
    const response = await this.$http.put(`/shipment_quotes/${id}/mark_payment`, form);
    if (response.status === 200) {
      commit('setShipmentQuote', response.data);
      displaySuccessToaster(this.$t('messages.success.updated', {entity: this.$t('models.shipmentQuote.entity', 1)}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  }
};

const mutations = {
  setShipmentQuotes: (state, shipmentQuotes) => {
    const quotes = _.map(shipmentQuotes.data, (quote) => {
      const normalisedQuote = utils.jsonapiToNorm(quote);
      const {
        agent, shipment
      } = normalisedQuote._jv.relationships;
      const normalisedShipment = utils.jsonapiToNorm(_.find(shipmentQuotes.included, shipment.data));
      normalisedQuote._jv.relationships.agent = utils.jsonapiToNorm(_.find(shipmentQuotes.included, agent.data));
      normalisedQuote._jv.relationships.shipment = normalisedShipment;
      return normalisedQuote;
    });
    const meta = new Meta(shipmentQuotes.meta);
    state.shipmentQuotes = {data: quotes, ...meta};
  },
  setShipmentQuote: (state, quote) => {
    if (quote) {
      const shipmentQuote = utils.jsonapiToNorm(quote.data);
      const shipment = shipmentQuote._jv.relationships.shipment.data;
      shipmentQuote._jv.relationships.shipment = utils.jsonapiToNorm(_.find(quote.included, shipment));
      shipmentQuote.expirationDate = shipmentQuote.expirationDate ? moment(shipmentQuote.expirationDate) : undefined;
      state.shipmentQuote = shipmentQuote;
      if (router.currentRoute.value.query.template === 'true') {
        const service = _.find(state.shipmentQuote['categories'], ({name}) => name === 'service');
        if (!service) return;
        const foundServiceSubCategory = _.find(service.subCategories,
          ({name}) => name === state.shipmentQuote.shipmentType);
        service.subCategories = [{
          id: foundServiceSubCategory ? foundServiceSubCategory.id : null,
          name: state.shipmentQuote.shipmentType,
          value: foundServiceSubCategory ? foundServiceSubCategory.value : 0,
          note: foundServiceSubCategory ? foundServiceSubCategory.note : null
        }];
      }
    } else {
      state.shipmentQuote = {};
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
