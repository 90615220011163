<template>
  <a-dropdown :overlayClassName="classes" :placement="placement" :trigger="action">
    <sdButton v-if="isButton" type="light" class="fill-width">
      <sdFeatherIcons v-if="icon" :type="icon" size="20" />
      <span>{{title}}</span>
    </sdButton>
    <p v-else class="cursor-pointer" @click.prevent>
      <span>
        {{title}}
        <sdFeatherIcons v-if="icon" :type="icon" size="20" />
        <EllipsisOutlined v-else/>
      </span>
    </p>
    <template v-slot:overlay>
      <Content>
        <template v-for="option in options" :key="option.value">
          <slot name="overlay">
            <a @click="$emit('selectedOption', option.value)" v-if="labelExists">
              <span>{{option.label}}</span>
            </a>
            <div v-else>
              <p class="ml-10 mr-10">{{option}}</p>
            </div>
          </slot>
        </template>
      </Content>
    </template>
    <slot></slot>
  </a-dropdown>
</template>

<script>
import {defineComponent} from '@vue/runtime-core';
import VueTypes from 'vue-types';
import {Content} from './dropdown-style';
import {EllipsisOutlined} from '@ant-design/icons-vue';

export default defineComponent({
  name: 'Dropdown',
  components: {
    Content,
    EllipsisOutlined
  },
  props: {
    placement: VueTypes.string.def('bottomRight'),
    title: VueTypes.string,
    action: VueTypes.array.def(['hover']),
    classes: VueTypes.string.def('strikingDash-dropdown'),
    options: VueTypes.array.required,
    icon: VueTypes.string,
    isButton: VueTypes.bool.def(false),
    labelExists: VueTypes.bool.def(true)
  },
  emits: ['selectedOption']
});
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
