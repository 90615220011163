import {displayServerErrorsInToaster, displaySuccessToaster} from '@/helpers/toasters';
import {dynamicLists} from '@/constants';
import {utils} from 'jsonapi-vuex';
import _ from 'lodash';

const state = {
  filteredLists: {},
  trackingStatuses: [],
  iorScopes: [],
  eorScopes: [],
  packagingTypes: [],
  countries: [],
  productCategories: [],
  ticketCategories: []
};

const getters = {
  filteredLists: (state) => state.filteredLists,
  trackingStatuses: (state) => state.trackingStatuses,
  iorScopes: (state) => state.iorScopes,
  eorScopes: (state) => state.eorScopes,
  packagingTypes: (state) => state.packagingTypes,
  countries: (state) => state.countries,
  productCategories: (state) => state.productCategories,
  ticketCategories: (state) => state.ticketCategories
};

const actions = {
  async getList({commit}, listName) {
    const list = dynamicLists[listName];
    if (!list) {
      return displayServerErrorsInToaster(['List not defined']);
    }
    const response = await this.$http.get('/dynamic_lists', {params: {list_name: list ? list.name : ''}});
    if (response.status === 200) {
      const {data} = response.data;
      commit(list.mutation, {data, listName});
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async getCountries({commit}, params) {
    const response = await this.$http.get('/dynamic_lists/countries', {params});
    if (response.status === 200) {
      commit('setCountries', response.data.countries);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async getFilteredLists({commit}, listNames) {
    const response = await this.$http.get('/dynamic_lists/filtered_lists', {params: {list_name: listNames ? listNames : []}});
    if (response.status === 200) {
      const {data} = response.data;
      commit('setFilteredLists', data);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async addDynamicListItem({commit}, form) {
    const response = await this.$http.post('/dynamic_lists', form);
    if (response.status === 200) {
      commit('pushListItem', response.data.data);
      displaySuccessToaster(this.$t('messages.success.created', {entity: 'item'}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async updateDynamicListItem({state, commit}, {item, form}) {
    const response = await this.$http.put(`/dynamic_lists/${item._jv.id}`, form);
    if (response.status === 200) {
      const index = state.filteredLists[item.listName].indexOf(item);
      if (_.has(form, _.snakeCase('endStatus'))) {
        commit('updateFinalState');
      }
      commit('updateListItem', {index, item: response.data.data});
      displaySuccessToaster(this.$t('messages.success.updated', {entity: 'item'}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  }
};

const mutations = {
  setTrackingStatuses: (state, {data}) => {
    state.trackingStatuses = _.map(data, (status) => {
      return {
        value: status.id,
        label: status.attributes.value,
        endStatus: status.attributes.endStatus,
        active: status.attributes.active
      };
    });
  },
  setLists: (state, {data, listName}) => {
    state[_.camelCase(listName)] = _.map(data, (option) => {
      return {
        value: option.id,
        label: option.attributes.value,
        active: option.attributes.active
      };
    });
  },
  setCountries: (state, countries) => {
    state.countries = countries.map((country) => {
      return {
        value: country.country_short_code,
        label: country.country_name,
        phoneCode: country.phone_code
      };
    });
  },
  setFilteredLists: (state, data) => {
    const formattedData = {};
    _.forEach(data, (list) => {
      let listItems = _.map(list.items.data, (item) => utils.jsonapiToNorm(item));
      listItems = _.sortBy(listItems, (item) => !item.active);
      formattedData[list.name] = listItems;
    });
    state.filteredLists = formattedData;
  },
  updateListItem: (state, {index, item}) => state.filteredLists[item.attributes[_.camelCase('listName')]][index] = utils.jsonapiToNorm(item),
  pushListItem: (state, item) => {
    state.filteredLists[item.attributes[_.camelCase('listName')]].push(utils.jsonapiToNorm(item));
  },
  updateFinalState: (state) => {
    const index = _.findIndex(state.filteredLists[dynamicLists.TRACKING_STATUS.name], (status) => status.endStatus);
    state.filteredLists[dynamicLists.TRACKING_STATUS.name][index].endStatus = false;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
