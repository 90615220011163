<template>
<!--  <p v-if="index === 1" class="time-connector text-center text-capitalize">-->
<!--    <span>today</span>-->
<!--  </p>-->

  <div :key="key" :style="{ overflow: 'hidden' }">
    <div :class="author.email !== me.email ? 'left' : 'right'">

      <div class="atbd-chatbox__content">
        <sd-heading as="h5" class="atbd-chatbox__name">
          {{ author.email !== me.email ? author.name : null }}
          <span>
            {{ moment(time).format('MM-DD-YYYY') === moment().format('MM-DD-YYYY')
            ? moment(time).format('hh:mm A')
            : moment(time).format('LL')}}
          </span>
          <span v-if="author.email === me.email" class="ml-4">
            <span v-if="status === messageStatuses.PENDING" class="m-0 ml-2">...</span>
            <sd-feather-icons v-else-if="status === messageStatuses.RECEIVED" type="check" class="al-v" :size=12 />
          </span>
        </sd-heading>

        <div class="atbd-chatbox__contentInner d-flex">
          <div class="atbd-chatbox__message preserve-newline">
            <message-wrapper :class="`message-box ${author.email !== me.email ? 'mr-30' : 'ml-30'}`">
              {{content}}
              <template v-if="!$_.isEmpty(attachments)">
                <template v-if="content">
                  <br />
                </template>
                <a to="#" @click="downloadAttachment(attachments)"
                  :class="author.email !== me.email ? 'dark-background-link' : ''">
                  {{attachments.filename}}
                </a>
              </template>
            </message-wrapper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {MessageWrapper} from './style';
import VueTypes from 'vue-types';
import {downloadFile} from '@/helpers/downloadFile';
import {messageStatuses} from '@/constants';

export default {
  components: {
    MessageWrapper
  },
  props: {
    key: VueTypes.number.required,
    content: VueTypes.string.required,
    author: VueTypes.object.required,
    time: VueTypes.number.required,
    image: VueTypes.object,
    attachments: VueTypes.object,
    status: VueTypes.string,
    me: VueTypes.object.required
  },
  setup() {
    const downloadAttachment = (attachment) => {
      downloadFile(attachment.filename, attachment.url);
    };
    return {
      downloadAttachment,
      messageStatuses,
      moment
    };
  }
};
</script>

<style scoped>
.m-0 {
  margin: 0 !important;
}
.ml-2 {
  margin-left: 2px !important;
}
.ml-4 {
  margin-left: 4px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.al-v {
  vertical-align: middle !important;
}
.preserve-newline {
  white-space: pre-line;
  word-break: break-all;
}
</style>
