
<template>
  <div>
    <a-form-item :label="label ? $t(label) : ''" :name="fieldKey" >
      <a-date-picker :value="fieldValue" :allowClear="allowClear"
                     :placeholder="placeholder ? $t(placeholder) : ''" :disabled="disabled"
                     :inputReadOnly="true" format="DD MMM YYYY"
                     @change="onChange" :disabledDate="disabledDate" />
    </a-form-item>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import moment from 'moment';

export default defineComponent({
  name: 'DatePicker',
  emits: ['update:fieldValue'],
  props: {
    /**
   * fieldValue Moment object in correct date format (DD MMM YYYY)
   */
    fieldValue: VueTypes.object.def(undefined),
    label: VueTypes.string,
    disabled: VueTypes.bool.def(false),
    fieldKey: VueTypes.oneOfType([VueTypes.array, VueTypes.string]),
    placeholder: VueTypes.string.def(''),
    allowClear: VueTypes.bool.def(true),
    futureDate: VueTypes.bool.def(false)
  },
  setup(props, context) {
    const disabledDate = (current) => {
      // Can not select days before today and today
      return props.futureDate && current && current < moment().endOf('day');
    };

    const onChange = (date, dateString) => {
      context.emit('update:fieldValue', date);
    };

    return {
      disabledDate,
      onChange
    };
  }
});
</script>

<style scoped>
.ant-calendar-picker {
  width: 100% !important;
}
</style>
