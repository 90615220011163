import {utils} from 'jsonapi-vuex';
import {displaySuccessToaster, displayServerErrorsInToaster} from '@/helpers/toasters';
import _ from 'lodash';
import buildAbility from '@/core/plugins/ability';

const state = {
  currentUser: JSON.parse(localStorage.getItem('currentUser')),
  isLoggedIn: !!localStorage.getItem('accessToken'),
  accessToken: localStorage.getItem('accessToken'),
  client: localStorage.getItem('client'),
  uid: localStorage.getItem('uid'),
  userType: localStorage.getItem('userType')
};

const getters = {
  currentUser: (state) => state.currentUser,
  userType: (state) => {
    switch (state.userType) {
    case 'AgentUser':
      return 'agent';
    case 'ClientUser':
      return 'client';
    case 'BackOfficeUser':
      return 'back_office';
    }
  },
  isLoggedIn: (state) => state.isLoggedIn,
  userPermissions: (state) => state.currentUser?.permissions || [],
  baseURL: (state) => {
    let namespace = '';
    switch (state.userType) {
    case 'AgentUser':
      namespace = 'agent';
      break;
    case 'ClientUser':
      namespace = 'client';
      break;
    case 'BackOfficeUser':
      namespace = 'back_office';
      break;
    }
    return `${process.env.VUE_APP_GCE_BASE_URL}/api/${namespace}/v1/`;
  }
};

const mutations = {
  setCurrentUser: (state, response) => {
    const currentUser = utils.jsonapiToNorm(response.data.data);
    if (currentUser._jv.relationships.company) {
      const company = currentUser._jv.relationships.company.data;
      currentUser._jv.relationships.company = utils.jsonapiToNorm(_.find(response.data.included, company));
    }
    state.currentUser = currentUser;
    state.userType = state.currentUser.type;
    localStorage.setItem('userType', state.userType);
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  },
  setSession: (state, response) => {
    state.isLoggedIn = true;
    state.accessToken = response.headers['access-token'];
    state.client = response.headers['client'];
    state.uid = response.headers['uid'];
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('accessToken', state.accessToken);
    localStorage.setItem('client', state.client);
    localStorage.setItem('uid', state.uid);
  },
  clearSession: (state) => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('client');
    localStorage.removeItem('uid');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('shipmentRequestColumns');
    localStorage.removeItem('shipmentColumns');


    state.isLoggedIn = false;
    state.accessToken = '';
    state.client = '';
    state.uid = '';
    state.currentUser = {};
  },
  setUserType: (state, userType) => {
    state.userType = userType;
    localStorage.setItem('userType', userType);
  },
  setUserCompanyStatus: (state, status) => {
    const user = state.currentUser;
    user._jv.relationships.company.status = status;
    localStorage.setItem('currentUser', JSON.stringify(user));
  }
};

const actions = {
  async login({state, commit}, data) {
    const response = await this.axios.post('/auth/sign_in', data);
    if (response.status === 200) {
      commit('setCurrentUser', response);
      const havePermission = state.currentUser.permissions.includes('edit_agent_profile') || state.currentUser.permissions.includes('manage_shipment_request');
      if (state.userType === 'AgentUser' && _.find(response.data.included, (relation) => relation.type === 'company').attributes.status !== 'approved' && !havePermission) {
        commit('setError', this.$t('messages.error.companyApprovalRequired'));
        return;
      }
      const company = _.find(response.data.included, {type: 'company'})?.attributes;
      switch (company?.status) {
      case 'blacklisted':
        commit('setError', this.$t('messages.error.companyBlacklisted', {date: company.blacklistedUntil}));
        return;
      case 'draft':
        commit('setError', this.$t('messages.error.invalidLoginCredentials'));
        return;
      case 'in_review':
        if (state.userType === 'AgentUser') {
          commit('setError', this.$t('messages.error.companyApprovalRequired'));
          return;
        }
      }
      this.$ability.update(buildAbility(state.currentUser.permissions).rules);
      commit('setSession', response);
      if (state.userType === 'BackOfficeUser') {
        await this.$router.push('/back-office');
      } else {
        await this.$router.push(state.userType === 'AgentUser' ? '/agent' : '/client');
      }
      commit('setError', '');
    } else {
      commit('clearSession');
      commit('setError', response.errors[0]);
    }
  },
  async forgotPassword({state, commit}, data) {
    const response = await this.axios.post('/auth/password', data);
    if (response.status === 200) {
      switch (state.userType) {
      case 'AgentUser':
        this.$router.push('/agent/auth');
        break;
      case 'ClientUser':
        this.$router.push('/client/auth');
        break;
      case 'BackOfficeUser':
        this.$router.push('/back-office/auth');
        break;
      }
      displaySuccessToaster(response.data.message);
    } else {
      commit('setError', response.errors[0]);
    }
  },
  async resetPassword({state, commit}, data) {
    const path = _.has(data, 'invitation_token') ? '/auth/invitation' : '/auth/password';
    const response = await this.axios.put(path, data);
    if (response.status === 200) {
      displaySuccessToaster(this.$t('messages.success.passwordSet'));
      if (response.headers['access-token']) {
        commit('setCurrentUser', response);
        this.$ability.update(buildAbility(state.currentUser.permissions).rules);
        commit('setSession', response);
      }
      switch (state.userType) {
      case 'AgentUser':
        this.$router.push('/agent');
        break;
      case 'ClientUser':
        this.$router.push('/client');
        break;
      case 'BackOfficeUser':
        this.$router.push('/back-office');
        break;
      }
      commit('setError', '');
    } else if (_.isEmpty(response.errors)) {
      commit('setError', this.$t('messages.error.notAuthorisedToResetPassword'));
    } else {
      commit('setError', response.errors[0]);
    }
  },
  async updateAccount({commit}, data) {
    const response = await this.axios.put('/auth', data);
    if (response.status === 200) {
      commit('setCurrentUser', response);
      commit('setUser', response.data);
      displaySuccessToaster(this.$t('messages.success.updated', {entity: this.$t('models.user.attributes.account')}));
    } else {
      displayServerErrorsInToaster(response.errors.full_messages);
    }
    return response.status;
  },
  async logout({commit}) {
    await this.axios.delete('/auth/sign_out');
    commit('clearSession');
    this.$router.push('/');
    commit('clearNotifications');
    this.$ability.update([]);
  },
  async resetSession({commit}) {
    commit('clearSession');
    commit('clearNotifications');
  },
  async isEmailAvailable({commit}, email) {
    const response = await this.axios.get('/user/check_email_validity', {params: {email: email}});
    return response.status === 200;
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};
