import {displayServerErrorsInToaster, displaySuccessToaster} from '@/helpers/toasters';
import _ from 'lodash';
import {utils} from 'jsonapi-vuex';

const state = {
  categoryTypes: [],
  categoryItems: [],
  hsCodesColumns: {}
};

const getters = {
  categoryTypes: (state) => state.categoryTypes,
  categoryItems: (state) => state.categoryItems,
  hsCodesColumns: (state) => state.hsCodesColumns
};

const actions = {
  async getCategoryTypes({commit}, params) {
    const response = await this.$http.get('/price_category_items/category_types', {params});
    if (response.status === 200) {
      commit('setCategoryTypes', response.data);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async getHsCodesColumns({commit}, {companyId, params}) {
    const response = await this.$http.get(`/companies/${companyId}/hs_code_item_columns`, {params});
    if (response.status === 200) {
      commit('setHsCodesColumns', response.data);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async getCategoryItems({commit}, data) {
    const response = await this.$http.get('/price_category_items', {params: data});
    if (response.status === 200) {
      commit('setCategoryItems', response.data);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async updateCategory({commit}, data) {
    const response = await this.$http.put('/price_category_items/update_category', data);
    if (response.status === 200) {
      commit('setCategoryItems', response.data);
      displaySuccessToaster(this.$t('messages.success.updated',
        {entity: this.$t(`models.priceCategory.attributes.categoryTypes.${data[_.snakeCase('categoryType')]}`)}));
    } else {
      displayServerErrorsInToaster(_.values(...response.errors));
      return response.errors;
    }
  }
};

const mutations = {
  setCategoryTypes: (state, data) => state.categoryTypes = data[_.snakeCase('categoryTypes')],
  setCategoryItems: (state, {data}) => state.categoryItems = _.values(utils.jsonapiToNorm(data)),
  setHsCodesColumns: (state, {data}) => {
    const cols = {};
    _.forEach(data, (col) => {
      cols[col.attributes.key] = {
        [_.snakeCase('colName')]: col.attributes.colName,
        id: col.id,
        key: col.attributes.key,
        [_.snakeCase('countryCode')]: col.attributes.countryCode
      };
    });
    state.hsCodesColumns = cols;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
