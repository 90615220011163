import {utils} from 'jsonapi-vuex';
import {displayServerErrorsInToaster, displaySuccessToaster} from '@/helpers/toasters';
import _ from 'lodash';

const state = {
  systemConfigurations: {},
  sanctionedCountries: [],
  bankAccountInformation: {},
  allowedShipmentRequestsForNonVerifiedClient: ''
};

const getters = {
  systemConfigurations: (state) => state.systemConfigurations,
  sanctionedCountries: (state) => state.sanctionedCountries,
  bankAccountInformation: (state) => state.bankAccountInformation,
  allowedShipmentRequestsForNonVerifiedClient: (state) => state.allowedShipmentRequestsForNonVerifiedClient
};

const actions = {
  async getSystemConfigurations({state, commit}, data) {
    const response = await this.axios.get('/system_configurations', data);
    if (response.status === 200) {
      commit('setSystemConfigurations', response);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async updateSystemConfiguration({commit}, {id, form}) {
    const response = await this.$http.put(`/system_configurations/${id}`, form);
    if (response.status === 200) {
      commit('updateConfigurationInList', response.data.data);
      displaySuccessToaster(this.$t('messages.success.updated', {entity: this.$t('models.systemConfiguration.entity', 1)}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async getSanctionedCountries({commit}) {
    const response = await this.axios.get('/sanctioned_countries');
    if (response.status === 200) {
      commit('setSanctionedCountries', response);
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async sanctionACountry({rootState, commit}, form) {
    const response = await this.axios.post('/sanctioned_countries', form);
    if (response.status === 200) {
      commit('addToSanctioned', {data: response.data.data, rootState});
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async deleteSanctionedCountry({rootState, commit}, country) {
    const response = await this.axios.delete(`/sanctioned_countries/${country.id}`);
    if (response.status === 204) {
      commit('removeFromSanctioned', {country, rootState});
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async getSystemConfigurationByKey({commit}, key) {
    const response = await this.axios.get('/system_configurations/show_by_key', {
      params: {key}
    });
    if (response.status === 200) {
      commit('setSystemConfiguration', {data: response.data.data, key});
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  }
};

const mutations = {
  setSystemConfigurations: (state, response) => {
    state.systemConfigurations = _.values(utils.jsonapiToNorm(response.data.data));
  },
  updateConfigurationInList: (state, record) => {
    const index = _.findIndex(state.systemConfigurations, (config) => config._jv.id === record.id);
    if (index >= 0) {
      state.systemConfigurations[index] = utils.jsonapiToNorm(record);
    }
  },
  setSanctionedCountries: (state, response) => {
    state.sanctionedCountries = response.data.data.map((country) => {
      return {
        value: country.attributes.countryCode,
        label: country.attributes.countryName,
        id: country.id
      };
    });
  },
  addToSanctioned: (state, {data, rootState}) => {
    const index = _.findIndex(rootState.dynamicLists.countries, (country) => {
      return country.value === data.attributes.countryCode;
    });
    rootState.dynamicLists.countries.splice(index, 1);
    state.sanctionedCountries.push({
      value: data.attributes.countryCode,
      label: data.attributes.countryName,
      id: data.id
    });
  },
  removeFromSanctioned: (state, {country, rootState}) => {
    const index = _.findIndex(state.sanctionedCountries, country);
    state.sanctionedCountries.splice(index, 1);
    rootState.dynamicLists.countries.push(country);
  },
  setSystemConfiguration: (state, {data, key}) => state[_.camelCase(key)] = utils.jsonapiToNorm(data)
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};
