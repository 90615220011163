<template>
  <div>
  <a-form-item :label="label ? $t(label) : ' '" :name="fieldKey" class="switch-field-item" >
    <a-switch :checked="fieldValue" :disabled="disabled"
                @change="$emit('update:fieldValue', $event)">
    </a-switch>
  </a-form-item>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'CheckBox',
  emits: ['update:fieldValue'],
  props: {
    label: VueTypes.string.def(''),
    fieldValue: VueTypes.bool.def(false),
    disabled: VueTypes.bool.def(false),
    fieldKey: VueTypes.oneOfType([VueTypes.array, VueTypes.string])
  }
});
</script>

<style lang="scss">
.inline-switch {
  display: flex !important;
  align-items: center !important;
}
</style>
