export const userTypes = {
  PRIMARY_USER: 'primary_user',
  SECONDARY_USER: 'secondary_user'
};

export const shipmentRequestTypes = {
  IOR: 'IOR',
  EOR: 'EOR'
};

export const shipmentRequestPublishStatus = {
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished'
};

export const packagingUnits = {
  INCH: 'inch',
  CM: 'cm'
};

export const weightUnits = {
  LBS: 'lbs',
  KG: 'kg'
};

export const temperatureUnits = {
  FAHRENHEIT: 'fahrenheit',
  CELSIUS: 'celsius'
};

export const shipmentStatuses = {
  SUBMITTED: 'submitted',
  AGENT_QUOTES_SUBMITTED: 'agent_quotes_submitted',
  QUOTE_APPROVED: 'quote_approved',
  CANCELLED: 'cancelled',
  AWAITING_CANCELLATION_APPROVAL: 'awaiting_cancellation_approval',
  FINAL_DOCUMENTS_REQUESTED: 'final_documents_requested',
  INVOICE_GENERATED: 'invoice_generated',
  COMPLETED: 'completed',
  assigned_agent_rejected_shipment: 'all_quotes_rejected',
  ASSIGNED_AGENT_REJECTED_SHIPMENT: 'assigned_agent_rejected_shipment',
  PUBLISH_TO_OTHER_AGENTS_ACTION_NEEDED: 'publish_to_other_agents_action_needed',
  AWAITING_COMPLETION_APPROVAL: 'awaiting_completion_approval'
};

export const shipmentQuoteStatuses = {
  WAITING_AGENT_RESPONSE: 'waiting_agent_response',
  WAITING_PRIMARY_USER_APPROVAL: 'waiting_primary_user_approval',
  MODIFICATION_REQUESTED: 'modification_requested',
  DOCUMENTS_REQUESTED: 'documents_requested',
  DOCUMENTS_SUBMITTED: 'documents_submitted',
  SUBMITTED: 'submitted',
  REVALIDATION_REQUESTED: 'revalidation_requested',
  EXPIRED: 'expired',
  REJECTED: 'rejected',
  APPROVED: 'approved',
  ARCHIVED: 'archived',
  AWAITING_RECONFIRMATION: 'awaiting_reconfirmation'
};

export const shipmentDetailsView = {
  FULL: 'full',
  SUMMARY: 'SUMMARY'
};

export const agentShipmentStatus = {
  REJECTED: 'rejected',
  REJECTION_WAITING_APPROVAL: 'waiting_for_approval_on_rejection'
};

export const dynamicLists = {
  TRACKING_STATUS: {name: 'tracking_status', mutation: 'setTrackingStatuses'},
  IOR_SCOPES: {name: 'ior_scopes', mutation: 'setLists'},
  EOR_SCOPES: {name: 'eor_scopes', mutation: 'setLists'},
  PACKAGING_TYPES: {name: 'packaging_types', mutation: 'setLists'},
  PRODUCT_CATEGORIES: {name: 'product_category', mutation: 'setLists'},
  TICKET_CATEGORIES: {name: 'ticket_categories', mutation: 'setLists'}
};

export const iorScope = {
  DOOR_TO_DOOR: 'door_to_door',
  AIRPORT_OF_ARRIVAL_TO_DOOR: 'airport_of_arrival_to_door',
  AIRPORT_OF_DEPARTURE_TO_DOOR: 'airport_of_departure_to_door',
  AIRPORT_DEPARTURE_TO_AIRPOT_OF_ARRIVAL: 'airport_of_departure_to_airport_of_arrival'
};

export const eorScope = {
  DOOR_TO_DOOR: 'door_to_door',
  DOOR_TO_AIRPORT_OF_ARRIVAL: 'door_to_airport_of_arrival',
  DOOR_TO_AIRPORT_OF_DEPARTURE: 'door_to_airport_of_departure',
  AIRPORT_OF_ARRIVAL_TO_DOOR: 'airport_of_departure_to_door'
};

export const shippingMethods = {
  AIR: 'air',
  LAND: 'land',
  SEA: 'sea'
};

export const stepsStatuses = {
  FINISH: 'finish',
  PROCESS: 'process',
  WAIT: 'wait'
};

export const messageStatuses = {
  PENDING: 'pending',
  ERROR: 'error',
  RECEIVED: 'received',
  READ: 'read'
};

export const systemConfigurations = {
  AGENT_QUOTE_VALIDITY: {type: 'number'},
  ARCHIVE_REJECTED_QUOTE_PERIOD: {type: 'number'},
  SHIPMENT_PERCENTAGE_TO_CALCULATE_INSTANT_QUOTE: {type: 'number'},
  INSTANT_QUOTE_MAX_IOR: {type: 'number'},
  INSTANT_QUOTE_MIN_IOR: {type: 'number'},
  PRICE_LIST_DEFAULT_CURRENCY: {type: 'text'},
  ALLOWED_SHIPMENT_REQUESTS_FOR_NON_VERIFIED_CLIENT: {type: 'number'},
  BEFORE_SHIPMENT_DEADLINE_REMINDER: {type: 'number'},
  DISPLAY_AGENT_RATING: {type: 'switch'},
  DISPLAY_CLIENT_RATING: {type: 'switch'},
  REAPPLY_AFTER_REJECTION: {type: 'number'},
  SESSION_TIMEOUT: {type: 'number'},
  PREFERRED_AGENT_RESPONSE_TIME: {type: 'number'},
  BANK_ACCOUNT_INFORMATION: {type: 'bank'},
  LIFESPAN: {type: 'number'},
  SHIPMENT_DEADLINE_REMINDER: {type: 'number'},
  SHIPMENT_DEADLINE_REMINDER_WITH_MINIMUM_BID_SUBMISSIONS: {type: 'number'},
  MINIMUM_SHIPMENT_BIDS_LIMIT: {type: 'integer'},
  TOTAL_LOCAL_FEES_PERCENTAGE: {type: 'number'},
  SHIPMENT_QUOTE_COMMISSION_PERCENT: {type: 'number'},
  SHIPMENT_QUOTE_ADMIN_FEES: {type: 'number'}
};

export const companyProfileSteps = {
  COMPANY_DETAILS: 'company_details',
  COUNTRIES_COVERAGE: 'countries_coverage',
  PRICE_LISTS: 'price_lists',
  PRODUCT_ITEMS: 'product_items',
  AGREEMENT: 'agreement'
};

export const ticketStatuses = {
  OPEN: 'open',
  REOPENED: 'reopened',
  IN_PROGRESS: 'in_progress',
  RESOLVED: 'resolved'
};

export const priceListApprovalColumns = {
  col1: 'approval1',
  col2: 'approval2',
  col3: 'approval3',
  col4: 'approval4',
  col5: 'approval5',
  col6: 'approval6'
};

export const shipmentTableCommonVisibleColumns = ['shippingFrom', 'destinationCountry', 'totalValue', 'shipmentStatus', 'trackingStatus', 'quoteDeadline'];
export const shipmentTableBackOfficeVisibleColumns = ['requestDate', 'scope', 'shipmentType', 'agentName', 'clientName', 'offersSubmitted', 'quoteApproved', 'quoteRecieved', 'clientWasInvoiced', 'clientPaymentDone', 'agentPaymentDone',
  'assistMe', 'lastTransactionDate', 'lastCommunicationDate'];
export const shipmentTableAgentVisibleColumns = ['requestDate', 'scope', 'shipmentType', 'clientName'];

export const shipmentTableColumnsPerRole = {
  BackOfficeUser: shipmentTableBackOfficeVisibleColumns,
  AgentUser: shipmentTableAgentVisibleColumns,
  ClientUser: []
};
