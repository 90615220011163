import {displayServerErrorsInToaster, displaySuccessToaster} from '@/helpers/toasters';
import {utils} from 'jsonapi-vuex';
import router from '@/routes';
import _ from 'lodash';
import {Meta} from '@/models/meta';

const state = {
  shipmentRequests: [],
  shipmentRequest: {},
  productItems: []
};

const getters = {
  shipmentRequest: (state) => state.shipmentRequest,
  shipmentRequests: (state) => state.shipmentRequests,
  productItems: (state) => state.productItems,
  shipmentRequestColumns: () => window.localStorage.getItem('shipmentRequestColumns')
};

const actions = {
  async getProductItems({commit, rootState}) {
    const response = await this.$http.get('/product_items');
    if (response.status === 200) {
      commit('setProductItems', response.data);
    } else {
      const target = rootState.session.userType === 'ClientUser' ? 'ClientShipmentRequests' : 'AgentShipments';
      router.push({name: target});
      displayServerErrorsInToaster(response.errors);
    }
  },
  async createShipmentRequest({commit}, data) {
    const response = await this.$http.post('/shipment_requests', data);
    if (response.status === 200) {
      commit('setShipmentRequest', response.data);
      router.push({name: 'ClientShipmentsForm', params: {shipmentRequestId: response.data.data.id}});
      displaySuccessToaster(this.$t('messages.success.created', {entity: this.$t('models.shipmentRequest.entity', 1)}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
  },
  async updateShipmentRequest({commit, state}, data) {
    const response = await this.$http.put(`/shipment_requests/${data.shipmentRequestId}`, data.form);
    if (`${response.status}`.startsWith(2)) {
      if (response.status === 200) {
        displaySuccessToaster(this.$t('messages.success.updated', {entity: this.$t('models.shipmentRequest.entity', 1)}));
        commit('setShipmentRequest', response.data);
      }
      router.push({name: 'ClientShipmentsForm', params: {shipmentRequestId: data.shipmentRequestId}});
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async cloneShipmentRequest({commit}, data) {
    const response = await this.$http.post(`/shipment_requests/${data.shipmentRequestId}/clone`, data.form);
    if (`${response.status}`.startsWith(2)) {
      if (response.status === 200) {
        displaySuccessToaster(this.$t('messages.success.cloned', {entity: this.$t('models.shipmentRequest.entity', 1)}));
        commit('setShipmentRequest', response.data);
      }
      router.push({name: 'ClientShipmentsFormCloneShipmentRequest', params: {shipmentRequestId: response.data.data.id}});
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async getShipmentRequest({commit, rootState}, id) {
    const response = await this.$http.get(`/shipment_requests/${id}`);
    if (response.status === 200) {
      commit('setShipmentRequest', response.data);
    } else {
      let target;
      if (rootState.session.userType === 'ClientUser') {
        target = 'ClientShipmentRequestDetails';
      } else if (rootState.session.userType === 'BackOfficeUser') {
        target = 'BackOfficeShipments';
      } else {
        target = 'AgentShipments';
      }
      router.push({name: target});
      displayServerErrorsInToaster(response.errors);
    }
  },
  async getShipmentRequests({commit}, data) {
    const response = await this.$http.get('/shipment_requests', {params: data});
    if (response.status === 200) {
      const requests = _.map(response.data.data, (item) => {
        const normalisedItem = utils.jsonapiToNorm(item);
        return normalisedItem;
      });
      const meta = new Meta(response.data.meta);
      commit('setShipmentRequests', {data: requests, ...meta});
    } else {
      commit('setShipmentRequests', {});
    }
    return response.status;
  },
  async publishShipmentRequest({getters, commit}, shipmentRequest) {
    const id = typeof shipmentRequest === 'object' ? shipmentRequest._jv.id : shipmentRequest;
    const response = await this.$http.put(`/shipment_requests/${id}/publish`);
    if (response.status === 200) {
      if (typeof shipmentRequest === 'object') {
        const index = getters.shipmentRequests.data.indexOf(shipmentRequest);
        commit('updatePublished', index);
      } else {
        commit('setShipmentRequest', response.data);
      }
      displaySuccessToaster(this.$t('messages.success.published', {entity: this.$t('models.shipmentRequest.entity', 1)}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async cancelShipmentRequest({getters, commit}, data) {
    const id = typeof data === 'object' ? data._jv.id : data;
    const response = await this.$http.put(`/shipment_requests/${id}/cancel`);
    if (response.status === 200) {
      if (typeof data === 'object') {
        const index = getters.shipmentRequests.data.indexOf(data);
        commit('updateCancelled', index);
      } else {
        commit('setShipmentRequest', response.data);
      }
      displaySuccessToaster(this.$t('messages.success.cancelled', {entity: this.$t('models.shipmentRequest.entity', 1)}));
    } else {
      displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async getInstantQuotes({commit}, {shipmentRequestId}) {
    const response = await this.$http.get(`/shipment_requests/${shipmentRequestId}/instant_quotes`);
    if (response.status === 200) {
      commit('setShipmentQuotes', response.data);
    } else {
      commit('setShipmentQuotes', []);
    }
  },
  setShipmentAndShipmentRequestColumns({commit}, {columnName, values}) {
    window.localStorage.setItem(columnName, JSON.stringify(values));
  }
};

const mutations = {
  setShipmentRequests: (state, shipmentRequests) => state.shipmentRequests = shipmentRequests,
  setShipmentRequest: (state, request) => {
    const shipmentRequest = utils.jsonapiToNorm(request.data);
    _.forEach(['client', 'createdBy'], (key) => {
      shipmentRequest._jv.relationships[key] = utils.jsonapiToNorm(
        _.find(request.included, shipmentRequest._jv.relationships[_.snakeCase(key)].data));
    });
    delete shipmentRequest._jv.relationships[_.snakeCase('createdBy')];
    state.shipmentRequest = shipmentRequest;
  },
  setProductItems: (state, productItems) => {
    const items = _.map(productItems.data, (item) => {
      const normalizedItem = utils.jsonapiToNorm(item);
      normalizedItem._jv.relationships.productCategory = _.find(productItems.included, (relation) => {
        return relation.id === item.relationships.productCategory.data.id && relation.type === 'dynamicList';
      });
      return normalizedItem;
    });
    state.productItems = items;
  },
  updatePublished: (state, index) => state.shipmentRequests.data[index].published = true,
  updateCancelled: (state, index) => state.shipmentRequests.data[index].cancelledAt = Date.now()
};

export default {
  state,
  getters,
  actions,
  mutations
};
