import {utils} from 'jsonapi-vuex';
import _ from 'lodash';
import store from '@/store';

const state = {
  socket: {
    currentTarget: {},
    isConnected: false,
    message: '',
    reconnectError: false,
    heartBeatInterval: 50000,
    heartBeatTimer: 0,
    channel: ''
  }
};
const getters = {
  socket: (state) => state.socket
};


const mutations = {
  SOCKET_ONOPEN(state, event) {
    state.socket.currentTarget = event.currentTarget;
    state.socket.isConnected = true;

    if (!_.isEmpty(state.socket.channel)) {
      event.currentTarget.send(JSON.stringify(state.socket.channel));
    }
  },
  SOCKET_ONCLOSE(state, event) {
    state.socket.isConnected = false;
  },
  SOCKET_ONERROR(state, event) {
    state.socket.isConnected = false;
    state.socket.reconnectError = true;
  },
  SOCKET_ONMESSAGE(state, message) {
    state.socket.message = message;
    const data = JSON.parse(message.data);
    if ('identifier' in data && 'message' in data) {
      const normalizedData = utils.jsonapiToNorm(data.message.data);
      if (normalizedData._jv.type !== 'message') {
        store.commit('pushNotifications', {data: [normalizedData], fromSocket: true});
      } else {
        const included = utils.jsonapiToNorm(data.message.included);
        if (!_.isEmpty(data.message.data.attributes.attachments) || !(store.getters.me.id in included)) {
          store.commit('addParticipants', included);
          const normalizedMessage = utils.jsonapiToNorm(data.message.data);
          store.commit( 'pushMessages', [normalizedMessage]);
          store.commit('incrementUnread', normalizedMessage);
        }
      }
    }
  },
  SOCKET_RECONNECT(state, count) {
  },
  SOCKET_RECONNECT_ERROR(state) {
    state.socket.reconnectError = true;
  },
  SOCKET_CHANNEL: (state, channel) => {
    state.socket.channel = channel;
  }
};

const actions = {
  socketConnect({rootState, commit}) {
    const params = new URLSearchParams();
    params.append('access-token', rootState.session.accessToken);
    params.append('uid', rootState.session.uid);
    params.append('client', rootState.session.client);

    this.$connect(`${process.env.VUE_APP_GCE_SOCKET_URL}/cable?${params}`, {
      connectManually: false,
      reconnection: true,
      reconnectionDelay: 5000,
      pingTimeout: 60000,
      store: store
    });
  },
  socketDisconnect({commit}) {
    this.$disconnect();
  },
  channelSubscribe({state, commit}, message) {
    const socket = state.socket.currentTarget;
    wait(() => socket.send(JSON.stringify(message)), 1000, socket);
    commit('SOCKET_CHANNEL', message);
  },
  channelUnsubscribe({state, commit}, message) {
    const socket = state.socket.currentTarget;
    wait(() => socket.send(JSON.stringify(message)), 1000, socket);
    commit('SOCKET_CHANNEL', message);
  },
  notificationChannelSubscribe({state, commit}, message) {
    const socket = state.socket.currentTarget;
    wait(() => socket.send(JSON.stringify(message)), 1000, socket);
    commit('SOCKET_CHANNEL', message);
  },
  notificationChannelUnsubscribe({state, commit}, message) {
    const socket = state.socket.currentTarget;
    wait(() => socket.send(JSON.stringify(message)), 1000, socket);
    commit('SOCKET_CHANNEL', message);
  }
};

const wait = (callback, interval, socket) => {
  if (socket.readyState === 1) {
    callback();
  } else {
    setTimeout(() => wait(callback, interval, socket), interval);
  }
};

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions
};
